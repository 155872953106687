@import 'tokens.scss';

.CampaignCreationWrapper {
  display: grid;
  grid-template-columns: 1fr 550px;
  gap: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
  height: 100%;
  border-radius: 18px;

  // (min-width: 1600px) and
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 400px;
  }
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 350px;
  }
  // (min-width: 1600px) and
  @media (max-width: 1300px) {
    grid-template-columns: 1fr 350px;
  }
  // (min-width: 700px) and
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
