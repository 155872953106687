@import 'tokens.scss';

.nodata-inprogress {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
  align-items: center;
}
.CampaignDashboard {
  height: 100px;
  padding: 5px !important;
  background: none !important;
  &__inProgressPieChart {
    margin-top: 1rem;
    padding-right: 1rem;
    height: 240px;
    width: 240px;
    @media screen and (max-width: 1600px) {
      height: 200px;
      width: 200px;
    }
    @media screen and (max-width: 1500px) {
      height: 180px;
      width: 180px;
    }
    @media screen and (max-width: 1400px) {
      height: 160px;
      width: 160px;
    }
  }
  .CampaignDashboard__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #000000 !important;
  }

  &__in-progress-campaign-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    // flex-direction: column;
    padding-right: 1rem;
    margin: 1rem;
    @media screen and (max-width: 1500px) {
      flex-direction: column;
    }
    @media screen and (max-width: 699px) {
      display: block;
      padding-right: 0;
      margin: 0;
    }
  }

  &__in-progress-campaign-bar {
    align-self: center;
  }

  &__in-progress-campaign-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    // margin-left: -2rem;
    @media screen and (max-width: 1500px) {
      margin-left: 0;
      flex-direction: row;
      gap: 1rem;
    }
    @media screen and (max-width: 699px) {
      gap: 1rem;
      margin-left: 0;
    }
  }

  &__in-progress-campaign-circle {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 11px;
  }

  &__value {
    font-weight: 700;
  }
}
.campaignType__context_container {
  display: flex !important;
  align-items: center !important;
}
