.FindYourProspectFilters {
  &__homeType {
    display: flex;
    flex-wrap: wrap;
    width: 440px;
  }
  &__modalOption {
    // margin: 0 3px;
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: fit-content;
    text-align: center;
    font-weight: 600;
  }
  &__buttonActive {
    background-color: #007b94;
    color: white;
  }
  &__modalSaveCancel {
    border: 2px solid #ffb708;
    color: #007b94;
    float: right;
    margin: 9px 0;
    // padding: 20px 0;
  }
  &__modalSaveCancel:hover {
    background: inherit;
    color: #ffb708;
  }
  &__modalOption:hover {
    background-color: inherit;
  }
  &__modalOption:active,
  &__modalOption:focus {
    background-color: #ffab03;
    color: white;
  }
  &__priceFlterContainer {
    width: 300px;
  }
  &__priceFilter {
    display: flex;
    justify-content: space-between;
    width: 320px;
    text-align: center;
  }
  &__priceFilterBox {
    height: 44px;
    width: 400px;
    // font-weight: 600;
    font-size: 21px;
    text-align: center;
  }
}
.FindYourProspectFiltersActive {
  background-color: #ffab03;
  color: white;
}
// .Price_range {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   /* identical to box height */

//   display: flex;
//   align-items: center;

//   color: #aab2b5;
// }
