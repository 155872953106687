@import 'src/tokens';

.edit_card {
  width: 318px;
  height: 350px;
  border: 1px solid #aab2b5;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 9px;

  .btn_group {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .submit_button {
    width: 177px;
    height: 40px;
    font-size: 15px;
    font-weight: bold;
    color: #2b1f0a;
  }

  .title {
    display: inline-block;
    color: #2b1f0a !important;
    margin-bottom: 16px;
  }
  .setPasswordInput {
    border-radius: 0;
  }
  .input_fields_group {
    display: flex;
    justify-content: space-between;
  }

  .cvvSpace {
    width: 125px;
  }

  .forgotPassword {
    font-weight: 700;
  }
}
