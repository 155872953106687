.grid_box {
  margin-right: 1rem;
  width: 100%;
  @media (max-width: 699px) {
    margin-right: 0;
  }
}

.CampaignView {
  // background-color: white;
  background-color: #f8f8f8;
  .divider {
    border: 1px solid #dedede !important;
    width: 100%;
  }
  .campaign_details_progressbar {
    .ProgressIndicator__meter {
      width: 100%;
    }
  }
  .CompactSavedAudiences {
    max-height: 100%;
    padding: 5px;
    &__audience-container {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 41, 0.2);
      border-radius: 6px;
      border: 0px solid transparent;
    }
    &__border {
      border: 0px solid transparent;
    }
  }
  &__top_section {
    border-radius: 20px;
    padding: 31px 33px;
  }

  &__report {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    .report_card {
      // max-width: 350px;
      width: 30%;
      height: 147px;
      box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
      border-radius: 27px;
      display: flex;
      align-items: center;
    }
    .img_div {
      flex-grow: 1;
      text-align: center;
    }
    .title_div {
      display: flex;
      flex-direction: column;
      flex: 4;
    }

    .title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 50px !important;
      line-height: 68px !important;
      color: #2b1f0a !important;
    }
    .sub_title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300 !important;
      font-size: 24px !important;
      line-height: 33px !important;
      color: #2b1f0a !important;
    }

    .report_larg_card {
      // max-width: 534px;
      width: 47%;
      height: 211px;
      box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
      border-radius: 27px;
      display: flex;
      align-items: center;
      padding: 2rem;
      // flex-grow: 1;

      .nested_div {
        min-height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 2;
      }

      .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 30px !important;
        line-height: 41px !important;
        color: #2b1f0a !important;
      }
      .sub_title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        color: #2b1f0a !important;
      }

      .detail1,
      .detail2 {
        font-weight: 300 !important;
        font-size: 12px !important;
        color: #2b1f0a !important;
        line-height: 16px !important;
      }

      .detail_div {
        display: flex;
        flex-direction: column;
      }
    }
    .card_dot {
      background-color: #fcd917;
      height: 14px;
      width: 14px;
      margin-right: 8px;
      border-radius: 50%;
    }

    .report_pie_chart {
      // font-size: 18px;
      // font-weight: 700;
      // fill: #263238;
      width: 155px;
    }
  }

  &__creatives_container {
    display: flex;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
    border-radius: 27px;
    width: 100%;
    padding: 26px;

    .right_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card_title {
      font-weight: 700 !important;
      font-size: 30px !important;
      line-height: 41px !important;
      color: #2b1f0a !important;
    }

    .sub_title {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      color: #2b1f0a !important;
    }

    .small_imgs {
      width: 16px;
      margin-right: 12px;
      width: 35px;
    }

    .des {
      font-weight: 400 !important;
      font-size: 18px !important;
      color: #5d5444 !important;
    }
  }

  .CampaignView__all_campaign_creatives {
    // box-shadow: none;
    // padding: 10px;
    // width: 50%;
    border-radius: 10px;
    padding: 10px;
    width: 45%;
    margin: 10px;

    .creative_img {
      width: 115px;
      height: 118px;
      border-radius: 5px;
      // object-fit: contain;
    }

    .card_title {
      // font-size: 18px !important;
      // line-height: 25px !important;
      // width: 170px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      font-size: 18px !important;
      line-height: 25px !important;
      width: 96px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sub_title {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    .des {
      font-size: 10px !important;
    }
  }

  .text-campaign-details {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-transform: uppercase;
    color: #6d797c !important;
  }
  .text-campaign-details-value {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #2b1f0a !important;
  }

  .flight_date {
    font-weight: 600 !important;
    font-size: 16px !important;
    // margin-left: -69px!important;
    line-height: 22px !important;
    text-transform: uppercase !important;
    color: #313131 !important;
  }
  .flight-shedule {
    @media (max-width: 1400px) {
      width: 85% !important;
    }
    @media (max-width: 1350px) {
      width: 100% !important;
    }
  }
  .campaign-title-audience-heading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 26px !important;
    line-height: 35px !important;
    color: #2b1f0a !important;
  }
  .creative_value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #2b1f0a !important;
  }
  .flight-date-time {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 28px !important;
    color: #2b1f0a !important
  }
  .DateRangePicker {
    // width: 280px !important;
    margin-top: 20px;
  }
  .date-campaign-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // margin-left: -34px!important;
  }
}
.creative_right_column {
  min-height: 313px;
  width: 46%;
  @media (max-width: 1400px) {
    margin-left: 20px;
    width: auto;
  }
}
