@import 'src/tokens';

.Transactions__container {
  margin-top: 35px;

  .heading {
    display: inline-block;
    margin-bottom: 20px;

    @media (max-width: 699px) {
      margin-top: 20px;
    }
  }

  .TableSelectionWrapper__table-wrapper {
    // padding: 0;
    border-radius: 7px;
    // border: 1px solid #aab2b5;
    background: #ffffff;
  }

  .TableHeader {
    display: none;
  }

  .TableWrapper {
    border-radius: 7px;
  }

  .Table__body {
    box-sizing: border-box;
    border-radius: 7px;
    background: #ffffff;
  }

  .TableRow {
    border: none;
  }
}

.Transactions {
  &__table-wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
}
