@font-face {
  font-family: helvetican;
  src: url('../../assets/HelveticaNeue.ttf');
}
.loginFooter {
  /* height: 6vh; */
  // border: 2px solid #000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-family: helvetican;
  align-items: center;
  bottom: 0;

  background-color: #313131 !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  height: 58px;
  padding: 0 40px;

  @media screen and (max-width: 1050px) {
    font-size: 14px;
    // position: relative !important;
  }
  // @media screen and (max-height: 600px) {
  @media screen and (max-height: 820px) {
    position: relative !important;
  }
  @media screen and (max-height: 650px) and (min-width: 500px) {
    bottom: 0;
  }
  @media screen and (max-width: 550px) {
    display: none;
  }
}
.loginFooterLeft {
  padding: 1.5vh;
}
.loginfooterRight {
  margin: 1vh;
}
.loginFooterRight > ul > li {
  display: inline;
  margin-right: 10px;
}
.loginFooterRight > ul > li :hover {
  cursor: pointer;
  zoom: 1.5;
}
.loginFooterRightList:hover {
  cursor: pointer;
  opacity: 0.7;
}
@media screen and (max-width: 600px) {
  .loginFooterRight {
    display: none;
  }
  .loginFooter {
    justify-content: center;
  }
}
