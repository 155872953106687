.subscription_card_container {
  display: flex;
  // margin-left: 3rem;
  justify-content: space-between;
  // min-width: 550px;
  // max-width: 550px;
  // width: 550px;
  // height: 708px;

  @media (max-width: 1050px) {
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 699px) {
    margin: 0;
  }

  .subscription_card {
    border-radius: 7px;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // border: 1px solid #6d6d6d;
    margin-right: 2rem;
    min-width: 260px;
    height: 100%;

    width: 347px;
    // height: 692px;
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(40, 40, 40, 0.16);
    border-radius: 40px;

    @media (max-width: 1050px) {
      margin-right: 0;
    }

    @media (max-width: 699px) {
      margin: 0.5rem 0;
    }

    .hidePremium {
      max-height: 0;
      transition: max-height 0.2s ease-out;
      overflow: hidden;
    }

    .dropDownPremium {
      max-height: 500px;
      transition: max-height 0.2s ease-in;
      margin-left: 20px;
      // height: 100px !important;
      // transition: height 0.2s ease-out;
    }
    .dropDownPremium > li {
      background-image: url('../../../../assets/Images/premium_tick.png');
      margin-left: 20px;
      list-style: none;
      // background-image: url('./assets/img/control.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 30px;
      padding-block: 13px;
    }

    .dropDownPremium ul {
      list-style: none;
      margin-left: 50px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .dropDownPremium > li > span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: #000000 !important;
    }
    .dropDownPremium > ul li::before {
      background-color: #fcd917;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2.2px;
      height: 5px;
      width: 5px;
    }
    .title {
      // margin-bottom: 0.7rem;
      position: absolute;
      margin-top: 13px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 17px !important;
      line-height: 22px;
      color: #ffffff;
      margin-left: 9px;
    }
    .price_value {
      text-align: center !important;
      font-family: 'Open Sans' !important;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 60px !important;
      line-height: 82px;
      color: #2b1f0a !important;
    }

    hr {
      width: 185px;
      border: 1px solid #e1e1e1;
    }

    ul {
      padding: 0;
      // min-height: 124px;
    }

    ul.basic {
      // padding-bottom: 106px;
      // padding-bottom: 113px;
    }

    ul.basic > li {
      background-image: url('../../../../assets/Images/basic_tick.png');
      margin-left: 10px;

      list-style: none;
      // background-image: url('./assets/img/control.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 30px;
      padding-block: 13px;
    }

    ul.basic > li > span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: #000000 !important;
    }

    .price_div {
      display: flex;
      justify-content: center;
      // margin-top: 30px;
    }
    .price_content {
      display: flex;
      flex-direction: column;
    }
    ul.premium > li {
      background-image: url('../../../../assets/Images/premium_tick.png');
      margin-left: 10px;

      list-style: none;
      // background-image: url('./assets/img/control.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 30px;
      padding-block: 13px;
    }

    ul.premium > li > span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: #000000 !important;
    }
    ul > ul li::marker {
      content: '';
    }

    ul > ul {
      margin-left: 41px;
    }
    ul > ul > li {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
    ul.basic > ul li::before {
      background-color: #ffab03;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2.2px;
      height: 5px;
      width: 5px;
    }

    ul.premium > ul li::before {
      background-color: #fcd917;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2.2px;
      height: 5px;
      width: 5px;
    }

    .body_height {
      height: 108px;
    }

    .sub_logo {
      margin-left: -33px;
      width: 249px;
    }
    .basic_btn {
      color: #ffab03;
      border: 1px solid #ffab03;
      background: transparent;
    }
    .premium_btn {
      color: #1f1f1f;
    }
    .decimal_value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      margin-top: -9px;
      color: #2b1f0a;
      margin-bottom: -6px;
    }
    .sub_month {
      font-family: 'Open Sans';
      font-style: normal !important;
      font-weight: 400;
      font-size: 10px !important;
      line-height: 14px;
      text-align: center;

      color: #000000 !important;
    }
    .upgrade_button {
      width: 205px;
      height: 40px;
      margin: 0 auto;
      padding: 23px 0;
      // font-size: 30px;
      // font-style: italic;
      filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));
      border-radius: 10px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      // @media screen and (max-width: 1080px) {
      //   font-size: 26px;
      // }
      // @media screen and (max-width: 600px) {
      //   font-size: 21px;
      // }
    }

    .visibility_hidden {
      display: none;
    }
  }
}
