@import 'tokens.scss';

.campaignType__section {
  padding: 1px !important;
  .Section__actions {
    color: #515a5c;
    font-size: 16px;
  }
  .campaignType__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #000000 !important;
  }
}
.noData-campaign-type {
  margin: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
  @media screen and (max-width: 1500px) {
    // flex-direction: column;
    flex-direction: column-reverse;
  }
}
.campaignType {
  &__pieChart {
    padding-right: 2rem;
    // width: 550px;
    @media screen and (max-width: 1500px) {
      width: 170px;
    }
  }
  &__campaign-type {
    // height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    @media screen and (max-width: 1500px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 8px;
      :nth-child(1) {
        order: 3;
      }
    }

    &-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem;
      gap: 2rem;
      @media screen and (max-width: 1500px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;

        :nth-child(2) {
          order: 2;
        }
      }
      @media screen and (max-width: 699px) {
        margin: 1rem 0.5px;
        gap: 1rem;
      }
    }
  }

  &__in-progress-campaign-circle {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 11px;
  }
  &__in-progress-campaign-circle_video {
    background: #ff6d03 !important;
  }
  &__in-progress-campaign-circle_BannerAd {
    color: #85e2ff !important;
  }

  &__context_containers {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1500px) {
      align-items: center;
      flex-direction: row-reverse;
    }
  }
  &__value {
    font-weight: 700 !important;
  }

  &__label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #000000 !important;
    @media screen and (max-width: 699px) {
      font-size: 12px !important;
    }
  }
}
