@import 'src/tokens';

.CampaignReport {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  .Section {
    flex-grow: 1;
  }

  &__highest-preforming-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 3;
    background-color: #fff;
    margin-right: 1rem;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  &__creative-grid {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 120px;
    // padding: 2rem;
  }

  &__devices-served {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;
    padding: 1.5rem;
    justify-content: space-between;
    margin-bottom: 12rem;
    margin-top: 0.5rem;
  }
  &__creative-grid-box {
    padding: 0.3rem;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid transparent;
    &__imageText {
      width: 140px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__big-image {
    font-size: 300px;
  }
}
.creatives-banners {
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  gap: 1.2rem;
  flex-wrap: wrap;
  max-height: 384px;
  gap: 2rem;
  overflow: auto;
}

.creatives-report-text {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 23px !important;
  text-transform: uppercase !important;
  color: #6d797c !important;
}

.display-grid-aligned {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3.5rem;

  @media (max-width: 699px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
