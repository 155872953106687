@import 'tokens.scss';
@import 'src/mixins';

.CreativeTypeBadge {
  @include focus-ring;
  border: none;
  cursor: pointer;
  padding: 3rem 0;
  background-color: $color-grey-100;
  &__icon {
    color: $text-on-grey-100;
    margin-bottom: 0.5rem;
  }
  &__button {
    background-color: white !important;
    margin-top: 10px;
    border: white !important;
   
    @media screen and (max-width: 600px) {
      max-width: 180px !important;
    }
  }
  &__boxTextButton {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1ba1c5 !important;
  }
}
