.bread_crumb_container {
  // margin: 18px 49px 0 104px;
  display: flex;
  place-content: center space-between;
  align-items: center;
  height: 64px;
  background-color: #ffffff;
  padding: 0 15px 0 25px;
  text-transform: capitalize;
  // margin-bottom: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
  border-radius: 20px;
  width: 100%;

  @media screen and (max-width: 601px) {
    display: none;
  }

  .bread_crumb_route_text {
    color: #7e7e7e !important;
    font-size: 13px !important;
    font-weight: 700;
  }
  .bread_crumb_username {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 700;
    margin: 0 18px;
  }
  .sidebar_icon {
    font-size: 29px;
    margin-right: 10px;
  }
  .right_corner {
    min-width: 150px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }

  .Breadcrumbs {
    display: flex;
    align-items: center;
  }

  .Breadcrumbs__separator {
    margin: 0 0.5rem;
    color: #7e7e7e;
    font-size: 12px;
    font-weight: bold;
  }

  .Breadcrumbs__section {
    color: #7e7e7e;
    font-size: 12px;
  }
}

.dot {
  background-color: #fcd917;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  position: absolute;
  right: 10px;
}
