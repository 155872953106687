@import 'tokens.scss';

.ViewMarketStats {
  width: 100%;
  // margin-bottom: 1rem;

  // display: flex;
  // align-items: center;
  // text-align: center;
  // justify-content: center;
  // flex-direction: column;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
  border-radius: 27px;

  height: 110.98px;

  &__columns_per_line {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 1rem;
    // gap: 1rem;
    // padding-block: 1.6rem;
    // padding-inline: 23px;
    display: flex;
    // justify-content: space-around;
    align-items: center;
    height: 100%;
    padding-left: 2rem;
  }

  &__number_counter {
    flex: 2;
  }

  &__button {
    width: 186px !important;
    height: 44px !important;
    top: 655px;
    font-size: 15px;
    font-family: 'Open Sans';

    color: white;
    background: #44c2e6 !important;
    border-radius: 13px !important;
    border: none !important;
  }
  &__text-content {
    // margin-left: 11px;

    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 20px;
      line-height: 18px;
      /* or 90% */
      @media screen and (max-width:1750px) {
        font-size: 18px !important;
      }
      @media screen and (max-width:1550px) {
        font-size: 16px !important;
      }
      @media screen and (max-width:1350px) {
        font-size: 14px !important;
      }
      display: flex;
      align-items: center;
      text-transform: uppercase;

      color: #989898 !important;
      // @media screen and (max-width: 699px) {
      //   font-size: 28px !important;
      // }
    }

    &__sub_title {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 18px !important;
      color: #d1d1d1 !important;

      @media screen and (max-width: 1500px) {
        font-size: 15px !important;
      }
    }
  }
  &__percentage {
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 1;
  }
  &__text {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    font-size: 29px !important;
    line-height: 15px !important;
    width: 75px;
    color: #d1d1d1 !important;
  }
  &__img_wrapper {
    display: flex;
    min-width: 45px;
    justify-content: center;

    img {
      width: 40px;
      // height: 40px;
    }
  }
}
