@import 'src/tokens';

@mixin modal-position {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include modal-position();
  &__shade {
    background-color: rgba(0, 0, 0, 0.25);

    @include modal-position();
  }
  &__header {
    padding: 1rem;
    font-weight: $text-weight-bold;
    font-size: 1.2rem;
  }
  &__content {
    background-color: white;
    border-radius: 20px;
    box-shadow: $box-shadow-300-dark;
    z-index: 1;
    &--has-no-background {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }
  &__scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
      height: 30px;
      max-height: 220px;
    }
    &::-webkit-scrollbar-track {
      // background: linear-gradient(90deg, #FCD917 23.4%, #FFAB03 75.9%);
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      margin-top: 5px;
      margin-right: 8px;
    }
    &::-webkit-scrollbar-thumb {
      // background: red;
      background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
      border-radius: 10px;
      // height: 30px;
      min-height: 40px;
      max-height: 220px;
    }
  }
  &__close {
    color: $color-grey-200;
    border: none;
    &:hover {
      color: $text-on-white--subdued;
    }
  }
}
