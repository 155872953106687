@import 'src/tokens';

.PersonalInfoSectionContainer {
  &__header {
    display: flex;
    // align-items: center;
  }

  &__heading {
    margin-left: 4px;
    margin-bottom: 1rem;

    @media (min-width: 700px) and (max-width: 1050px) {
      margin: 1rem 0;
    }
  }

  &__error_icon {
    color: $color-danger;
    margin-top: 1px;
    margin-left: 9px;
  }

  .Section--dark {
    padding: 40px 15px 80px;
    // border: 1px solid #aab2b5;
    border-radius: 20px;

    @media (max-width: 699px) {
      padding: 15px 10px;
    }
  }

  .PersonalInfoSection {
    &__form_container {
      width: 90%;
    }
    // width: 65%;
    width: 80%;
    // border-right: 1px solid #c6cbcd;
    // padding-right: 32px;
    @media (max-width: 1500px) {
      width: 75%;
    }
    @media (max-width: 699px) {
      width: 100%;
      border-right: 0;
      padding-right: 0;
    }

    &__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding: 0 1rem;
      width: 20%;

      @media (max-width: 699px) {
        width: 100%;
      }
    }

    &__fields_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      gap: 70px;
      align-items: flex-end;

      @media (max-width: 699px) {
        flex-direction: column;
        margin-bottom: 0;
      }

      .fields_container_nested {
        width: 95%;
        // margin-right: 5px;

        @media (max-width: 699px) {
          width: 100%;
          margin: 0.5rem 0;

          .FormItem__label {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__divider {
    border: 0.5px solid #c6cbcd;
  }

  &__btn_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__reset_password {
    font-size: 16px;
    font-weight: 700;
    color: rgb(255, 171, 3);
    cursor: pointer;
    padding-bottom: 3px;
    border: none;
    background: none;
    font-size: 17px;

    @media (max-width: 699px) {
      font-size: 12px;
    }

    &:hover {
      background-color: none;
      // color: #44c2e6;
      box-shadow: none;
    }
  }

  &__save_button {
    width: 250px;
    height: 59px;
    font-weight: bold;
    margin: 0px auto;
  }
}

.imageModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 1172.95px;
  width: calc(100vw - 65vw);
  padding: 4rem 3rem;
  @media screen and (max-width: 1750px) {
    padding: 4rem 2.5rem;
  }
  @media screen and (max-width: 1600px) {
    padding: 2.5rem 1rem;
  }
  @media screen and (max-width: 1350px) {
    padding: 3rem 12rem;
  }
  &__icon {
    width: 140px;
    @media screen and (max-width: 1750px) {
      width: 125px;
    }
    @media screen and (max-width: 1600px) {
      width: 105px;
    }
    @media screen and (max-width: 1350px) {
      width: 92px;
    }
  }
}
.leftvoting_icon1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40%;
}
.rightvoting_icon1 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;

  z-index: -1;
}

.imageModal .profile-pic {
  width: 380px;
  z-index: 1;
  @media screen and (max-width: 1750px) {
    width: 480px;
  }
  @media screen and (max-width: 1600px) {
    width: 390px;
  }
  @media screen and (max-width: 1350px) {
    width: 350px;
  }
}
