@import 'src/tokens';

$sizes: (
  's': 1.5rem,
  'm': 3rem,
  'l': 5rem,
  'xl': 8rem,
);

.Avatar {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  &--rounded {
    border-radius: 50%;
    img {
      border-radius: 50%;
      height: inherit;
    }
  }

  @each $size, $rem in $sizes {
    &--#{$size}-size {
      width: $rem;
      height: $rem;
      font-size: $rem / 2;

      @if $rem == 1.5rem {
        font-weight: bold;
      }
      img {
        width: $rem;
      }
    }
  }
}
