.pagination_container {
  float: right;
  padding: 30px 0 0 0;
  margin: 1rem;

  .title {
    text-align: center;
    font-size: 11px;
    margin: 0;
  }

  .button {
    margin: 0 5px;
    background: #fff;
    border: 1px solid #c6cbcd;
    border-radius: 5px;
  }
}
