#cloudsponge-address-book div,
#cloudsponge-address-book span,
#cloudsponge-address-book p,
#cloudsponge-address-book a,
#cloudsponge-address-book address,
#cloudsponge-address-book em,
#cloudsponge-address-book img,
#cloudsponge-address-book b,
#cloudsponge-address-book u,
#cloudsponge-address-book i ol,
#cloudsponge-address-book ul,
#cloudsponge-address-book li,
#cloudsponge-address-book form,
#cloudsponge-address-book label,
#cloudsponge-address-book table,
#cloudsponge-address-book caption,
#cloudsponge-address-book tbody,
#cloudsponge-address-book tfoot,
#cloudsponge-address-book thead,
#cloudsponge-address-book tr,
#cloudsponge-address-book th,
#cloudsponge-address-book td,
#cloudsponge-address-book figure,
#cloudsponge-address-book figcaption,
#cloudsponge-address-book hgroup,
#cloudsponge-address-book time {
  border: 0 !important;
  font-size: 100% !important;
  font: inherit !important;
  vertical-align: baseline !important;
}
#cloudsponge-address-book ol,
#cloudsponge-address-book ul {
  list-style: none !important;
  column-count: auto !important;
  outline: 0 !important;
}
#cloudsponge-address-book table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}
#cloudsponge-address-book,
#cloudsponge-address-book * {
  box-sizing: border-box !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  line-height: normal !important;
  width: auto !important;
}
#cloudsponge-address-book a:hover:after {
  transition: none !important;
  -webkit-transition: none !important;
}
#cloudsponge-address-book a:after {
  height: 0 !important;
  transition: none !important;
  -webkit-transition: none !important;
}

#cloudsponge-overlay {
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 90000 !important;
  background-color: #000000 !important;
  opacity: 0.8 !important;
}
#cloudsponge-address-book {
  width: 824px !important;
  height: 85% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 90000 !important;
  padding: 10px !important;
  margin: auto !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  font-family: arial, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
#cloudsponge-address-book .cloudsponge-widget {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  background-color: #ebebeb !important;
  border-radius: 5px !important;
  position: relative !important;
}
#cloudsponge-contacts-manager {
  display: table !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: -42px !important;
  padding-top: 42px !important;
}
#cloudsponge-address-book a {
  color: #0097a7 !important;
  text-decoration: none !important;
}
#cloudsponge-address-book a:hover {
  text-decoration: underline !important;
}
#cloudsponge-address-book .cloudsponge-button,
#cloudsponge-address-book .cloudsponge-button:hover {
  display: inline-block !important;
  background-color: #4caf50 !important;
  padding: 16px 25px !important;
  color: #ffffff !important;
  font-weight: bold !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 2px #cecece !important;
}
#cloudsponge-address-book .cloudsponge-back-button:hover {
  display: inline !important;
  background-color: transparent !important;
  color: #ffffff !important;
  font-weight: bold !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 3px 6px 6px 0 !important;
}
#cloudsponge-address-book .cloudsponge-button span {
  vertical-align: middle !important;
  padding-inline: 4px !important;
}
#cloudsponge-address-book .cloudsponge-message {
  text-align: center !important;
  color: #34474e !important;
  font-size: 14px !important;
  padding: 25px !important;
}
#cloudsponge-address-book .cloudsponge-icon {
  // background-image: url(/assets/iconset-25833d6299c6740cd125a60eaff14831624293024d1bfda2832522a9992cbec4.png) !important;
  display: inline-block !important;
  width: 32px !important;
  height: 32px !important;
  vertical-align: top !important;
  background-size: 42px 1260px !important;
  background-position: -4px -174px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mail126 {
  background-position: -5px -5px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mail163 {
  background-position: -5px -47px !important;
}
#cloudsponge-address-book .cloudsponge-icon.aol {
  background-position: -5px -89px !important;
}
#cloudsponge-address-book .cloudsponge-icon.bol {
  background-position: -5px -131px !important;
}
#cloudsponge-address-book .cloudsponge-icon.cs {
  background-position: -5px -173px !important;
}
#cloudsponge-address-book .cloudsponge-icon.csv {
  background-position: -5px -215px !important;
}
#cloudsponge-address-book .cloudsponge-icon.gmail {
  background-position: -5px -257px !important;
}
#cloudsponge-address-book .cloudsponge-icon.gmx {
  background-position: -5px -299px !important;
}
#cloudsponge-address-book .cloudsponge-icon.icloud {
  background-position: -5px -341px !important;
}
#cloudsponge-address-book .cloudsponge-icon.linkedin {
  background-position: -5px -383px !important;
}
#cloudsponge-address-book .cloudsponge-icon.addressbook {
  background-position: -5px -425px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mail2world {
  background-position: -5px -467px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mailcom {
  background-position: -5px -509px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mail_ru {
  background-position: -5px -551px !important;
}
#cloudsponge-address-book .cloudsponge-icon.naver {
  background-position: -5px -593px !important;
}
#cloudsponge-address-book .cloudsponge-icon.poczta_o2 {
  background-position: -5px -635px !important;
}
#cloudsponge-address-book .cloudsponge-icon.office365 {
  background-position: -5px -677px !important;
}
#cloudsponge-address-book .cloudsponge-icon.windowslive {
  background-position: -5px -719px !important;
}
#cloudsponge-address-book .cloudsponge-icon.outlookcom {
  background-position: -5px -719px !important;
}
#cloudsponge-address-book .cloudsponge-icon.outlook {
  background-position: -5px -761px !important;
}
#cloudsponge-address-book .cloudsponge-icon.plaxo {
  background-position: -5px -803px !important;
}
#cloudsponge-address-book .cloudsponge-icon.qip_ru {
  background-position: -5px -845px !important;
}
#cloudsponge-address-book .cloudsponge-icon.qq_mail {
  background-position: -5px -887px !important;
}
#cloudsponge-address-book .cloudsponge-icon.rediff {
  background-position: -5px -929px !important;
}
#cloudsponge-address-book .cloudsponge-icon.sapo {
  background-position: -5px -971px !important;
}
#cloudsponge-address-book .cloudsponge-icon.terra {
  background-position: -5px -1013px !important;
}
#cloudsponge-address-book .cloudsponge-icon.uol {
  background-position: -5px -1055px !important;
}
#cloudsponge-address-book .cloudsponge-icon.web_de {
  background-position: -5px -1097px !important;
}
#cloudsponge-address-book .cloudsponge-icon.yahoo {
  background-position: -5px -1139px !important;
}
#cloudsponge-address-book .cloudsponge-icon.yandex_ru {
  background-position: -5px -1181px !important;
}
#cloudsponge-address-book .cloudsponge-icon.mail_yeah_net {
  background-position: -5px -1223px !important;
}
#cloudsponge-address-book .cloudsponge-glyph,
#cloudsponge-address-book .cloudsponge-glyph:hover {
  font-family: 'cloudsponge-icomoon' !important;
  speak: none !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  text-decoration: none !important;
  line-height: 1 !important;
  display: inline-block !important;
}
#cloudsponge-address-book .cloudsponge-glyph.refresh:before {
  color: #eceff1 !important;
  content: '\e607' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.search:before {
  color: #eceff1 !important;
  content: '\e60a' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.forward-arrow:before {
  font-size: 10px !important;
  content: '\e602' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.back-arrow:before {
  font-size: 10px !important;
  content: '\e907' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.tick:before {
  content: '\e901' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.cross:before {
  content: '\e903' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.multitick:before {
  content: '\e902' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.menu:before {
  content: '\e900' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.back:before {
  content: '\e904' !important;
}
#cloudsponge-address-book .cloudsponge-glyph.down-arrow:before {
  content: '\e905' !important;
}
#cloudsponge-address-book .cloudsponge-topbar {
  height: 46px !important;
  position: relative !important;
  z-index: 90000 !important;
  padding: 12px !important;
  color: #ffffff !important;
  font-size: 18px !important;
  border-radius: 5px 5px 0 0 !important;
  background-color: #1695b4 !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1 {
  text-align: center !important;
  font-size: 18px !important;
  font-weight: normal !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-menu-button,
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-close-button {
  position: absolute !important;
  top: 12px !important;
  color: #ffffff !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-menu-button {
  left: 12px !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-close-button {
  right: 12px !important;
}
#cloudsponge-address-book .cloudsponge-header {
  width: 100% !important;
  padding: 0px 8px 8px !important;
  background-color: #1695b4 !important;
  display: table !important;
  position: relative !important;
}
#cloudsponge-address-book .cloudsponge-close-search,
#cloudsponge-address-book .cloudsponge-close-search:hover {
  color: #cccccc !important;
  position: absolute !important;
  right: 15px !important;
  top: 16px !important;
  font-size: 16px !important;
  vertical-align: middle !important;
}
#cloudsponge-address-book .cloudsponge-input-holder {
  width: 100% !important;
  position: relative !important;
  padding: 8px !important;
  background-color: #ffffff !important;
  border-radius: 5px !important;
  overflow: hidden !important;
  display: table-cell !important;
}
#cloudsponge-address-book .cloudsponge-separator {
  display: inline-block !important;
  width: 1px !important;
  height: 26px !important;
  margin: 3px 0 0 3px !important;
  vertical-align: top !important;
  background: white !important;
  background: -moz-linear-gradient(
    top,
    white 0%,
    #cecece 50%,
    white 100%
  ) !important;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, white),
    color-stop(50%, #cecece),
    color-stop(100%, white)
  ) !important;
  background: -webkit-linear-gradient(
    top,
    white 0%,
    #cecece 50%,
    white 100%
  ) !important;
  background: -o-linear-gradient(
    top,
    white 0%,
    #cecece 50%,
    white 100%
  ) !important;
  background: -ms-linear-gradient(
    top,
    white 0%,
    #cecece 50%,
    white 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    white 0%,
    #cecece 50%,
    white 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 ) !important;
}
#cloudsponge-address-book .cloudsponge-input-holder .cloudsponge-glyph.search {
  position: absolute !important;
  top: 16px !important;
  left: 58px !important;
}
#cloudsponge-address-book .cloudsponge-input,
#cloudsponge-address-book .cloudsponge-input:focus {
  font: 16px arial, sans-serif !important;
  color: #607d8b !important;
  height: 32px !important;
  width: 100% !important;
  position: absolute !important;
  top: 8px !important;
  left: 55px !important;
  padding: 0 95px 0 26px !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
}
#cloudsponge-address-book .cloudsponge-title {
  height: 36 !important;
  line-height: 36px !important;
  color: #b0bec5 !important;
  background-color: #eef2f4 !important;
  border-top: 1px solid #dadbdb !important;
  border-bottom: 1px solid #dadbdb !important;
  padding: 0 10px 0 10px !important;
  overflow: hidden !important;
  text-align: right !important;
  position: relative !important;
}
#cloudsponge-address-book .cloudsponge-left-link {
  float: left !important;
}
#cloudsponge-address-book .cloudsponge-left-link .cloudsponge-selected-count,
#cloudsponge-address-book .cloudsponge-left-link.cloudsponge-selected span,
#cloudsponge-address-book
  .cloudsponge-left-link.cloudsponge-selected:hover
  .cloudsponge-selected-count {
  display: none !important;
}
#cloudsponge-address-book
  .cloudsponge-left-link.cloudsponge-selected
  .cloudsponge-selected-count,
#cloudsponge-address-book
  .cloudsponge-left-link.cloudsponge-selected:hover
  span {
  display: inline !important;
}
#cloudsponge-address-book .cloudsponge-title a {
  vertical-align: top !important;
  text-transform: uppercase !important;
}
#cloudsponge-address-book .cloudsponge-title a:hover {
  text-decoration: none !important;
  color: #046b76 !important;
}
#cloudsponge-address-book .cloudsponge-contacts-content {
  height: 100% !important;
  display: table-row !important;
}
#cloudsponge-address-book .cloudsponge-contacts-content .cloudsponge-cell {
  display: table-cell !important;
  height: 100% !important;
}
#cloudsponge-address-book
  .cloudsponge-contacts-content
  .cloudsponge-cell-holder {
  position: relative !important;
  height: 100% !important;
}
#cloudsponge-address-book #cloudsponge-contacts-scrolling-region {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  bottom: 0 !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list {
  list-style-type: none !important;
  padding-top: 18px !important;
  padding-bottom: 30px !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li {
  height: 50px !important;
  position: relative !important;
  border: 1px solid #dee8ed !important;
  box-shadow: 0 1px 1px #cecece !important;
  margin: -1px 12px 0 12px !important;
  cursor: pointer !important;
  display: list-item !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li select,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-row-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  color: transparent !important;
  background-color: transparent !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-row-container {
  background-color: #ffffff !important;
  font-size: 16px !important;
  color: #383737 !important;
  padding: 15px 10px !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li.cloudsponge-selected
  .cloudsponge-row-container {
  background-color: #ecf8ff !important;
}
#cloudsponge-address-book .cloudsponge-contact-avatar {
  display: none !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li.cloudsponge-letter,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li.cloudsponge-letter:hover {
  margin: 0 !important;
  padding: 18px 0 10px 30px !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: default !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #a9a9a9 !important;
  text-transform: uppercase !important;
  height: auto !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > .cloudsponge-letter:first-child,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > .cloudsponge-letter:hover:first-child {
  padding-top: 0 !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list.cloudsponge-disable-letter
  > li.cloudsponge-letter {
  display: none !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li.cloudsponge-hidden {
  display: none !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  input[type='checkbox'] {
  display: none !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  input[type='checkbox']
  + label {
  vertical-align: top !important;
  color: #a9a9a9 !important;
  font-size: 18px !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  input[type='checkbox']
  + label:before {
  content: '\e611' !important;
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  input[type='checkbox']:checked
  + label:before {
  content: '\e610' !important;
  color: #1695b4 !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li select {
  font-size: 16px !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li .cloudsponge-h3 {
  width: 260px !important;
  display: inline-block !important;
  margin: 0 20px 0 30px !important;
  font-size: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-mail {
  display: inline-block !important;
  position: relative !important;
  margin-right: 10px !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-mail
  em,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-phone
  em,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-address
  em {
  margin-left: 5px !important;
  display: none !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-mail.cloudsponge-multiple-mails:hover {
  text-decoration: underline !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-mail.cloudsponge-multiple-mails
  em,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-phone.cloudsponge-multiple-phones
  em,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-address.cloudsponge-multiple-addresses
  em {
  display: inline-block !important;
  vertical-align: top !important;
  line-height: 20px !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-mail
  > span.cloudsponge-mail-label {
  max-width: 330px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block !important;
  white-space: nowrap !important;
}
#cloudsponge-address-book .cloudsponge-storage-message {
  border: none !important;
  box-shadow: none !important;
  margin-top: 10px !important;
  margin-bottom: 40px !important;
  cursor: default !important;
}
#cloudsponge-address-book .cloudsponge-storage-message * {
  text-align: center !important;
  margin: 5px !important;
}
#cloudsponge-address-book #cloudsponge-storage-update,
#cloudsponge-address-book #cloudsponge-storage-disable,
#cloudsponge-address-book #cloudsponge-storage-enable {
  margin: 10px !important;
}
#cloudsponge-address-book .cloudsponge-copyright-item {
  display: none !important;
}
#cloudsponge-address-book
  .cloudsponge-selected-mail.cloudsponge-multiple-mails
  .cloudsponge-mail-dropdown,
#cloudsponge-address-book
  .cloudsponge-selected-phone.cloudsponge-multiple-phones
  .cloudsponge-phone-dropdown,
#cloudsponge-address-book
  .cloudsponge-selected-address.cloudsponge-multiple-addresses
  .cloudsponge-address-dropdown {
  list-style-type: none !important;
  position: absolute !important;
  z-index: 90000 !important;
  top: 25px !important;
  left: 0 !important;
  width: 350px !important;
  padding: 5px 0 !important;
  background-color: #ffffff !important;
  border: 1px solid #cacaca !important;
  border-radius: 3px !important;
  box-shadow: 0 0 3px 3px #cecece !important;
  display: none !important;
}
#cloudsponge-address-book
  .cloudsponge-selected-phone.cloudsponge-multiple-phones
  .cloudsponge-phone-dropdown,
#cloudsponge-address-book
  .cloudsponge-selected-address.cloudsponge-multiple-address
  .cloudsponge-address-dropdown {
  width: 220px !important;
}
#cloudsponge-address-book .cloudsponge-selected-mail select {
  display: none !important;
}
#cloudsponge-address-book
  .cloudsponge-selected-mail.cloudsponge-multiple-mails
  .cloudsponge-mail-dropdown.cloudsponge-opened,
#cloudsponge-address-book
  .cloudsponge-selected-phone.cloudsponge-multiple-phones
  .cloudsponge-phone-dropdown.cloudsponge-opened,
#cloudsponge-address-book
  .cloudsponge-selected-address.cloudsponge-multiple-addresses
  .cloudsponge-address-dropdown.cloudsponge-opened {
  display: block !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-mail-dropdown
  li,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-phone-dropdown
  li,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-address-dropdown
  li {
  padding: 8px !important;
  color: #404040 !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-mail-dropdown
  li:hover,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-phone-dropdown
  li:hover,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-address-dropdown
  li:hover {
  background-color: #ebebeb !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-more-mails,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-more-phones,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-more-addresses {
  font-size: 12px !important;
  color: #bbbbbb !important;
}
#cloudsponge-address-book .cloudsponge-multiple-mails em {
  color: #bbbbbb !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-phone,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-address {
  display: inline-block !important;
  position: relative !important;
  margin-right: 10px !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-phone
  .cloudsponge-phone-label,
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-selected-address
  .cloudsponge-address-label {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 130px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  margin-bottom: -4px !important;
}
#cloudsponge-address-book .cloudsponge-dictionary {
  width: 22px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-left: 1px solid #dadbdb !important;
  background-color: #ebebeb !important;
  display: none !important;
  padding: 5px 0px 5px 0px !important;
  -webkit-flex-direction: column !important;
  -moz-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
#cloudsponge-address-book .cloudsponge-dictionary a,
#cloudsponge-address-book .cloudsponge-dictionary a:hover {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 11px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  color: #0097a7 !important;
  text-decoration: none !important;
  -moz-box-flex: 1 !important;
  -webkit-flex: 1 !important;
  -moz-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}
#cloudsponge-address-book .cloudsponge-dictionary a:first-child {
  border-top: 0 !important;
}
#cloudsponge-address-book .cloudsponge-dictionary a.cloudsponge-disabled {
  cursor: default !important;
  color: #cecece !important;
}
#cloudsponge-address-book .cloudsponge-letter-preview {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  font-size: 36px !important;
  font-weight: bold !important;
  font-style: normal !important;
  color: #a9a9a9 !important;
  text-align: center !important;
  line-height: 82px !important;
  text-transform: uppercase !important;
  width: 82px !important;
  height: 82px !important;
  background-color: #ebebeb !important;
  border: 1px solid #dddede !important;
  border-radius: 3px !important;
}
#cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button {
  position: absolute !important;
  z-index: 90000 !important;
  bottom: 30px !important;
  right: 30px !important;
}
#cloudsponge-address-book
  a.cloudsponge-button.cloudsponge-export-button.disabled {
  opacity: 0.25;
  cursor: default;
}
#cloudsponge-address-book
  a.cloudsponge-button.cloudsponge-export-button.disabled:hover {
  background-color: #dddddd !important;
  cursor: default !important;
}
#cloudsponge-address-book .cloudsponge-button .cloudsponge-mobile-export {
  display: none !important;
}
#cloudsponge-address-book .cloudsponge-back-button {
  display: inline !important;
  background-color: transparent !important;
  padding: 0 !important;
  color: #ffffff !important;
  font-weight: bold !important !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  position: absolute !important;
  top: 8px !important;
  left: 16px !important;
  padding: 3px 6px 6px 0 !important;
  bottom: auto !important !important;
}
#cloudsponge-address-book .cloudsponge-desktop-back-button {
  text-decoration: underline !important;
}
#cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button:hover {
  background-color: #389b3c !important;
}
#cloudsponge-address-book .cloudsponge-providers-holder {
  position: absolute !important;
  top: 46px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
#cloudsponge-address-book .cloudsponge-providers-list {
  list-style-type: none !important;
  position: relative !important;
  width: 800px !important;
  margin: 65px auto !important;
  padding-left: 22px !important;
}
#cloudsponge-address-book .cloudsponge-providers-list li {
  width: 237px !important;
  margin: 0 22px 10px 0 !important;
  display: inline-block !important;
  white-space: nowrap !important;
}
#cloudsponge-address-book .cloudsponge-providers-list li.cloudsponge-two-books {
  width: 368px !important;
}
#cloudsponge-address-book .cloudsponge-providers-list a {
  display: block !important;
  width: 100% !important;
  padding: 12px 17px !important;
  background-color: #ffffff !important;
  border-radius: 3px !important;
  color: #383737 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
#cloudsponge-address-book .cloudsponge-providers-list a:hover {
  text-decoration: none !important;
  background-color: #ecf8ff !important;
}
#cloudsponge-address-book
  .cloudsponge-providers-list
  li.cloudsponge-two-books
  a {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
#cloudsponge-address-book .cloudsponge-providers-list em {
  vertical-align: middle !important;
  margin-right: 10px !important;
}
#cloudsponge-address-book .cloudsponge-copyright {
  position: absolute !important;
  width: 100% !important;
  text-align: center !important;
  left: 0 !important;
  bottom: -40px !important;
  padding-bottom: 20px !important;
  color: #7c7c7c !important;
  font-size: 10px !important;
}
#cloudsponge-address-book .cloudsponge-copyright a {
  display: inline !important;
  color: #ffffff !important;
  // background-image: url(/assets/powered_by-grey-ba9b7d0ed2104ece321b4f9ee999805ecc1d18683435bd5ad4d50a4cfcd041b1.png) !important;
  background-repeat: no-repeat !important;
  margin-left: 4px !important;
  padding: 7px 41px !important;
  background-size: 82px 25px !important;
}
#cloudsponge-address-book .cloudsponge-copyright a:hover {
  color: #ffffff !important;
  // background-image: url(/assets/powered_by-color-19e1d0ee45b22bc182a900f0375529cc2f0dc45d601dda5007d10797c32aa2db.png) !important;
}
#cloudsponge-address-book .cloudsponge-copyright a span {
  display: none !important;
  color: #c7c7c7 !important;
}
#cloudsponge-address-book .cloudsponge-mobile-copyright {
  display: none !important;
}
#cloudsponge-address-book #cloudsponge-loading-overlay {
  position: absolute !important;
  overflow-y: auto !important;
  top: 46px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}
#cloudsponge-address-book #cloudsponge-loading-overlay #cloudsponge-loader {
  text-align: center !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-gif {
  display: inherit !important;
  width: 32px !important;
  height: 32px !important;
  margin: 30px auto !important;
  // background-image: url(/assets/loader-53f091f39a4e3ee7cbbf78bd5abdac2fa67b72fbbffc19074508078ff6cb59a5.gif) !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text {
  display: block !important;
  font-style: normal !important;
  text-align: center !important;
  width: 300px !important;
  margin: 10% auto 10px !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-oauth-focus-span {
  font-style: normal !important;
  text-align: center !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text
  .cloudsponge-icon {
  vertical-align: middle !important;
  margin-right: 10px !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text-error-detail {
  text-align: left !important;
  margin: 20px 0px !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text-error-detail
  ol {
  list-style: decimal inside !important;
  margin: 10px !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text-error-detail
  ol
  li {
  padding: 4px !important;
}
#cloudsponge-address-book
  #cloudsponge-loading-overlay
  .cloudsponge-loading-text-error-detail
  .icloud-error {
  padding: 10px !important;
  border: 1px solid #fedb76 !important;
  background-color: #f9f4e8 !important;
  border-radius: 3px !important;
}
#cloudsponge-address-book .cloudsponge-login {
  position: absolute !important;
  top: 46px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
#cloudsponge-address-book .cloudsponge-login-form {
  width: 500px !important;
  margin: 100px auto 0 auto !important;
}
#cloudsponge-address-book .cloudsponge-login-form .cloudsponge-h2 {
  font-size: 18px !important;
  line-height: 17px !important;
  color: #383737 !important;
  margin-bottom: 30px !important;
}
#cloudsponge-address-book .cloudsponge-login-form .cloudsponge-h2 em {
  font-style: normal !important;
}
#cloudsponge-address-book
  .cloudsponge-login-form
  .cloudsponge-h2
  em.cloudsponge-icon {
  float: left !important;
  margin-right: 10px !important;
}
#cloudsponge-address-book .cloudsponge-login-form .cloudsponge-h2 span {
  display: block !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
#cloudsponge-address-book .cloudsponge-login-form input {
  width: 100% !important;
  height: 42px !important;
  background-color: #ffffff !important;
  border: 1px solid #959595 !important;
  padding: 0 10px !important;
  color: #9c9c9c !important;
}
#cloudsponge-address-book .cloudsponge-login-form input.cloudsponge-error {
  border: 1px solid #ff0000 !important;
}
#cloudsponge-address-book .cloudsponge-login-form input[type='submit'] {
  background-color: #4caf50 !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: center !important;
  cursor: pointer !important;
  border: 0 !important;
  border-radius: 4px !important;
  margin-bottom: 20px !important;
}
#cloudsponge-address-book .cloudsponge-login-form p {
  margin-bottom: 10px !important;
}
#cloudsponge-address-book .cloudsponge-login-form p.cloudsponge-error {
  text-align: center !important;
  display: none !important;
  color: #ff0000 !important;
  font-size: 12px !important;
}
#cloudsponge-address-book
  .cloudsponge-login-form
  #cloudsponge-icloud-two-steps-verification-message {
  text-align: left !important;
  margin: 20px 0px !important;
  padding: 10px !important;
  border: 1px solid #fedb76 !important;
  background-color: #f9f4e8 !important;
  border-radius: 3px !important;
}
#cloudsponge-address-book
  .cloudsponge-login-form
  #cloudsponge-icloud-two-steps-verification-message
  ol {
  list-style: decimal inside !important;
  margin: 10px 10px 6px 10px !important;
}
#cloudsponge-address-book
  .cloudsponge-login-form
  #cloudsponge-icloud-two-steps-verification-message
  ol
  li {
  margin: 4px !important;
}
#cloudsponge-address-book .cloudsponge-login .cloudsponge-disclaimer {
  font-size: 12px !important;
  color: #383737 !important;
  padding: 20px 100px 20px 100px !important;
}
#cloudsponge-address-book .cloudsponge-login .cloudsponge-disclaimer span {
  display: block !important;
}
#cloudsponge-address-book .cloudsponge-import {
  position: absolute !important;
  top: 46px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow: auto !important;
}
#cloudsponge-address-book .cloudsponge-import-form {
  padding: 25px !important;
  margin: 25px !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-not-supported {
  display: none !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-step-row,
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-not-supported {
  min-height: 56px !important;
  margin-bottom: 50px !important;
  margin-left: 75px !important;
  padding-top: 10px !important;
  position: relative !important;
  font-size: 18px !important;
  color: #383737 !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form.cloudsponge-csv-generic
  .cloudsponge-step-row,
#cloudsponge-address-book
  .cloudsponge-import-form.cloudsponge-csv-generic
  .cloudsponge-step-row.cloudsponge-description {
  margin-left: 0px !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form.cloudsponge-csv-generic
  .cloudsponge-csv-specific,
#cloudsponge-address-book
  .cloudsponge-import-form.cloudsponge-csv-specific
  .cloudsponge-csv-generic {
  display: none !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form
  .cloudsponge-step-row.cloudsponge-description {
  margin-left: 75px !important;
  font-size: 14px !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-step-row p {
  margin-bottom: 10px !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-step-number {
  width: 56px !important;
  height: 56px !important;
  border-radius: 28px !important;
  background-color: #4caf50 !important;
  position: absolute !important;
  left: -75px !important;
  top: 0 !important;
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 55px !important;
  color: #ffffff !important;
  text-align: center !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-save-button,
#cloudsponge-address-book
  .cloudsponge-import-form
  .cloudsponge-save-button:hover {
  display: inline-block !important;
  margin-bottom: 15px !important;
  padding: 3px 20px 3px 5px !important;
  border: 1px solid #ffffff !important;
  border-radius: 4px !important;
  background-color: #52a7e0 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  text-decoration: none !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form
  .cloudsponge-save-button
  .cloudsponge-icon {
  vertical-align: middle !important;
  margin-right: 10px !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-file-upload {
  cursor: pointer !important;
  height: 160px !important;
  margin-top: 30px !important;
  background-color: #ffffff !important;
  border: 3px dashed #52a7e0 !important;
  color: #9c9c9c !important;
  text-align: center !important;
  line-height: 160px !important;
  position: relative !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form
  .cloudsponge-file-upload
  input[type='file'] {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  opacity: 0 !important;
}
#cloudsponge-address-book .cloudsponge-contact-details {
  padding: 30px !important;
  height: 100% !important;
  background-color: #fff !important;
}
#cloudsponge-address-book .cloudsponge-contact-details .cloudsponge-h3 {
  padding: 10px !important;
  text-align: center !important;
  font-size: 30px !important;
}
#cloudsponge-address-book .cloudsponge-contact-details .cloudsponge-email-field,
#cloudsponge-address-book .cloudsponge-contact-details .cloudsponge-phone-field,
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-address-field {
  padding: 5px !important;
}
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-email-field
  .cloudsponge-field-label,
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-phone-field
  .cloudsponge-field-label,
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-address-field
  .cloudsponge-field-label {
  padding: 5px !important;
  font-weight: 900 !important;
}
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-email-field
  .cloudsponge-email-select,
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-phone-field
  .cloudsponge-phone-select,
#cloudsponge-address-book
  .cloudsponge-contact-details
  .cloudsponge-address-field
  .cloudsponge-address-select {
  height: 30px !important;
  width: 100% !important;
  border: 1px solid #999 !important;
  border-radius: 5px !important;
  background: #eee !important;
}
@media (max-width: 960px),
  (max-device-width: 1000px) and (orientation: landscape) {
  #cloudsponge-overlay,
  #cloudsponge-address-book .cloudsponge-copyright,
  #cloudsponge-address-book .cloudsponge-close-button,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-h3,
  #cloudsponge-address-book .cloudsponge-button .cloudsponge-desktop-export {
    display: none !important;
  }
  #cloudsponge-address-book {
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    min-width: 0 !important;
  }
  #cloudsponge-address-book .cloudsponge-widget,
  #cloudsponge-address-book .cloudsponge-header,
  #cloudsponge-address-book,
  #cloudsponge-address-book .cloudsponge-topbar {
    border-radius: 0 !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list {
    padding-top: 2px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list > li {
    height: 62px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    box-shadow: none !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name {
    height: 62px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list > li .cloudsponge-h3 {
    margin-left: 10px !important;
    display: inline !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-row-container {
    pointer-events: none !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail {
    display: block !important;
    padding: 5px 0 0 28px !important;
    white-space: nowrap !important;
    max-width: 100% !important;
    overflow: hidden !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-phone,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-address {
    padding: 5px 0 0 28px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-multiple-mails
    .cloudsponge-mail-label {
    max-width: 330px !important;
    overflow: hidden !important;
    vertical-align: middle !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-selected-mail {
    margin-top: -22px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    > .cloudsponge-h3,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-row-container
    > .cloudsponge-h3 {
    display: none !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-row-container
    > .cloudsponge-h3 {
    overflow: hidden !important;
    width: 80% !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
}
@media only screen and (max-width: 960px) and (min-width: 768px),
  only screen and (max-device-width: 1000px) and (orientation: landscape) and (min-width: 768px) {
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-row-container
    > .cloudsponge-h3 {
    width: 85% !important;
  }
}
@media (max-width: 960px),
  (max-device-width: 1000px) and (orientation: landscape) {
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-mobile-detail {
    position: absolute !important;
    top: 20px !important;
    right: 20px !important;
    width: 40px !important;
    height: 40px !important;
    padding: 10px 10px 30px 10px !important;
    z-index: 9999 !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-mobile-detail
    .cloudsponge-mobile-detail-more {
    width: 5px !important;
    height: 5px !important;
    border-radius: 50% !important;
    background-color: #ccc !important;
    box-shadow: 0px 8px 0px #ccc, 0px 16px 0px #ccc !important;
    margin: 0px 5px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter:hover {
    padding: 2px 0 2px 14px !important;
  }
  #cloudsponge-address-book .cloudsponge-storage-message * {
    margin: 15px 2.5px !important;
  }
  #cloudsponge-address-book .cloudsponge-copyright-item a,
  #cloudsponge-address-book .cloudsponge-copyright-item a:hover {
    font-size: 11px !important;
    color: #383737 !important;
    text-decoration: none !important;
  }
  #cloudsponge-address-book .cloudsponge-copyright-item {
    display: block !important;
    text-align: center !important;
    padding: 0 0 50px 0 !important;
    border: 0 !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-copyright-item
    a,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-copyright-item
    a:hover {
    font-size: 11px !important;
    color: #383737 !important;
    text-decoration: none !important;
  }
  #cloudsponge-address-book .cloudsponge-dictionary {
    display: -moz-box !important;
    display: -webkit-flexbox !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: flex !important;
  }
  #cloudsponge-address-book .cloudsponge-button,
  #cloudsponge-address-book .cloudsponge-button:hover,
  #cloudsponge-address-book .cloudsponge-back-button {
    font-size: 14px !important;
    padding: 3px 6px 6px 6px !important;
    box-shadow: none !important;
  }
  #cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button,
  #cloudsponge-address-book .cloudsponge-back-button {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    bottom: auto !important !important;
  }
  #cloudsponge-address-book .cloudsponge-back-button,
  #cloudsponge-address-book .cloudsponge-back-button:hover {
    background-color: transparent !important;
    display: inline !important;
    right: auto !important !important;
    left: 16px !important;
    padding: 3px 6px 6px 0 !important;
  }
  #cloudsponge-address-book .cloudsponge-button .cloudsponge-mobile-export {
    display: inline !important;
  }
  #cloudsponge-address-book .cloudsponge-providers-list {
    margin: 0 !important;
    width: auto !important;
    padding: 0 !important;
  }
  #cloudsponge-address-book .cloudsponge-providers-list li {
    width: 100% !important;
    margin: 0 !important;
    border-top: 1px solid #dee8ed !important;
  }
  #cloudsponge-address-book .cloudsponge-providers-list li a {
    border-radius: 0 !important;
    padding-left: 18px !important;
    padding-right: 10px !important;
  }
  #cloudsponge-address-book .cloudsponge-login-form {
    width: auto !important;
    margin: 30px !important;
  }
  #cloudsponge-address-book .cloudsponge-login .cloudsponge-disclaimer {
    left: 30px !important;
    right: 30px !important;
    width: auto !important;
    padding: 20px 32px 20px 32px !important;
  }
  #cloudsponge-address-book .cloudsponge-import-form {
    width: auto !important;
    padding: 0 !important;
  }
}
@media (max-width: 400px),
  (max-device-width: 960px) and (orientation: landscape) {
  #cloudsponge-contacts-manager {
    margin-top: -34px !important;
    padding-top: 34px !important;
  }
  #cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1 {
    font-size: 16px !important;
    padding-top: 0 !important;
  }
  #cloudsponge-address-book .cloudsponge-topbar {
    height: 38px !important;
    padding: 8px 12px 8px 12px !important;
    font-size: 16px !important;
  }
  #cloudsponge-address-book .cloudsponge-providers-holder {
    top: 38px !important;
  }
  .cloudsponge-topbar .cloudsponge-menu-button,
  .cloudsponge-topbar .cloudsponge-close-button {
    top: 9px !important;
  }
  #cloudsponge-address-book .cloudsponge-header {
    padding: 0px 5px 5px !important;
  }
  #cloudsponge-address-book .cloudsponge-input-holder {
    padding: 4px !important;
  }
  #cloudsponge-address-book .cloudsponge-input,
  #cloudsponge-address-book .cloudsponge-input:focus {
    height: 26px !important;
    left: 42px !important;
  }
  #cloudsponge-address-book
    .cloudsponge-input-holder
    .cloudsponge-glyph.search {
    top: 14px !important;
    left: 50px !important;
  }
  #cloudsponge-address-book .cloudsponge-title {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0 8px 0 8px !important;
  }
  #cloudsponge-address-book {
    font-size: 12px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list {
    padding-top: 2px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter:hover {
    font-size: 14px !important;
    padding: 2px 0 2px 12px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list > li .cloudsponge-h3 {
    font-size: 14px !important;
    margin-left: 8px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail {
    font-size: 12px !important;
    padding: 5px 0 0 24px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail
    span.cloudsponge-mail-label {
    max-width: 320px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-row-container
    > .cloudsponge-h3 {
    overflow: hidden !important;
    width: 80% !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-row-container
    > .cloudsponge-h3 {
    display: none !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-phone,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-address {
    font-size: 12px !important;
    padding: 5px 0 0 24px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    input[type='checkbox']
    + label {
    font-size: 16px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list > li {
    height: 56px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name {
    height: 62px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-row-container {
    padding: 8px 8px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-more-mails {
    font-size: 10px !important;
  }
  #cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button {
    bottom: 12px !important;
  }
  #cloudsponge-address-book .cloudsponge-button {
    padding: 12px 18px !important;
  }
  #cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button,
  #cloudsponge-address-book .cloudsponge-back-button {
    font-size: 14px !important;
    top: 5px !important;
    right: 5px !important;
  }
  #cloudsponge-address-book .cloudsponge-button,
  #cloudsponge-address-book .cloudsponge-button:hover {
    padding: 6px 6px 6px 6px !important;
  }
  #cloudsponge-address-book .cloudsponge-back-button {
    padding: 6px 6px 6px 0px !important;
  }
  #cloudsponge-address-book .cloudsponge-close-search,
  #cloudsponge-address-book .cloudsponge-close-search:hover {
    top: 12px !important;
  }
}
@media (max-height: 440px) {
  #cloudsponge-address-book .cloudsponge-dictionary {
    display: none !important;
  }
}
@media (max-height: 800px) {
  #cloudsponge-address-book .cloudsponge-import-form .cloudsponge-step-row,
  #cloudsponge-address-book
    .cloudsponge-import-form
    .cloudsponge-not-supported {
    font-size: 14px !important;
    margin-bottom: 20px !important;
  }
  #cloudsponge-address-book .cloudsponge-import-form .cloudsponge-file-upload {
    height: 115px !important;
    line-height: 115px !important;
  }
  #cloudsponge-address-book
    .cloudsponge-import-form
    .cloudsponge-step-row.cloudsponge-description {
    margin: 0 !important;
    margin-left: 75px !important;
    min-height: 0 !important;
    margin-top: -20px !important;
  }
}
@media (max-height: 960px) {
  #cloudsponge-address-book .cloudsponge-dictionary a,
  #cloudsponge-address-book .cloudsponge-dictionary a:hover {
    font-size: 11px !important;
    line-height: 18px !important;
  }
}
@media (max-height: 626px) {
  #cloudsponge-address-book .cloudsponge-dictionary a,
  #cloudsponge-address-book .cloudsponge-dictionary a:hover {
    font-size: 10px !important;
    line-height: 16px !important;
  }
}
@media (max-height: 500px) {
  #cloudsponge-address-book .cloudsponge-dictionary a,
  #cloudsponge-address-book .cloudsponge-dictionary a:hover {
    font-size: 9px !important;
    line-height: 12px !important;
  }
}
.cs-launch,
.cloudsponge-launch {
  cursor: pointer !important;
}
@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  #cloudsponge-address-book .cloudsponge-icon {
    // background-image: url(/assets/iconset@2x-851c818120a4f0f7a7af3d8849e34e977e0ffa12adc67bfd229eeeacf1937b9a.png) !important;
  }
  #cloudsponge-address-book .cloudsponge-copyright a {
    // background-image: url(/assets/powered_by-grey@2x-7ecae7d147f70837c27f643dbda057eb44562b29dd384ade1d278396714abc24.png) !important;
    background-size: 82px 25px !important !important;
  }
  #cloudsponge-address-book .cloudsponge-copyright a:hover {
    color: #ffffff !important;
    // background-image: url(/assets/powered_by-color@2x-2b6626489bb9194c276c829206ad8703294ab4d2423d796ad9c6d2102971d614.png) !important;
  }
}
.cloudsponge-import-form input[type='file'] {
  position: fixed !important;
  top: -100em !important;
}
@media only screen and (min-width: 960px) {
  .cloudsponge-columns-3 #cloudsponge-address-book {
    min-width: 950px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail {
    width: 300px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail
    span.cloudsponge-mail-label {
    max-width: 75% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
}
@media (max-width: 960px),
  (max-device-width: 1000px) and (orientation: landscape) {
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li {
    min-height: 82px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    span.cloudsponge-phone-label {
    max-width: 330px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name {
    min-height: 82px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-h3 {
    display: none !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter {
    min-height: 0px !important;
  }
}
@media (max-width: 400px),
  (max-device-width: 960px) and (orientation: landscape) {
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li {
    min-height: 72px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    span.cloudsponge-phone-label {
    max-width: 320px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name {
    min-height: 72px !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-no-name
    .cloudsponge-h3 {
    display: none !important;
  }
  .cloudsponge-columns-3
    #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li.cloudsponge-letter {
    min-height: 0px !important;
  }
}
#cloudsponge-address-book {
  font-family: inherit !important;
  background-color: white !important;
  border-radius: 8px !important;
  color: #5f5668 !important;
}
#cloudsponge-address-book a:link,
#cloudsponge-address-book a:visited {
  color: #0d9ce6 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
#cloudsponge-address-book a:link:hover {
  text-decoration: none !important;
  color: #085b85 !important;
}
#cloudsponge-address-book {
  padding: 40px !important;
}
#cloudsponge-address-book .cloudsponge-widget {
  background-color: white !important;
  border-radius: 0 !important;
}
#cloudsponge-address-book #cloudsponge-contacts-manager {
  padding-bottom: 80px !important;
}
#cloudsponge-address-book .cloudsponge-button,
#cloudsponge-address-book .cloudsponge-button:hover {
  padding: 10px 10px !important;
}
#cloudsponge-address-book .cloudsponge-topbar {
  height: unset !important;
  padding: unset !important;
  color: #2c2830 !important;
  background-color: white !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1 {
  font-size: 30px !important;
  font-weight: 300 !important;
  padding: 0 0 20px !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1:after {
  height: 1px !important;
  background: url('data:image/svg+xml,<svg%20width%3D"300"%20height%3D"4"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20xmlns%3Asketch%3D"http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns"><title>Rectangle-path<%2Ftitle><desc>Created%20with%20Sketch.<%2Fdesc><defs><linearGradient%20x1%3D"0%25"%20y1%3D"0%25"%20y2%3D"-3.294%25"%20id%3D"a"><stop%20stop-color%3D"%23B078B1"%20offset%3D"0%25"%2F><stop%20stop-color%3D"%23B5CE80"%20offset%3D"29.699%25"%2F><stop%20stop-color%3D"%2351B2D5"%20offset%3D"77.298%25"%2F><stop%20stop-color%3D"%23B078B1"%20offset%3D"100%25"%2F><%2FlinearGradient><%2Fdefs><rect%20fill%3D"url(%23a)"%20sketch%3Atype%3D"MSShapeGroup"%20width%3D"300"%20height%3D"4"%2F><%2Fsvg>')
    bottom left repeat-x !important;
  bottom: 0 !important;
  content: '' !important;
  display: block !important;
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-close-button {
  right: 0 !important;
  background: 0 0 !important;
  width: auto !important;
  height: auto !important;
  top: -6px !important;
  overflow: visible !important;
  font-size: 0 !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-close-button:before {
  content: '\00d7' !important;
  color: #000000 !important;
  opacity: 1 !important;
  font-size: 22px !important;
  font-weight: bolder;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
#cloudsponge-address-book .cloudsponge-topbar .cloudsponge-close-button:hover {
  opacity: 0.5 !important;
  text-decoration: none !important;
}
#cloudsponge-address-book
  > #cloudsponge-books-tab
  > .cloudsponge-topbar
  > .cloudsponge-back-button {
  display: none !important;
}
#cloudsponge-address-book
  > #cloudsponge-loading-tab
  .cloudsponge-topbar
  > .cloudsponge-back-button,
#cloudsponge-address-book
  > #cloudsponge-loading-tab
  .cloudsponge-topbar
  > .cloudsponge-h1 {
  display: none !important;
}
#cloudsponge-address-book .cloudsponge-header {
  padding: 0 !important;
}
#cloudsponge-address-book .cloudsponge-input-holder {
  border-radius: 0 !important;
  width: 100% !important;
  position: relative !important;
  padding: 8px !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  overflow: hidden !important;
  display: table-cell !important;
}
#cloudsponge-address-book .cloudsponge-contacts-content .cloudsponge-cell {
  border-bottom: 1px solid #dee8ed !important;
}
#cloudsponge-address-book ul.cloudsponge-contacts-list > li {
  height: 40px !important;
  position: relative !important;
  margin: -1px 0px 0 0px !important;
}
#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  .cloudsponge-row-container {
  line-height: 20px !important;
  padding: 10px 10px !important;
}
#cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button {
  bottom: 0px !important;
  right: 0px !important;
  background-color: #f6d301 !important;
  color: #2b1f09 !important;
  padding-right: 6px !important;
  font-size: 16px !important;
}
#cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button:hover {
  background-color: #f6d301 !important;
}
#cloudsponge-address-book .cloudsponge-back-button {
  padding: 6px !important;
  bottom: auto !important;
}
#cloudsponge-address-book .cloudsponge-back-button:hover {
  box-shadow: none !important;
  padding: 6px !important;
}
#cloudsponge-address-book .cloudsponge-providers-holder {
  top: 61px !important;
}
#cloudsponge-address-book .cloudsponge-providers-list {
  margin: 0 !important;
  padding: 0 !important;
}
#cloudsponge-address-book .cloudsponge-providers-list li {
  display: list-item !important;
  margin: 0 !important;
  width: unset !important;
}
#cloudsponge-address-book .cloudsponge-providers-list a {
  font-size: 15px !important;
  margin: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 20px 0 20px 0 !important;
}
#cloudsponge-address-book .cloudsponge-copyright {
  text-align: right !important;
  bottom: -55px !important;
}
#cloudsponge-address-book .cloudsponge-login-form input[type='submit'] {
  background-color: #c08eef !important;
}
#cloudsponge-address-book .cloudsponge-import-form {
  margin: 50px auto 0 auto !important;
}
#cloudsponge-address-book
  .cloudsponge-import-form
  .cloudsponge-step-row.cloudsponge-description {
  font-size: 14px !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-step-number {
  background-color: #c08eef !important;
}
#cloudsponge-address-book .cloudsponge-import-form .cloudsponge-file-upload {
  border: 3px dashed #c08eef !important;
}
@media (max-width: 960px),
  (max-device-width: 1000px) and (orientation: landscape) {
  #cloudsponge-address-book {
    padding: 0 !important;
  }
  #cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1 {
    padding: 10px 0 10px 0 !important;
  }
  #cloudsponge-address-book #cloudsponge-contacts-manager {
    padding-bottom: 75px !important;
  }
  #cloudsponge-address-book ul.cloudsponge-contacts-list {
    padding-top: 2px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail {
    line-height: 14px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail.cloudsponge-multiple-mails
    em,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-phone.cloudsponge-multiple-phones
    em {
    line-height: 14px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-mobile-detail {
    top: 20px !important;
  }
  #cloudsponge-address-book .cloudsponge-button,
  #cloudsponge-address-book .cloudsponge-button:hover,
  #cloudsponge-address-book .cloudsponge-back-button {
    // font-size: 18px !important;
    padding: 9px 9px 6px 9px !important;
  }
  #cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button,
  #cloudsponge-address-book .cloudsponge-back-button {
    right: 8px !important;
    bottom: 10px !important;
    padding-bottom: 7px !important;
    padding-top: 5px !important;
  }
  #cloudsponge-address-book .cloudsponge-back-button,
  #cloudsponge-address-book .cloudsponge-back-button:hover {
    font-size: 16px !important;
  }
  #cloudsponge-address-book
    div.cloudsponge-topbar
    > a.cloudsponge-close-button {
    top: -12px !important;
    right: 7px !important;
  }
}
@media (max-width: 400px),
  (max-device-width: 960px) and (orientation: landscape) {
  #cloudsponge-address-book .cloudsponge-topbar .cloudsponge-h1 {
    font-size: 18px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail {
    padding: 0px 0 0 24px !important;
    line-height: 12px !important;
  }
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-mail.cloudsponge-multiple-mails
    em,
  #cloudsponge-address-book
    ul.cloudsponge-contacts-list
    > li
    .cloudsponge-selected-phone.cloudsponge-multiple-phones
    em {
    line-height: 14px !important;
  }
  #cloudsponge-address-book .cloudsponge-button.cloudsponge-export-button {
    padding: 3px 6px 6px 6px !important;
  }
  #cloudsponge-address-book #cloudsponge-contacts-manager {
    padding-bottom: 50px !important;
  }
  #cloudsponge-address-book
    div.cloudsponge-topbar
    > a.cloudsponge-close-button {
    top: 0px !important;
    right: 9px !important;
  }
  #cloudsponge-address-book
    div.cloudsponge-topbar
    > a.cloudsponge-close-button:before {
    font-size: 26px !important;
  }
  #cloudsponge-address-book .cloudsponge-back-button {
    padding: 5px !important;
  }
}
@media (max-height: 800px) {
  #cloudsponge-address-book .cloudsponge-import-form {
    margin: 40px auto 0 auto !important;
  }
  #cloudsponge-address-book
    .cloudsponge-import-form
    .cloudsponge-step-row.cloudsponge-description {
    margin-left: 75px !important;
  }
}
