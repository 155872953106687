@import 'src/tokens';

.SuperAdminPage {
  height: 100%;
  display: flex;
  align-items: stretch;
  background-color: #f8f8f8;

  @media (min-width: 700px) and (max-width: 1050px) {
    flex-direction: column;
  }

  &__nav {
    margin-left: 25px;
    width: 20%;
    max-width: 20rem;
    // border-right: $pixels-1 solid $color-grey-300;
    background-color: #f8f8f8;

    @media (min-width: 700px) and (max-width: 1050px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding: 0 2rem;
    }
  }
  &__main {
    flex-grow: 1;
    overflow-y: auto;
    padding: 4.5rem 2rem 2rem 2rem;
    background-color: #f8f8f8;
    @media (min-width: 700px) and (max-width: 1050px) {
      padding-top: 1.5rem;
    }
  }
  &__header {
    padding: 0.5rem 0.3rem;
    color: black;
    margin: 18px 0px 10px 0px;

    @media (min-width: 700px) and (max-width: 1050px) {
      padding: 0 0.2rem;
    }
  }
}
.chargebeePackageModalHeader {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  line-height: 53px;
}
.chargebeePackageModalHeader > p {
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}
.chargebeePackageModalText {
  width: auto;
  text-align: center;
  max-width: 330px;
  margin: 0 auto;
  margin-top: 1rem;
}
.chargebeePackageModalBoxes {
  display: flex;
  margin: 2rem 2rem 1rem 2rem;
}
.chargebeePackageModalBox1 {
  background: #fff;
  display: flex;
  border: 1px solid #6d6d6d;
  // height: 330px;
  width: 270px;
  margin-right: 1rem;
  flex-direction: column;
  border-radius: 7px;
  align-items: center;
  padding: 1rem 1.5rem;
}
.chargebeePackageModalBox2 {
  background: #fff;
  display: flex;
  border: 1px solid #6d6d6d;
  border-radius: 7px;
  // height: 330px;
  width: 270px;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
}
.chargebeePackageModalBox1Perks {
  align-self: flex-start;
  margin: 1rem 0;
}
.chargebeePay {
  display: flex;

  // height: 330px;
  width: 270px;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
}
.chargebeePerksList > li {
  color: lightgray;
}
.ChargebeeBg {
  // background-image: url('../../assets/Images/honeycomb.jpg');
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}
.onBoardBgIcon::before {
  content: '';
  background: url('../../assets//Images/honeycomb_left.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -240px;
  left: -100px;
  background-size: 330px;
  background-position: left top;
  z-index: -1;
}
.onBoardBgIcon::after {
  content: '';
  background: url('../../assets//Images/honeycomb.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 330px;
  top: 220px;
  left: 0;
  background-position: right bottom;
  z-index: -1;
}
.ChargebeeBgPayment {
  // background-image: url('../../assets/Images/honeycomb.jpg');
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}
.onBoardBgIconPayment::before {
  content: '';
  background: url('../../assets//Images/honeycomb_left.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -122px;
  left: 0;
  background-size: 330px;
  background-position: left top;
  z-index: -1;
}
.onBoardBgIconPayment::after {
  content: '';
  background: url('../../assets//Images/honeycomb.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 330px;
  bottom: -40px;
  right:0;
  background-position: right bottom;
  z-index: -1;
}
