@import 'tokens';
@import 'mixins';

.SavedAudiences {
  &__select-action {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;

    @include focus-ring;
  }
  &__textstyle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    display: flex;
    align-items: center;
    color: #757575 !important;
  }
  &__tagsContainer{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    
  }
  &__styleIcon {
    margin-right: 5px;
  }
  &__info {
    margin-left: 0.5rem;
    color: $text-on-white--subdued;
  }
  &__delete_campaign_list {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #ffffff !important;
  }
}
