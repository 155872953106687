.notification_container_tooltip {
  .empty_status_container {
    // img {
    //   width: 30%;
    // }
    .Text {
      font-size: 18px !important;
    }

    .empty_status_container__subHeading {
      font-size: 16px !important;
    }
  }

  .nested_container {
    background: white;
    min-height: 446px;

    ul {
      list-style: none;
      padding: 0;
      cursor: pointer;
    }

    .header_container {
      border-bottom: 1px solid #c6cbcd;
      padding: 0 10px;

      li {
        padding: 8px 0;
        cursor: pointer;
        padding-bottom: 10px;
      }

      .header_titles {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        overflow: auto;
        align-items: center;

        &::-webkit-scrollbar {
          height: 4px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
          border-radius: 10px;
        }

        .Text {
          font-size: 11px !important;
        }

        li {
          margin: 0 7px;
          font-size: 11px;
        }
      }

      .count {
        padding: 5px 10px;
        border-radius: 25%;
        margin-left: 0.3rem;
        background: #c6cbcd;
        font-weight: bold;
        font-size: 11px;
        color: white;
      }

      .mark_all_as_read {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        border-bottom: 1px solid black;
        padding-bottom: 0;
      }
    }

    .body_container {
      .list_view {
        display: flex;
        justify-content: space-between;
        margin: 0;
        height: 71px;
        border-bottom: 1px solid #c6cbcd;
        align-items: center;
        padding: 0 15px;
      }

      .notification_title {
        cursor: pointer;
        display: flex;
        align-items: center;

        .Text {
          margin-left: 5px;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          // max-width: 200px !important;
          max-width: 400px !important;
        }
      }

      .pagination_container {
        margin: 0;
        padding-top: 20px;
      }
    }

    .black_bg_color {
      background-color: black !important;
    }

    .border_bottom {
      border-bottom: 3px solid black;
    }

    .unread {
      background: rgba(255, 171, 3, 0.1);
    }

    .empty_status_container__headings_container {
      .Text {
        font-family: sans-serif, 'Helvetica Neue', 'Lucida Grande', Arial;
        font-size: 32px !important;
      }
    }
  }
}
.notificationTitleIcon {
  display: flex;
  align-items: center;
  &__icon {
    margin: 0 5px;
    object-fit: contain !important;
  }
}
