// .talkToSphere {
//   background: #f8f8f8;
//   border-radius: 18px;
//   margin: 0.5rem;
//   padding: 0.2rem 0.5rem;
//   &__head {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
//   &__head > h3 {
//     font-size: 15px;
//     font-weight: 800;
//     color: #d89613;
//   }
//   &__headButton {
//     border: none;
//     border-radius: 18px;
//     background-color: white;
//     color: #d89613;
//     height: 35px;
//     padding: 0.2rem 1rem;
//     font-weight: 600;
//     font-size: 20px;
//     :hover {
//       background: none;
//       color: #d89613;
//       background-color: white;
//     }
//   }
// }
.talkToSphere{
    &__table{
        background: white !important;
        border-radius: 19px !important;
        margin-top: 1rem !important;
    }
}