
$input-height-s: 1.5rem;
$input-height-m: 1.75rem;
$input-height-l: 2.5rem;
$input-height-xl: 3.25rem;

$input-font-weight-s: 600;
$input-font-weight-m: 500;
$input-font-weight-l: 500;
$input-font-weight-xl: 400;
