@import 'src/tokens';
@import '../input-variables';
.TextInputError {
  display: flex;
  align-items: center;
  border: 3px solid #bd0000;
  border-radius: $border-radius-s;
  background: #fcf2f2;
  color: #bd0000;
  height: calc(
    #{$input-height-m} - var(--border-width-default) - var(--border-width-default)
  );

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fcf2f2 inset;
    text-emphasis-color: #bd0000;
  }
  &::placeholder {
    color: #bd0000;
  }
  &:active {
    box-shadow: #bd0000;
    color: #bd0000;
  }
  &:focus-within {
    box-shadow: #bd0000;
    color: #bd0000;
    &:active {
      box-shadow: #bd0000;
      color: #bd0000;
    }
  }
  &__iconLeft {
    color: #bd0000;
    padding: 0 $pixels-4;
  }
  &__iconRight {
    color: #bd0000;
    padding: 0 $pixels-4;
  }
  &__input {
    border: none;
    color: #bd0000;
    background-color: #fcf2f2;
    font-size: $text-size-m;
    flex-grow: 1;
    padding: 0 $pixels-4;
    margin: 0 $pixels-4;
    width: 100%;
    &::placeholder {
      color: #bd0000;
    }
    &:focus {
      outline: none;
      background-color: #fcf2f2;
      color: #bd0000;
    }
  }
}
.TextInput {
  display: flex;
  align-items: center;
  // border: $border-width-default solid $color-grey-300;
  border: 1px solid #dadada;
  border-radius: $border-radius-s;
  background: $color-white;
  height: calc(
    #{$input-height-m} - var(--border-width-default) - var(--border-width-default)
  );
  &:hover {
    border: 1px solid #ffab03;
  }
  &:active {
    // box-shadow: $focus-ring;
  }
  &:focus-within {
    // box-shadow: $focus-ring;
    border: 1px solid #ffab03;
    &:active {
      // box-shadow: $focus-ring;
    }
  }
  &__iconLeft {
    color: $color-grey-500;
    padding: 0 $pixels-4;
  }
  &__iconRight {
    color: $color-grey-500;
    padding: 0 $pixels-4;
  }
  &__input {
    border: none;
    font-size: $text-size-m;
    flex-grow: 1;
    padding: 0 $pixels-4;
    margin: 0 $pixels-4;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
}

.defaultIconClass {
  background: none;
  border: none;
  padding-left: 10px;
}

.TextInputError--s-size {
  height: calc(
    #{$input-height-s} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-xs;
}

.TextInputError--s-size .TextInputError__input {
  font-size: $text-size-xs;
}

.TextInputError--l-size {
  height: calc(
    #{$input-height-l} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-l;
}

.TextInputError--l-size .TextInputError__input {
  font-size: $text-size-l;
}

.TextInputError--xl-size {
  height: calc(
    #{$input-height-xl} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-xl;
}

.TextInputError--xl-size .TextInputError__input {
  font-size: $text-size-xl;
}

.TextInput--s-size {
  height: calc(
    #{$input-height-s} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-xs;
}

.TextInput--s-size .TextInput__input {
  font-size: $text-size-xs;
}

.TextInput--l-size {
  height: calc(
    #{$input-height-l} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-l;
}

.TextInput--l-size .TextInput__input {
  font-size: $text-size-l;
}

.TextInput--xl-size {
  height: calc(
    #{$input-height-xl} - var(--border-width-default) - var(--border-width-default)
  );
  font-size: $text-size-xl;
}

.TextInput--xl-size .TextInput__input {
  font-size: $text-size-xl;
}
