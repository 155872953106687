@import 'tokens.scss';

// override default leaflet styles
.marker-cluster {
  background-color: rgba(129, 255, 118, 0.4);
}
.marker-cluster div {
  background-color: #36f226;
}

.leaflet-div-icon {
  border: none;
  background: transparent;
}

.leaflet-popup-content-wrapper {
  overflow: hidden;
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
  width: auto !important;
}

.leaflet-popup {
  margin-bottom: 42px;
  margin-left: 4px;
}
.leaflet-tooltip {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 999;
}

.Map {
  height: 100%;
  border-radius: 18px;
  box-shadow: 0px 3px 15px rgba(0, 0, 41, 0.25);

  &__fit-to-bounds-button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 1000;
    display: none;
  }
}
