@import 'tokens.scss';

.Section {
  padding: 1rem;
  background: $color-grey-100;
  border-radius: $border-radius-s;
  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__spacer {
    // width: 100%;
    // height: 1px;
    // background: $color-grey-500;
    margin: 1rem 0;
  }
  &--dark {
    background: #ffffff;
  }
  &--no-background {
    background: transparent;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #f0a000 !important;
    & > * {
      margin: 0 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
