.finished_campaign_section {
  padding: 1px !important;
  background: none !important;
}
.finished_campaigns_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__pie_className {
    fill: #ca9d00;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    width: 250px;
    line-height: 16px;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ca9d00 !important;
    @media (max-width: 1600px) {
      width: 200px;
    }
    @media (max-width: 1400px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
    }
  }
}

.FinishedCampaigns__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;

  color: #000000 !important;
}
