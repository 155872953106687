.react-tel-input .form-control {
  border: 1px solid black;
  height: 45px !important;
}
.contactEdit {
  &__body {
    background-color: white;
    border-radius: 12px;
    padding: 2rem 1.5rem;
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__bodyRow {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 2.5rem;
  }

  &__bodyColumn {
    width: 85%;
    margin-left: 3.5rem;
  }
  &__bodyColumnTitle {
    color: rgba(81, 90, 92, 1);
    font-weight: 600;
    letter-spacing: 1px;
  }
  &__bodyColumnInput {
    border: 1px solid gray;
    // color: gray !important;
    margin-top: 0.5rem;
    padding-left: 15px !important;
    height: 45px !important;
    font-weight: 600;
    letter-spacing: 1px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
  &__editButton:hover {
    background-color: white;
  }
}
.edit_inofo_title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;

  /* Light 100 */

  color: #2b1f0a;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}
.react-tel-input .special-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 124.68%;
  /* or 17px */

  /* Off Black - 6 */

  color: #515a5c;
}
.react-tel-input {
  width: 90%;
  border: 1px solid #dadada !important;
  border-radius: 10px;
}
.react-tel-input:hover {
  border: 1px solid #ffab03 !important;
  border-radius: 10px;
  selected-flag:hover {
    border: 1px solid #ffab03 !important;
  }
}
.react-tel-input .form-control {
  border: none !important;
}
.react-tel-input .form-control:focus {
  // border: 1px solid #ffab03 !important;
  box-shadow: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
}
