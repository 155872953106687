.campaignProgress {
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 25px;
    letter-spacing: 0.3px;

    color: #000000 !important;
    @media screen and (max-width: 1650px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 1500px) {
      font-size: 15px !important;
    }

  }
  &__container {
    height: 15rem;
    border-radius: 27px;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
    background-color: #fff;
    padding: 20px 30px;
    // width: 350px;
  }
  &__innerContainer {
    // margin: auto;
    width: 150px;
    margin-right: 2rem;
    &__pie_className {
      font-size: 18px;
      font-weight: 700;
      fill: #263238;
    }
    @media screen and (max-width:1750px) {
      width: 140px;
    }
    @media screen and (max-width:1550px) {
      width: 130px;
    }
    @media screen and (max-width:1350px) {
      width: 115px;
    }
  }
  &__right {
    div {
      color: hsl(0, 0%, 0%);
      font-size: 16px;
      margin: 1.5rem 0;
      display: flex;
      letter-spacing: 1px;
      @media screen and (max-width:1550px) {
        font-size: 14px;
      }
    }
    span {
      width: 18px;
      height: 18px;
      border-radius: 20%;
      margin-right: 0.5rem;
    }
  }

  &__link {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 999;
  }
  &__linkAnchore {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    color: #ffab03;
    z-index: 999;
    :hover {
      background-color: green;
      cursor: pointer;
    }
  }
  &__linkAnchore:hover {
    cursor: pointer;
  }
}
