@import 'tokens.scss';

.UploadBannerModal {
  padding: 2rem;
  display: grid;
  gap: 1rem;
  width: 898px;
  height: 872px;
  position: relative;

  @media (max-width: 699px) {
    padding: 1rem;
    max-width: 310px;
  }

  &__upload_area_layout {
    .UploadAreaLayout {
      @media (max-width: 699px) {
        margin-bottom: 50px !important;
      }
    }
  }

  &__hl {
    border: 1px solid #e3e4e5;
    height: $pixels-2;
    width: 100%;
    background-color: $color-grey-300;
  }
  &__creative-placeholder {
    border: $border-width-default solid $color-grey-300;
    background-color: #eeeff0;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: -35px;
    padding-bottom: 2rem;
  }
  &__cancel-button {
    margin-right: 2rem;
    padding: 1px 3px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    background-color: white;
    cursor: pointer;
    border: none;

    /* Light 400 */

    color: #6d6d6d;
  }
}

.gridSizing {
  padding: 15px;

  @media (max-width: 699px) {
    padding: 10px;
  }
}
.gridSizing:nth-child(1) {
  margin-left: 2rem;
  // border: 1px solid #e3e4e5;

  // border-left: none;
  // border-top: none;
  // border-right: none;
}

.gridSizing:nth-child(2) {
  // border: 1px solid #e3e4e5;

  // border-left: none;
  // border-top: none;
  // border-right: none;
  // border-bottom: none;
}
.gridSizing:nth-child(3) {
  // border: 1px solid #e3e4e5;

  // border-left: none;
  // border-top: none;
  // border-right: none;
}
.gridSizing:nth-child(4) {
  // border: 1px solid #e3e4e5;
  // border-right: none;
  // border-top: none;
}
.gridSizing:nth-child(5) {
  // border: 1px solid #e3e4e5;
  // border-right: none;
  // border-top: none;
  margin-left: 2rem;
}
// .gridSizing:nth-child() {
//   border: 1px solid;
// }
.gridSizing:nth-child(6) {
  // border-left: 1px solid #e3e4e5;
  margin-left: 1rem;
}

.upload-creative-btn {
  margin-top: -127px;
  // max-width: 43%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // margin-left: 197px;
  margin-bottom: 68px;

  @media (max-width: 699px) {
    margin-top: -86px;
    margin-bottom: 68px;
    max-width: 100%;
    margin-left: 0px;

    button {
      width: 80%;
    }
  }
}
.upload_video_confirm {
  padding: 20px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Light 100 */

  color: #2b1f0a;
}
.upload_video_cancel {
  padding: 20px 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Light 100 */

  color: #2b1f0a;
}
