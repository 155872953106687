@import 'tokens.scss';

.DateRangeSelector {
  background-color: $color-white;
  // padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  padding: 15px;
  &__body {
    display: flex;
    // border-top: 1px solid lightgray;

    @media (max-width: 699px) {
      flex-direction: column;
    }
  }
  &__start_end_date {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    color: #2b1f0a;
    margin-top: -2.7rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__pick_timeframe {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 33px !important;
    color: #2b1f0a !important;
  }
  &__pick_timeframe_subheading {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 23px !important;
    color: #6d6d6d !important;
  }
  &__date-selection {
    flex-basis: max-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 699px) {
      padding: 0;
    }
  }
  &__date-display {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid lightgray;

    @media (max-width: 699px) {
      border-left: 0;
    }
  }
  &__count_days {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: #ffab03;
  }
}
