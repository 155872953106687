.CampaignCheckout {
  height: 100%;
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height: 41px;
    display: flex;
    align-items: center;
    color: #2b1f0a !important;

    height: 24px;

    @media (max-width: 699px) {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }
  &__add_display {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin: 0;

    /* Light 400 */

    color: #6d6d6d;
  }

  .CampaignCreationWrapper {
    flex-direction: column;
  }
}
.campaignBack {
  border: 1px solid #bd0000;
  width: 212.76px;
  height: 55px;
  justify-content: center;
  padding: 20px 40px;
  background: rgba(201, 34, 45, 0.08);

  border-radius: 10px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 21.5205px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bd0000;
}
.campaignBack:hover {
  background: none;
  // border: inherit;
  color: #bd0000;
}
