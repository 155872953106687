@import 'tokens.scss';

.UploadVideoModal {
  padding: 1.6rem;
  display: grid;
  gap: 1.1rem;

  @media (max-width: 699px) {
    padding: 1rem;
    text-align: center;
    max-width: 310px;

    .thumbsContainer {
      display: flex;
      justify-content: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-button {
    margin-right: 1rem;
  }
}
