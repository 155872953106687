@import 'tokens.scss';

// override default leaflet styles
.leaflet-container a.leaflet-popup-close-button {
  color: $text-on-primary;
  background: $color-white;
  height: 25px;
  width: 25px;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: $box-shadow-400;
  &:hover {
    box-shadow: $box-shadow-300-dark;
    color: $text-on-primary-darker;
  }
}

.leaflet-popup-tip-container {
  // display: none;
}

.MapPopup {
  &__header {
    // background: $color-primary;
    padding: 1rem;
    white-space: nowrap;
    font-size: $text-size-m;
    // text-align: center;
  }
  &__buzzStatus {
    background-color: #f68533;
    text-align: center;
    color: white;
    width: 124px;
    height: 24px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
  }
}
