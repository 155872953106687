@import 'src/tokens';

.layout_container {
  background-color: #f8f8f8;
  height: 100%;
  border-radius: 18px;

  .layout {
    // max-width: 1650px;
    max-width: 1920px;
    margin: 0 auto;
  }
}
