.SaveAudienceModal {
  min-width: 30%;
  width: 50%;
  &__content {
    padding: 1rem;
    max-width: 50vw;
    margin: 0 auto;
  }
  &__Header {
    font-size: 40px;
    line-height: 48px;
  }
  &__cancel {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
    @media screen and (max-width: 650px) {
      right: -6rem;
    }
    @media screen and (max-width: 600px) {
      right: 1rem;
    }
  }
  @media screen and (max-width: 699px) {
    &__insideText {
      font-size: 17px !important;
    }
  }
}
.targetAudience {
  &__btn {
    border: 1px solid #dadada;
    border-radius: 12px;
    color: #6d6d6d;
    height: 45px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  &__btn:hover {
    color: #6d6d6d;
    background-color: #fff;
  }
  &__tags {
    border: 1px solid #f6d301;
    color: #f6d301;
    border-radius: 12px;
    border-color: #6d6d6d;
    color: #6d6d6d;
    height: 45px;
    margin: 0.5rem 0.5rem;
    width: auto;
    margin-bottom: 1rem;
    padding-right: 15px;
  }
  &__tags:hover {
    &___tagsCross {
      display: grid;
    }
  }
  &__tagsCross {
    position: absolute;
    top: 24px;
    right: 20px;
    color: #6d6d6d;
    font-size: 15px;
    display: none;
  }
}
