@import 'tokens';

.MyListings {
  &__header {
    display: flex;
    align-items: baseline;
  }
  &__status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius-m;
    background: $color-grey-100;
    background: #2ad90e;
    color: white;
    font-weight: bold;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: $color-grey-200;
    padding: 6rem;
  }
  &__FooterBtn {
    box-shadow: none;
  }
  &__FooterBtn:hover {
    background-color: #fff;
  }
}
.table-padding {
  padding: 1.5rem 0rem !important;
}
.header-user-title {
  font-size: 21px !important;
}
.header-users-search {
  width: 85% !important;
}
.header-users {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px !important;
}
