@import 'tokens.scss';
.prospects_heading {
  font-style: normal;
  font-weight: 700 !important;

  line-height: 124.19%;
  /* or 30px */

  display: flex;
  align-items: center;

  color: #aab2b5;
}
.ProspectFinderSelection {
  &__toolbar {
    background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
    height: 53px;
    text-align: center;
  }
  &__toolbarText {
    padding-top: 15px;
  }
  &__header {
    background: $color-primary-100;
    padding: 1rem;
  }
  &__layout {
    background-color: inherit !important;
  }
  &__prospect_logo {
    width: 100px;
  }
  &__content {
    display: grid;
    gap: 1rem;
    padding: 1rem 1.3%;
  }
  &__contentHeader {
    margin: 0 0 0.5rem 0;

    @media (max-width: 1050px) {
      margin: 1rem 0 0.5rem 0;
    }
  }

  &__sub_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 26px;
    line-height: 35px;

    color: #000000;
    @media (max-width: 1050px) {
      margin: 18px 0 5px;
    }
  }
  &__section {
    // display: grid;
    display: flex;
    gap: 1.8rem;
    // padding-bottom: 3rem;
  }
  &__wrap {
    flex-wrap: wrap;
  }
  .LargeIconButton {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
}

.ProspectFinderHeader {
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 33px;
    color: #000000 !important;
  }
}
