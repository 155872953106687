@import 'src/tokens';
@import 'src/mixins';

.CompactSavedAudiences {
  max-height: calc(100vh - 450px);
  overflow: auto;
  &__audience-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    border: 1px solid transparent;
    border: 1px solid #c6cbcd;

    // &:hover {
    //   border: 1px solid black;
    // }
  }
  &__audience_headings {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px!important;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #2b1f0a!important;
  }
  &__image {
    flex-grow: 1;
    padding-right: 1rem;
  }
  &__body {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
  }
  &__description {
    flex-grow: 4;
    padding-left: 1rem;
  }
  &__prospects {
    flex-grow: 1;
    display: flex;
    padding-left: 1rem;
    align-items: center;

    @media (max-width: 699px) {
      padding-left: 0;
    }
  }
  &__prospects-text {
    display: flex;
  }
  &__border {
    border-left: 1px solid #aab2b5;
    height: 68px;
    padding-right: 22px;
  }
  &__text-display {
    display: flex;
    align-items: center;
  }
  &__span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  }
  &__prospect-name {
    padding-left: 6px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #6d797c;
  }

  .CompactSavedAudiences__audience-container {
    height: 65px;
  }
  .CompactSavedAudiences__image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
    }
  }
}
