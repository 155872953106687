.deleteConfirmationModal {
  overflow: hidden;
  position: relative;
  width: 550px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;

  &__heading {
    padding: 0px 10%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 25px !important;
    line-height: 35px !important;
    color: #000000 !important;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 63%;
  }
  &__yes_button_modal {
    padding: 23px 20px;
    font-family: 'Open Sans';
    width: 40% !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */
    text-align: center;
    z-index: 9999;
    color: #000000;
  }
  &__no_button_modal {
    padding: 23px 20px;
    font-family: 'Open Sans';
    font-style: normal;
    width: 40% !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    text-align: center;
    z-index: 9999;
    color: #000000;
  }
}
.rightImage {
  position: absolute;
  bottom: -18%;
  right: 0px;
  width: 32%;
}
.leftImage {
  position: absolute;
  top: -20px;
  left: 15px;
  width: 25%;
}
