.MapMarker {
  height: 20px;
  width: 20px;
  position: relative;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  &__svg-icon {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    display: block;
    height: 2rem;
  }
  &__svg-icon-large {
    height: 63px;
    width: 6rem;
  }
  &__starIcon {
    position: absolute;
    top: -17px;
    left: 3px;
    z-index: 999;
  }
  &__starIconBig {
    position: absolute;
    top: -42px !important;
    left: -3px !important;
    z-index: 222;
  }
  &__svg-marker-path {
    // stroke: white;
    stroke-width: 50;
    stroke-width: 40;
    stroke-miterlimit: 10;
  }
  &__svg-marker-path-selected {
    stroke: red;
    stroke-width: 70;
    stroke-width: 60;
    stroke-miterlimit: 10;
  }
  &__svg-circle {
    fill: white;
  }
}
