.uploadcsvModal {
  position: relative;
  &__cross {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  &__points {
    font-size: 14px;
    font-weight: 400;
    max-width: 600px;
    border-left: 4px solid #fcd917;
    line-height: 25px;
    color: #2b1f0a;
    font-family: sans-serif;
  }
  &__StepOne_heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 2px;
    margin-bottom: -5px;

    /* Primary 500 */

    color: #ffab03;
  }
  &__StepOne_sub_heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 14px;
    /* Black - 7 */

    color: #000000;
  }
  &__StepOne_description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #6d6d6d;
  }
  &__points > ol {
    @media (max-width: 699px) {
      padding-left: 1.5rem;
    }
  }

  &__points > ol > li {
    padding: 5px 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 158.7%;
    /* or 25px */

    /* Light 400 */

    color: #6d6d6d;
  }
  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 50px;
    height: 90px;
  }
  &__example {
    background: #fff9ed;
    border-radius: 10px;
    padding: 20px 20px !important;
    color: #ffab03 !important;
    border: 1px solid #ffab03 !important;
    width: 20%;

    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__example:hover {
    background: none;
    border: 1px solid #1ba1c5;
    color: #1ba1c5;
    // width: 20%;
  }
  .example_img_container {
    @media (max-width: 699px) {
      img {
        width: 100%;
        height: 140px;
      }
    }
  }
}
.UploadBannerModal {
  &__cancel-buttons {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;

    background-color: white !important;
    /* Light 400 */

    color: #6d6d6d;
    border: none;
  }
  &__confirm-button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Light 100 */

    color: #2b1f0a;
  }
}
