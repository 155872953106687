@import 'tokens.scss';
@import 'src/mixins';

.Uploader {
  @include focus-ring;
  cursor: pointer;
  // border: dashed $border-width-default $color-grey-300;
  background: #fff9ed;
  border: 2px dashed #ffab03;
  border-radius: 9px;
}

.upload_your_video {
  padding: 35px 0px;

  @media (max-width: 699px) {
    padding: 0;
  }
}
