@import 'src/tokens';

.TableSelectionWrapper {
  &__table-wrapper {
    padding: 1.5rem 1rem;
    // max-width: 110rem;
    margin: 0 auto;
    position: relative;
    background: #f8f8f8;
    border-radius: 20px;
    &--selection {
      &::before {
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #ffab03;
        left: 2.6rem;
        top: 0;
      }
    }
  }
  &__selection_text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px!important;
    line-height: 25px!important;
    color: #ffffff!important;
  }
  &__selection-header {
    background: #ffab03;
    border-radius: 10px;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 1rem 1rem 0;
    &__actions {
      display: flex;
    }
  }
}
