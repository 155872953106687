.uploadcsvModal {
  &__points {
    font-size: 14px;
    font-weight: 400;
    max-width: 600px;
    border-left: 4px solid #fcd917;
    line-height: 25px;
    color: #2b1f0a;
    font-family: sans-serif;
  }
  &__points > ol {
    @media (max-width: 699px) {
      padding-left: 1.5rem;
    }
  }

  &__points > ol > li {
    padding: 5px 0;
  }
  &__points > ol > li > i {
    color: #aab2b5;
  }
  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
  }
  &__example {
    border: 1px solid #1ba1c5;
    color: #1ba1c5;
    width: 20%;

    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__example:hover {
    background: none;
    border: 1px solid #1ba1c5;
    color: #1ba1c5;
    // width: 20%;
  }
  .text_area_container {
    padding: 11px 15px;
    border: 1px solid #c6cbcd;
  }
  .text_area {
    height: 136px;
    width: 100%;
    resize: none;
    border-color: #c6cbcd;
    outline: none;
  }

  .example_img_container {
    @media (max-width: 699px) {
      img {
        width: 100%;
        height: 140px !important;
      }
    }
  }
}
