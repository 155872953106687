@import 'tokens.scss';

.AttachedCreatives {
  padding: 1rem;
  // background-color: $color-white;
  &__icon {
    margin-right: 0.5rem;
  }
  &__hl {
    border: 1px solid #e3e4e5;
    height: $pixels-2;
    margin-block: 10px;
    width: 100%;
    background-color: $color-grey-300;
  }

  // .link_input_field:hover {
  //   border: 1px solid #ffab03 !important;
  // }
  // .link_input_field_err:hover {
  //   border: 1px solid #bd0000 !important;
  // }
  .link_input_field:not(:focus):hover {
    border: 1px solid #ffab03 !important;
  }
  .link_input_field:focus {
    border: 1px solid #ffab03;
  }
  .link_input_field {
    // border: 1.5px solid #f0f0f0 !important;
    border-radius: 10px !important;
    background: none !important;

    width: 400px !important;

    @media (max-width: 699px) {
      width: 100% !important;
    }
    @media screen and (max-width: 500px) {
      padding-left: 83px !important;
      padding-top: 5px !important;
    }
  }
}
.center-image-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__text {
    border: none;
    background: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 25px;
    text-decoration-line: underline;
    color: #ffab03;
    font-family: 'Open Sans';
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    justify-content: center;
  }
}
.image-box {
  // border: 1px solid #aab2b5;
  position: relative;
  width: 143px;
  background: #eeeeee;
  border-radius: 6px;
  // height: 105px;

  &__img-1 {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    z-index: 1002;
  }
  &__img-2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 9px;
    height: 132px;
    object-fit: contain;
    width: 86%;
    filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.15));
  }
}
.image-box:hover {
  background: #eeeeee;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.22);
  // 1px 3px 19px -3px rgba(0, 0, 0, 0.27);
  // width: 163px;
  height: 163px;
}
.image-box:hover .image-box__img-2 {
  height: 140px;
  // margin-top: 4px;
  // margin-bottom: 9px;
}
// .image-box__img-2:hover {
//   height: 163px;
// }
.padding-Icon {
  padding: 20px 0px 8px 13px !important;
}
.sizeInput {
  // width: 66% !important;
  height: 50px !important;
  margin-top: 4px;
  border-color: #aab2b5 !important;
}

.creative-error {
  // margin-top: 58px !important;
}
.ImageWithLink {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  gap: 1.4rem;
}
.your_creative_heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #2b1f0a !important;
}
.your_creative_subheading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #6d6d6d !important;
}
