@import 'tokens.scss';
.LensSidebarHeader__back-button{
  color: #ffab03!important;
  font-weight: bold!important;

}
.LensSidebarHeader {
  &__back-button {
    display: inline-block;
    border-radius: $border-radius-m;
    margin: 1rem 2rem 0 2rem;
    font-weight: 700;
    // padding: 0.125rem 0.5rem;
    &:link,
    &:visited {
      color: $text-on-white--subdued;
      text-decoration: none;
    }
    &:hover {
      color: $text-on-grey-100;
      background-color: $color-grey-100;
    }
  }
  &__collapse {
    float: right;
    margin-top: 1rem;
    margin-right: 1.5rem;
    // color: lightgray;
  }
  &__divider {
    background-color: $color-grey-200;
    height: $pixels-2;
    margin-bottom: 0.5rem;
  }
  &__heading {
    margin: 0.5rem 2rem;
    color: black;
    font-size: $text-size-xxxl;
    font-weight: $text-weight-bold;

    @media (max-width: 1050px) {
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    &__sub {
      font-size: $text-size-xl;
      margin-block: 4px !important;
    }
  }
  &__InactiveTooltip {
    position: absolute;
    z-index: 1;
    background: rgb(59, 57, 57);
    border-radius: 6px;
    padding: 5px 8px;
    color: white;
    top: -25px;
    right: 0;
    font-size: 10px;
  }
  &__sub-heading {
    margin: 0rem 2rem 0.5rem;
    line-height: 24px;

    @media (max-width: 1050px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 0px;
      // margin-bottom: 0;
    }

    &__sub {
      margin-block: 8px;
    }
  }
}
