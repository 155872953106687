.PersonalInfoForm {
  display: flex;
  padding: 5rem 1rem;
  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
  }
  @media (max-width: 1400px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-top: 3rem;
  }

  @media (max-width: 699px) {
    padding-top: 2rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 310px;
  }

  &__header {
    max-width: 350px;

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 40px !important;
      line-height: 54px !important;
      display: flex !important;
      align-items: center !important;

      /* Primary 500 */

      color: #ffab03 !important;

      display: block;
      margin-bottom: 1rem;

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }
    .sub_heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 26px !important;
      /* or 118% */

      color: #818181 !important;
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 2rem;
    border-right: 1px solid #c6cbcd;
    // border-bottom: 1px solid #c6cbcd;

    @media (max-width: 1400px) {
      padding: 0rem 2rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }
  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }
  &__progress {
    margin: 1rem 0;
  }
  &__inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    // border-left: 1px solid #c6cbcd;
    // border-top: 1px solid #c6cbcd;
    // border-bottom: 1px solid #c6cbcd;
    padding: 1rem 3rem;
    padding-right: 6rem;
    padding-bottom: 3rem;

    @media (max-width: 1050px) {
      width: 100%;
      border-left: none;
      border-top: none;
    }
  }
  &__submit {
    position: absolute;
    top: 30px;
    right: 0px;
    // right: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}
