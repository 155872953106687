@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/HelveticaNeue.ttf');
}
.Text {
  &--truncated {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  font-family: 'Open Sans';
  color: #2b1f0a !important;
  line-height: 21px;
}
