.table_wrapper {
  margin: 1rem 1rem 0;
  overflow: auto;
  overflow-y: hidden;
  border-radius: 20px;
}

.mainTable {
  width: 100%;
  border-collapse: separate;
  border-radius: 20px;
  //   border-spacing: 8px 8px;
  border-spacing: 0 2px;
  background-color: #f0f0f0;
}
.customTableRow {
  font-family: 'Open Sans';
  font-weight: 600 !important;
  color: #000000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  text-align: start;
  background-color: white !important;

  th {
    text-align: start !important;
    // padding: 1rem 2rem;
    white-space: nowrap;
  }
}
.customTableRowSelected {
  font-family: 'Open Sans';
  font-weight: 600 !important;
  color: #000000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  text-align: start;
  background-color: white !important;
  th {
    text-align: start !important;
    // padding: 1rem 0;
    white-space: nowrap;
  }
}

.mainTableSelected {
  width: 100%;
  border-collapse: separate;
  border-radius: 20px;
  //   border-spacing: 8px 8px;
  border-spacing: 0 1px;
  background-color: #f0f0f0;
  overflow: hidden;
  margin-top: 1rem;
}
.mainTableTrSelected {
  border-collapse: none;
  border-spacing: 2px;
  background-color: white;
  outline: 1px solid #ffab03;
  outline-offset: -3px;
  width: 150px;
  text-align: center;
  border: 1px solid black;
}

.mainTableTrSelected:hover {
  background-color: #fff7c9;
}
.mainTableTr {
  border-collapse: none;
  border-spacing: 2px;
  background-color: white;
  // outline: 1px solid #f0f0f0;
  // border: 1px solid black !important;
  width: 150px;
  text-align: center;
  border: 1px solid black;
}
.mainTableTr:hover {
  background-color: #fff7c9;
}
.mainTableTdSelected {
  // width: 150px;
  text-align: start;
  padding: 1rem 1rem;
  position: relative;
  font-family: 'Open Sans';
  font-weight: 400;
  color: #757575;
  font-size: 18px;
  letter-spacing: 1px;

  line-height: 25px;
}
.mainTableTd {
  // width: 150px;
  text-align: start;
  // padding: 1rem 2rem;
  position: relative;
  font-family: 'Open Sans';
  font-weight: 400;
  color: #757575;
  font-size: 18px;
  letter-spacing: 1px;

  line-height: 25px;
}

.table_checkbox {
  display: flex;
  justify-content: center;
  input[type='checkbox'] {
    position: relative;
    border: 1px solid #c6cbcd;
    border-radius: 4px;
    background: #f8f8f8;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: 0.5;
  }

  input[type='checkbox']:hover {
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: #ffff;
    border: 1px solid #c6cbcd;
    border-radius: 4px;
    opacity: 1;
  }

  input[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid #ffab03;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
}
th:nth-child(1) .sortedIconChecked {
  display: none;
}
th:last-child .sortedIcon {
  display: none;
}
.sortedIcon {
  margin-left: 8px;
}
