$color-white: var(--color-white);

$color-grey-050: var(--color-grey-050);
$color-grey-100: var(--color-grey-100);
$color-grey-200: var(--color-grey-200);
$color-grey-300: var(--color-grey-300);
$color-grey-400: var(--color-grey-400);
$color-grey-500: var(--color-grey-500);
$color-grey-600: var(--color-grey-600);
$color-grey-700: var(--color-grey-700);
$color-grey-800: var(--color-grey-800);
$color-grey-900: var(--color-grey-900);

$color-primary-050: var(--color-primary-050);
$color-primary-100: var(--color-primary-100);
$color-primary-200: var(--color-primary-200);
$color-primary-300: var(--color-primary-300);
$color-primary-400: var(--color-primary-400);
$color-primary-500: var(--color-primary-500);
$color-primary-600: var(--color-primary-600);
$color-primary-700: var(--color-primary-700);
$color-primary-800: var(--color-primary-800);
$color-primary-900: var(--color-primary-900);

$color-secondary-050: var(--color-secondary-050);
$color-secondary-100: var(--color-secondary-100);
$color-secondary-200: var(--color-secondary-200);
$color-secondary-300: var(--color-secondary-300);
$color-secondary-400: var(--color-secondary-400);
$color-secondary-500: var(--color-secondary-500);
$color-secondary-600: var(--color-secondary-600);
$color-secondary-700: var(--color-secondary-700);
$color-secondary-800: var(--color-secondary-800);
$color-secondary-900: var(--color-secondary-900);

$color-tertiary-050: var(--color-tertiary-050);
$color-tertiary-100: var(--color-tertiary-100);
$color-tertiary-200: var(--color-tertiary-200);
$color-tertiary-300: var(--color-tertiary-300);
$color-tertiary-400: var(--color-tertiary-400);
$color-tertiary-500: var(--color-tertiary-500);
$color-tertiary-600: var(--color-tertiary-600);
$color-tertiary-700: var(--color-tertiary-700);
$color-tertiary-800: var(--color-tertiary-800);
$color-tertiary-900: var(--color-tertiary-900);

$color-success-050: var(--color-success-050);
$color-success-100: var(--color-success-100);
$color-success-200: var(--color-success-200);
$color-success-300: var(--color-success-300);
$color-success-400: var(--color-success-400);
$color-success-500: var(--color-success-500);
$color-success-600: var(--color-success-600);
$color-success-700: var(--color-success-700);
$color-success-800: var(--color-success-800);
$color-success-900: var(--color-success-900);

$color-warning-050: var(--color-warning-050);
$color-warning-100: var(--color-warning-100);
$color-warning-200: var(--color-warning-200);
$color-warning-300: var(--color-warning-300);
$color-warning-400: var(--color-warning-400);
$color-warning-500: var(--color-warning-500);
$color-warning-600: var(--color-warning-600);
$color-warning-700: var(--color-warning-700);
$color-warning-800: var(--color-warning-800);
$color-warning-900: var(--color-warning-900);

$color-danger-050: var(--color-danger-050);
$color-danger-100: var(--color-danger-100);
$color-danger-200: var(--color-danger-200);
$color-danger-300: var(--color-danger-300);
$color-danger-400: var(--color-danger-400);
$color-danger-500: var(--color-danger-500);
$color-danger-600: var(--color-danger-600);
$color-danger-700: var(--color-danger-700);
$color-danger-800: var(--color-danger-800);
$color-danger-900: var(--color-danger-900);

$text-on-primary-050: var(--text-on-primary-050);
$text-on-primary-050--subdued: var(--text-on-primary-050--subdued);
$text-on-primary-050--vivid: var(--text-on-primary-050--vivid);
$text-on-primary-050--vivid-subdued--UNSAFE: var(
  --text-on-primary-050--vivid-subdued--UNSAFE
);

$text-on-primary-100: var(--text-on-primary-100);
$text-on-primary-100--subdued: var(--text-on-primary-100--subdued);
$text-on-primary-100--vivid: var(--text-on-primary-100--vivid);
$text-on-primary-100--vivid-subdued--UNSAFE: var(
  --text-on-primary-100--vivid-subdued--UNSAFE
);

$text-on-primary-200: var(--text-on-primary-200);
$text-on-primary-200--subdued: var(--text-on-primary-200--subdued);
$text-on-primary-200--vivid: var(--text-on-primary-200--vivid);
$text-on-primary-200--vivid-subdued--UNSAFE: var(
  --text-on-primary-200--vivid-subdued--UNSAFE
);

$text-on-primary-300: var(--text-on-primary-300);
$text-on-primary-300--subdued--UNSAFE: var(
  --text-on-primary-300--subdued--UNSAFE
);
$text-on-primary-300--vivid: var(--text-on-primary-300--vivid);
$text-on-primary-300--vivid-subdued--UNSAFE: var(
  --text-on-primary-300--vivid-subdued--UNSAFE
);

$text-on-primary-400: var(--text-on-primary-400);
$text-on-primary-400--subdued--UNSAFE: var(
  --text-on-primary-400--subdued--UNSAFE
);
$text-on-primary-400--vivid: var(--text-on-primary-400--vivid);
$text-on-primary-400--vivid-subdued--UNSAFE: var(
  --text-on-primary-400--vivid-subdued--UNSAFE
);

$text-on-primary-500: var(--text-on-primary-500);
$text-on-primary-500--subdued--UNSAFE: var(
  --text-on-primary-500--subdued--UNSAFE
);
$text-on-primary-500--vivid: var(--text-on-primary-500--vivid);
$text-on-primary-500--vivid-subdued--UNSAFE: var(
  --text-on-primary-500--vivid-subdued--UNSAFE
);

$text-on-primary-600: var(--text-on-primary-600);
$text-on-primary-600--subdued--UNSAFE: var(
  --text-on-primary-600--subdued--UNSAFE
);
$text-on-primary-600--vivid: var(--text-on-primary-600--vivid);
$text-on-primary-600--vivid-subdued--UNSAFE: var(
  --text-on-primary-600--vivid-subdued--UNSAFE
);

$text-on-primary-700: var(--text-on-primary-700);
$text-on-primary-700--subdued: var(--text-on-primary-700--subdued);
$text-on-primary-700--vivid: var(--text-on-primary-700--vivid);
$text-on-primary-700--vivid-subdued--UNSAFE: var(
  --text-on-primary-700--vivid-subdued--UNSAFE
);

$text-on-primary-800: var(--text-on-primary-800);
$text-on-primary-800--subdued: var(--text-on-primary-800--subdued);
$text-on-primary-800--vivid: var(--text-on-primary-800--vivid);
$text-on-primary-800--vivid-subdued--UNSAFE: var(
  --text-on-primary-800--vivid-subdued--UNSAFE
);

$text-on-primary-900: var(--text-on-primary-900);
$text-on-primary-900--subdued: var(--text-on-primary-900--subdued);
$text-on-primary-900--vivid: var(--text-on-primary-900--vivid);
$text-on-primary-900--vivid-subdued--UNSAFE: var(
  --text-on-primary-900--vivid-subdued--UNSAFE
);

$text-on-secondary-050: var(--text-on-secondary-050);
$text-on-secondary-050--subdued: var(--text-on-secondary-050--subdued);
$text-on-secondary-050--vivid: var(--text-on-secondary-050--vivid);
$text-on-secondary-050--vivid-subdued--UNSAFE: var(
  --text-on-secondary-050--vivid-subdued--UNSAFE
);

$text-on-secondary-100: var(--text-on-secondary-100);
$text-on-secondary-100--subdued: var(--text-on-secondary-100--subdued);
$text-on-secondary-100--vivid: var(--text-on-secondary-100--vivid);
$text-on-secondary-100--vivid-subdued--UNSAFE: var(
  --text-on-secondary-100--vivid-subdued--UNSAFE
);

$text-on-secondary-200: var(--text-on-secondary-200);
$text-on-secondary-200--subdued: var(--text-on-secondary-200--subdued);
$text-on-secondary-200--vivid: var(--text-on-secondary-200--vivid);
$text-on-secondary-200--vivid-subdued--UNSAFE: var(
  --text-on-secondary-200--vivid-subdued--UNSAFE
);

$text-on-secondary-300: var(--text-on-secondary-300);
$text-on-secondary-300--subdued--UNSAFE: var(
  --text-on-secondary-300--subdued--UNSAFE
);
$text-on-secondary-300--vivid: var(--text-on-secondary-300--vivid);
$text-on-secondary-300--vivid-subdued--UNSAFE: var(
  --text-on-secondary-300--vivid-subdued--UNSAFE
);

$text-on-secondary-400: var(--text-on-secondary-400);
$text-on-secondary-400--subdued--UNSAFE: var(
  --text-on-secondary-400--subdued--UNSAFE
);
$text-on-secondary-400--vivid: var(--text-on-secondary-400--vivid);
$text-on-secondary-400--vivid-subdued--UNSAFE: var(
  --text-on-secondary-400--vivid-subdued--UNSAFE
);

$text-on-secondary-500: var(--text-on-secondary-500);
$text-on-secondary-500--subdued--UNSAFE: var(
  --text-on-secondary-500--subdued--UNSAFE
);
$text-on-secondary-500--vivid: var(--text-on-secondary-500--vivid);
$text-on-secondary-500--vivid-subdued--UNSAFE: var(
  --text-on-secondary-500--vivid-subdued--UNSAFE
);

$text-on-secondary-600: var(--text-on-secondary-600);
$text-on-secondary-600--subdued--UNSAFE: var(
  --text-on-secondary-600--subdued--UNSAFE
);
$text-on-secondary-600--vivid: var(--text-on-secondary-600--vivid);
$text-on-secondary-600--vivid-subdued--UNSAFE: var(
  --text-on-secondary-600--vivid-subdued--UNSAFE
);

$text-on-secondary-700: var(--text-on-secondary-700);
$text-on-secondary-700--subdued: var(--text-on-secondary-700--subdued);
$text-on-secondary-700--vivid: var(--text-on-secondary-700--vivid);
$text-on-secondary-700--vivid-subdued--UNSAFE: var(
  --text-on-secondary-700--vivid-subdued--UNSAFE
);

$text-on-secondary-800: var(--text-on-secondary-800);
$text-on-secondary-800--subdued: var(--text-on-secondary-800--subdued);
$text-on-secondary-800--vivid: var(--text-on-secondary-800--vivid);
$text-on-secondary-800--vivid-subdued--UNSAFE: var(
  --text-on-secondary-800--vivid-subdued--UNSAFE
);

$text-on-secondary-900: var(--text-on-secondary-900);
$text-on-secondary-900--subdued: var(--text-on-secondary-900--subdued);
$text-on-secondary-900--vivid: var(--text-on-secondary-900--vivid);
$text-on-secondary-900--vivid-subdued--UNSAFE: var(
  --text-on-secondary-900--vivid-subdued--UNSAFE
);

$text-on-tertiary-050: var(--text-on-tertiary-050);
$text-on-tertiary-050--subdued: var(--text-on-tertiary-050--subdued);
$text-on-tertiary-050--vivid: var(--text-on-tertiary-050--vivid);
$text-on-tertiary-050--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-050--vivid-subdued--UNSAFE
);

$text-on-tertiary-100: var(--text-on-tertiary-100);
$text-on-tertiary-100--subdued: var(--text-on-tertiary-100--subdued);
$text-on-tertiary-100--vivid: var(--text-on-tertiary-100--vivid);
$text-on-tertiary-100--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-100--vivid-subdued--UNSAFE
);

$text-on-tertiary-200: var(--text-on-tertiary-200);
$text-on-tertiary-200--subdued: var(--text-on-tertiary-200--subdued);
$text-on-tertiary-200--vivid: var(--text-on-tertiary-200--vivid);
$text-on-tertiary-200--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-200--vivid-subdued--UNSAFE
);

$text-on-tertiary-300: var(--text-on-tertiary-300);
$text-on-tertiary-300--subdued--UNSAFE: var(
  --text-on-tertiary-300--subdued--UNSAFE
);
$text-on-tertiary-300--vivid: var(--text-on-tertiary-300--vivid);
$text-on-tertiary-300--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-300--vivid-subdued--UNSAFE
);

$text-on-tertiary-400: var(--text-on-tertiary-400);
$text-on-tertiary-400--subdued--UNSAFE: var(
  --text-on-tertiary-400--subdued--UNSAFE
);
$text-on-tertiary-400--vivid: var(--text-on-tertiary-400--vivid);
$text-on-tertiary-400--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-400--vivid-subdued--UNSAFE
);

$text-on-tertiary-500: var(--text-on-tertiary-500);
$text-on-tertiary-500--subdued--UNSAFE: var(
  --text-on-tertiary-500--subdued--UNSAFE
);
$text-on-tertiary-500--vivid: var(--text-on-tertiary-500--vivid);
$text-on-tertiary-500--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-500--vivid-subdued--UNSAFE
);

$text-on-tertiary-600: var(--text-on-tertiary-600);
$text-on-tertiary-600--subdued--UNSAFE: var(
  --text-on-tertiary-600--subdued--UNSAFE
);
$text-on-tertiary-600--vivid: var(--text-on-tertiary-600--vivid);
$text-on-tertiary-600--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-600--vivid-subdued--UNSAFE
);

$text-on-tertiary-700: var(--text-on-tertiary-700);
$text-on-tertiary-700--subdued: var(--text-on-tertiary-700--subdued);
$text-on-tertiary-700--vivid: var(--text-on-tertiary-700--vivid);
$text-on-tertiary-700--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-700--vivid-subdued--UNSAFE
);

$text-on-tertiary-800: var(--text-on-tertiary-800);
$text-on-tertiary-800--subdued: var(--text-on-tertiary-800--subdued);
$text-on-tertiary-800--vivid: var(--text-on-tertiary-800--vivid);
$text-on-tertiary-800--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-800--vivid-subdued--UNSAFE
);

$text-on-tertiary-900: var(--text-on-tertiary-900);
$text-on-tertiary-900--subdued: var(--text-on-tertiary-900--subdued);
$text-on-tertiary-900--vivid: var(--text-on-tertiary-900--vivid);
$text-on-tertiary-900--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-900--vivid-subdued--UNSAFE
);

$text-on-success-050: var(--text-on-success-050);
$text-on-success-050--subdued: var(--text-on-success-050--subdued);
$text-on-success-050--vivid: var(--text-on-success-050--vivid);
$text-on-success-050--vivid-subdued--UNSAFE: var(
  --text-on-success-050--vivid-subdued--UNSAFE
);

$text-on-success-100: var(--text-on-success-100);
$text-on-success-100--subdued: var(--text-on-success-100--subdued);
$text-on-success-100--vivid: var(--text-on-success-100--vivid);
$text-on-success-100--vivid-subdued--UNSAFE: var(
  --text-on-success-100--vivid-subdued--UNSAFE
);

$text-on-success-200: var(--text-on-success-200);
$text-on-success-200--subdued: var(--text-on-success-200--subdued);
$text-on-success-200--vivid: var(--text-on-success-200--vivid);
$text-on-success-200--vivid-subdued--UNSAFE: var(
  --text-on-success-200--vivid-subdued--UNSAFE
);

$text-on-success-300: var(--text-on-success-300);
$text-on-success-300--subdued--UNSAFE: var(
  --text-on-success-300--subdued--UNSAFE
);
$text-on-success-300--vivid: var(--text-on-success-300--vivid);
$text-on-success-300--vivid-subdued--UNSAFE: var(
  --text-on-success-300--vivid-subdued--UNSAFE
);

$text-on-success-400: var(--text-on-success-400);
$text-on-success-400--subdued--UNSAFE: var(
  --text-on-success-400--subdued--UNSAFE
);
$text-on-success-400--vivid: var(--text-on-success-400--vivid);
$text-on-success-400--vivid-subdued--UNSAFE: var(
  --text-on-success-400--vivid-subdued--UNSAFE
);

$text-on-success-500: var(--text-on-success-500);
$text-on-success-500--subdued--UNSAFE: var(
  --text-on-success-500--subdued--UNSAFE
);
$text-on-success-500--vivid: var(--text-on-success-500--vivid);
$text-on-success-500--vivid-subdued--UNSAFE: var(
  --text-on-success-500--vivid-subdued--UNSAFE
);

$text-on-success-600: var(--text-on-success-600);
$text-on-success-600--subdued--UNSAFE: var(
  --text-on-success-600--subdued--UNSAFE
);
$text-on-success-600--vivid: var(--text-on-success-600--vivid);
$text-on-success-600--vivid-subdued--UNSAFE: var(
  --text-on-success-600--vivid-subdued--UNSAFE
);

$text-on-success-700: var(--text-on-success-700);
$text-on-success-700--subdued: var(--text-on-success-700--subdued);
$text-on-success-700--vivid: var(--text-on-success-700--vivid);
$text-on-success-700--vivid-subdued--UNSAFE: var(
  --text-on-success-700--vivid-subdued--UNSAFE
);

$text-on-success-800: var(--text-on-success-800);
$text-on-success-800--subdued: var(--text-on-success-800--subdued);
$text-on-success-800--vivid: var(--text-on-success-800--vivid);
$text-on-success-800--vivid-subdued--UNSAFE: var(
  --text-on-success-800--vivid-subdued--UNSAFE
);

$text-on-success-900: var(--text-on-success-900);
$text-on-success-900--subdued: var(--text-on-success-900--subdued);
$text-on-success-900--vivid: var(--text-on-success-900--vivid);
$text-on-success-900--vivid-subdued--UNSAFE: var(
  --text-on-success-900--vivid-subdued--UNSAFE
);

$text-on-warning-050: var(--text-on-warning-050);
$text-on-warning-050--subdued: var(--text-on-warning-050--subdued);
$text-on-warning-050--vivid: var(--text-on-warning-050--vivid);
$text-on-warning-050--vivid-subdued--UNSAFE: var(
  --text-on-warning-050--vivid-subdued--UNSAFE
);

$text-on-warning-100: var(--text-on-warning-100);
$text-on-warning-100--subdued: var(--text-on-warning-100--subdued);
$text-on-warning-100--vivid: var(--text-on-warning-100--vivid);
$text-on-warning-100--vivid-subdued--UNSAFE: var(
  --text-on-warning-100--vivid-subdued--UNSAFE
);

$text-on-warning-200: var(--text-on-warning-200);
$text-on-warning-200--subdued: var(--text-on-warning-200--subdued);
$text-on-warning-200--vivid: var(--text-on-warning-200--vivid);
$text-on-warning-200--vivid-subdued--UNSAFE: var(
  --text-on-warning-200--vivid-subdued--UNSAFE
);

$text-on-warning-300: var(--text-on-warning-300);
$text-on-warning-300--subdued--UNSAFE: var(
  --text-on-warning-300--subdued--UNSAFE
);
$text-on-warning-300--vivid: var(--text-on-warning-300--vivid);
$text-on-warning-300--vivid-subdued--UNSAFE: var(
  --text-on-warning-300--vivid-subdued--UNSAFE
);

$text-on-warning-400: var(--text-on-warning-400);
$text-on-warning-400--subdued--UNSAFE: var(
  --text-on-warning-400--subdued--UNSAFE
);
$text-on-warning-400--vivid: var(--text-on-warning-400--vivid);
$text-on-warning-400--vivid-subdued--UNSAFE: var(
  --text-on-warning-400--vivid-subdued--UNSAFE
);

$text-on-warning-500: var(--text-on-warning-500);
$text-on-warning-500--subdued--UNSAFE: var(
  --text-on-warning-500--subdued--UNSAFE
);
$text-on-warning-500--vivid: var(--text-on-warning-500--vivid);
$text-on-warning-500--vivid-subdued--UNSAFE: var(
  --text-on-warning-500--vivid-subdued--UNSAFE
);

$text-on-warning-600: var(--text-on-warning-600);
$text-on-warning-600--subdued--UNSAFE: var(
  --text-on-warning-600--subdued--UNSAFE
);
$text-on-warning-600--vivid: var(--text-on-warning-600--vivid);
$text-on-warning-600--vivid-subdued--UNSAFE: var(
  --text-on-warning-600--vivid-subdued--UNSAFE
);

$text-on-warning-700: var(--text-on-warning-700);
$text-on-warning-700--subdued: var(--text-on-warning-700--subdued);
$text-on-warning-700--vivid: var(--text-on-warning-700--vivid);
$text-on-warning-700--vivid-subdued--UNSAFE: var(
  --text-on-warning-700--vivid-subdued--UNSAFE
);

$text-on-warning-800: var(--text-on-warning-800);
$text-on-warning-800--subdued: var(--text-on-warning-800--subdued);
$text-on-warning-800--vivid: var(--text-on-warning-800--vivid);
$text-on-warning-800--vivid-subdued--UNSAFE: var(
  --text-on-warning-800--vivid-subdued--UNSAFE
);

$text-on-warning-900: var(--text-on-warning-900);
$text-on-warning-900--subdued: var(--text-on-warning-900--subdued);
$text-on-warning-900--vivid: var(--text-on-warning-900--vivid);
$text-on-warning-900--vivid-subdued--UNSAFE: var(
  --text-on-warning-900--vivid-subdued--UNSAFE
);

$text-on-danger-050: var(--text-on-danger-050);
$text-on-danger-050--subdued: var(--text-on-danger-050--subdued);
$text-on-danger-050--vivid: var(--text-on-danger-050--vivid);
$text-on-danger-050--vivid-subdued--UNSAFE: var(
  --text-on-danger-050--vivid-subdued--UNSAFE
);

$text-on-danger-100: var(--text-on-danger-100);
$text-on-danger-100--subdued: var(--text-on-danger-100--subdued);
$text-on-danger-100--vivid: var(--text-on-danger-100--vivid);
$text-on-danger-100--vivid-subdued--UNSAFE: var(
  --text-on-danger-100--vivid-subdued--UNSAFE
);

$text-on-danger-200: var(--text-on-danger-200);
$text-on-danger-200--subdued: var(--text-on-danger-200--subdued);
$text-on-danger-200--vivid: var(--text-on-danger-200--vivid);
$text-on-danger-200--vivid-subdued--UNSAFE: var(
  --text-on-danger-200--vivid-subdued--UNSAFE
);

$text-on-danger-300: var(--text-on-danger-300);
$text-on-danger-300--subdued--UNSAFE: var(
  --text-on-danger-300--subdued--UNSAFE
);
$text-on-danger-300--vivid: var(--text-on-danger-300--vivid);
$text-on-danger-300--vivid-subdued--UNSAFE: var(
  --text-on-danger-300--vivid-subdued--UNSAFE
);

$text-on-danger-400: var(--text-on-danger-400);
$text-on-danger-400--subdued--UNSAFE: var(
  --text-on-danger-400--subdued--UNSAFE
);
$text-on-danger-400--vivid: var(--text-on-danger-400--vivid);
$text-on-danger-400--vivid-subdued--UNSAFE: var(
  --text-on-danger-400--vivid-subdued--UNSAFE
);

$text-on-danger-500: var(--text-on-danger-500);
$text-on-danger-500--subdued--UNSAFE: var(
  --text-on-danger-500--subdued--UNSAFE
);
$text-on-danger-500--vivid: var(--text-on-danger-500--vivid);
$text-on-danger-500--vivid-subdued--UNSAFE: var(
  --text-on-danger-500--vivid-subdued--UNSAFE
);

$text-on-danger-600: var(--text-on-danger-600);
$text-on-danger-600--subdued--UNSAFE: var(
  --text-on-danger-600--subdued--UNSAFE
);
$text-on-danger-600--vivid: var(--text-on-danger-600--vivid);
$text-on-danger-600--vivid-subdued--UNSAFE: var(
  --text-on-danger-600--vivid-subdued--UNSAFE
);

$text-on-danger-700: var(--text-on-danger-700);
$text-on-danger-700--subdued: var(--text-on-danger-700--subdued);
$text-on-danger-700--vivid: var(--text-on-danger-700--vivid);
$text-on-danger-700--vivid-subdued--UNSAFE: var(
  --text-on-danger-700--vivid-subdued--UNSAFE
);

$text-on-danger-800: var(--text-on-danger-800);
$text-on-danger-800--subdued: var(--text-on-danger-800--subdued);
$text-on-danger-800--vivid: var(--text-on-danger-800--vivid);
$text-on-danger-800--vivid-subdued--UNSAFE: var(
  --text-on-danger-800--vivid-subdued--UNSAFE
);

$text-on-danger-900: var(--text-on-danger-900);
$text-on-danger-900--subdued: var(--text-on-danger-900--subdued);
$text-on-danger-900--vivid: var(--text-on-danger-900--vivid);
$text-on-danger-900--vivid-subdued--UNSAFE: var(
  --text-on-danger-900--vivid-subdued--UNSAFE
);

$text-on-white: var(--text-on-white);
$text-on-white--subdued: var(--text-on-white--subdued);
$text-on-white--primary: var(--text-on-white--primary);
$text-on-white--primary-subdued--UNSAFE: var(
  --text-on-white--primary-subdued--UNSAFE
);
$text-on-white--secondary: var(--text-on-white--secondary);
$text-on-white--secondary-subdued--UNSAFE: var(
  --text-on-white--secondary-subdued--UNSAFE
);
$text-on-white--tertiary: var(--text-on-white--tertiary);
$text-on-white--tertiary-subdued--UNSAFE: var(
  --text-on-white--tertiary-subdued--UNSAFE
);
$text-on-white--success: var(--text-on-white--success);
$text-on-white--success-subdued--UNSAFE: var(
  --text-on-white--success-subdued--UNSAFE
);
$text-on-white--warning: var(--text-on-white--warning);
$text-on-white--warning-subdued--UNSAFE: var(
  --text-on-white--warning-subdued--UNSAFE
);
$text-on-white--danger: var(--text-on-white--danger);
$text-on-white--danger-subdued--UNSAFE: var(
  --text-on-white--danger-subdued--UNSAFE
);

$text-on-grey-050: var(--text-on-grey-050);
$text-on-grey-050--subdued: var(--text-on-grey-050--subdued);
$text-on-grey-050--primary: var(--text-on-grey-050--primary);
$text-on-grey-050--primary-subdued--UNSAFE: var(
  --text-on-grey-050--primary-subdued--UNSAFE
);
$text-on-grey-050--secondary: var(--text-on-grey-050--secondary);
$text-on-grey-050--secondary-subdued--UNSAFE: var(
  --text-on-grey-050--secondary-subdued--UNSAFE
);
$text-on-grey-050--tertiary: var(--text-on-grey-050--tertiary);
$text-on-grey-050--tertiary-subdued--UNSAFE: var(
  --text-on-grey-050--tertiary-subdued--UNSAFE
);
$text-on-grey-050--success: var(--text-on-grey-050--success);
$text-on-grey-050--success-subdued--UNSAFE: var(
  --text-on-grey-050--success-subdued--UNSAFE
);
$text-on-grey-050--warning: var(--text-on-grey-050--warning);
$text-on-grey-050--warning-subdued--UNSAFE: var(
  --text-on-grey-050--warning-subdued--UNSAFE
);
$text-on-grey-050--danger: var(--text-on-grey-050--danger);
$text-on-grey-050--danger-subdued--UNSAFE: var(
  --text-on-grey-050--danger-subdued--UNSAFE
);

$text-on-grey-100: var(--text-on-grey-100);
$text-on-grey-100--subdued: var(--text-on-grey-100--subdued);
$text-on-grey-100--primary: var(--text-on-grey-100--primary);
$text-on-grey-100--primary-subdued--UNSAFE: var(
  --text-on-grey-100--primary-subdued--UNSAFE
);
$text-on-grey-100--secondary: var(--text-on-grey-100--secondary);
$text-on-grey-100--secondary-subdued--UNSAFE: var(
  --text-on-grey-100--secondary-subdued--UNSAFE
);
$text-on-grey-100--tertiary: var(--text-on-grey-100--tertiary);
$text-on-grey-100--tertiary-subdued--UNSAFE: var(
  --text-on-grey-100--tertiary-subdued--UNSAFE
);
$text-on-grey-100--success: var(--text-on-grey-100--success);
$text-on-grey-100--success-subdued--UNSAFE: var(
  --text-on-grey-100--success-subdued--UNSAFE
);
$text-on-grey-100--warning: var(--text-on-grey-100--warning);
$text-on-grey-100--warning-subdued--UNSAFE: var(
  --text-on-grey-100--warning-subdued--UNSAFE
);
$text-on-grey-100--danger: var(--text-on-grey-100--danger);
$text-on-grey-100--danger-subdued--UNSAFE: var(
  --text-on-grey-100--danger-subdued--UNSAFE
);

$text-on-grey-200: var(--text-on-grey-200);
$text-on-grey-200--subdued: var(--text-on-grey-200--subdued);
$text-on-grey-200--primary: var(--text-on-grey-200--primary);
$text-on-grey-200--primary-subdued--UNSAFE: var(
  --text-on-grey-200--primary-subdued--UNSAFE
);
$text-on-grey-200--secondary: var(--text-on-grey-200--secondary);
$text-on-grey-200--secondary-subdued--UNSAFE: var(
  --text-on-grey-200--secondary-subdued--UNSAFE
);
$text-on-grey-200--tertiary: var(--text-on-grey-200--tertiary);
$text-on-grey-200--tertiary-subdued--UNSAFE: var(
  --text-on-grey-200--tertiary-subdued--UNSAFE
);
$text-on-grey-200--success: var(--text-on-grey-200--success);
$text-on-grey-200--success-subdued--UNSAFE: var(
  --text-on-grey-200--success-subdued--UNSAFE
);
$text-on-grey-200--warning: var(--text-on-grey-200--warning);
$text-on-grey-200--warning-subdued--UNSAFE: var(
  --text-on-grey-200--warning-subdued--UNSAFE
);
$text-on-grey-200--danger: var(--text-on-grey-200--danger);
$text-on-grey-200--danger-subdued--UNSAFE: var(
  --text-on-grey-200--danger-subdued--UNSAFE
);

$text-on-grey-300: var(--text-on-grey-300);
$text-on-grey-300--subdued--UNSAFE: var(--text-on-grey-300--subdued--UNSAFE);
$text-on-grey-300--primary: var(--text-on-grey-300--primary);
$text-on-grey-300--primary-subdued--UNSAFE: var(
  --text-on-grey-300--primary-subdued--UNSAFE
);
$text-on-grey-300--secondary: var(--text-on-grey-300--secondary);
$text-on-grey-300--secondary-subdued--UNSAFE: var(
  --text-on-grey-300--secondary-subdued--UNSAFE
);
$text-on-grey-300--tertiary: var(--text-on-grey-300--tertiary);
$text-on-grey-300--tertiary-subdued--UNSAFE: var(
  --text-on-grey-300--tertiary-subdued--UNSAFE
);
$text-on-grey-300--success: var(--text-on-grey-300--success);
$text-on-grey-300--success-subdued--UNSAFE: var(
  --text-on-grey-300--success-subdued--UNSAFE
);
$text-on-grey-300--warning: var(--text-on-grey-300--warning);
$text-on-grey-300--warning-subdued--UNSAFE: var(
  --text-on-grey-300--warning-subdued--UNSAFE
);
$text-on-grey-300--danger: var(--text-on-grey-300--danger);
$text-on-grey-300--danger-subdued--UNSAFE: var(
  --text-on-grey-300--danger-subdued--UNSAFE
);

$text-on-grey-400: var(--text-on-grey-400);
$text-on-grey-400--subdued--UNSAFE: var(--text-on-grey-400--subdued--UNSAFE);
$text-on-grey-400--primary: var(--text-on-grey-400--primary);
$text-on-grey-400--primary-subdued--UNSAFE: var(
  --text-on-grey-400--primary-subdued--UNSAFE
);
$text-on-grey-400--secondary: var(--text-on-grey-400--secondary);
$text-on-grey-400--secondary-subdued--UNSAFE: var(
  --text-on-grey-400--secondary-subdued--UNSAFE
);
$text-on-grey-400--tertiary: var(--text-on-grey-400--tertiary);
$text-on-grey-400--tertiary-subdued--UNSAFE: var(
  --text-on-grey-400--tertiary-subdued--UNSAFE
);
$text-on-grey-400--success: var(--text-on-grey-400--success);
$text-on-grey-400--success-subdued--UNSAFE: var(
  --text-on-grey-400--success-subdued--UNSAFE
);
$text-on-grey-400--warning: var(--text-on-grey-400--warning);
$text-on-grey-400--warning-subdued--UNSAFE: var(
  --text-on-grey-400--warning-subdued--UNSAFE
);
$text-on-grey-400--danger: var(--text-on-grey-400--danger);
$text-on-grey-400--danger-subdued--UNSAFE: var(
  --text-on-grey-400--danger-subdued--UNSAFE
);

$text-on-grey-500: var(--text-on-grey-500);
$text-on-grey-500--subdued--UNSAFE: var(--text-on-grey-500--subdued--UNSAFE);
$text-on-grey-500--primary: var(--text-on-grey-500--primary);
$text-on-grey-500--primary-subdued--UNSAFE: var(
  --text-on-grey-500--primary-subdued--UNSAFE
);
$text-on-grey-500--secondary: var(--text-on-grey-500--secondary);
$text-on-grey-500--secondary-subdued--UNSAFE: var(
  --text-on-grey-500--secondary-subdued--UNSAFE
);
$text-on-grey-500--tertiary: var(--text-on-grey-500--tertiary);
$text-on-grey-500--tertiary-subdued--UNSAFE: var(
  --text-on-grey-500--tertiary-subdued--UNSAFE
);
$text-on-grey-500--success: var(--text-on-grey-500--success);
$text-on-grey-500--success-subdued--UNSAFE: var(
  --text-on-grey-500--success-subdued--UNSAFE
);
$text-on-grey-500--warning: var(--text-on-grey-500--warning);
$text-on-grey-500--warning-subdued--UNSAFE: var(
  --text-on-grey-500--warning-subdued--UNSAFE
);
$text-on-grey-500--danger: var(--text-on-grey-500--danger);
$text-on-grey-500--danger-subdued--UNSAFE: var(
  --text-on-grey-500--danger-subdued--UNSAFE
);

$text-on-grey-600: var(--text-on-grey-600);
$text-on-grey-600--subdued--UNSAFE: var(--text-on-grey-600--subdued--UNSAFE);
$text-on-grey-600--primary: var(--text-on-grey-600--primary);
$text-on-grey-600--primary-subdued--UNSAFE: var(
  --text-on-grey-600--primary-subdued--UNSAFE
);
$text-on-grey-600--secondary: var(--text-on-grey-600--secondary);
$text-on-grey-600--secondary-subdued--UNSAFE: var(
  --text-on-grey-600--secondary-subdued--UNSAFE
);
$text-on-grey-600--tertiary: var(--text-on-grey-600--tertiary);
$text-on-grey-600--tertiary-subdued--UNSAFE: var(
  --text-on-grey-600--tertiary-subdued--UNSAFE
);
$text-on-grey-600--success: var(--text-on-grey-600--success);
$text-on-grey-600--success-subdued--UNSAFE: var(
  --text-on-grey-600--success-subdued--UNSAFE
);
$text-on-grey-600--warning: var(--text-on-grey-600--warning);
$text-on-grey-600--warning-subdued--UNSAFE: var(
  --text-on-grey-600--warning-subdued--UNSAFE
);
$text-on-grey-600--danger: var(--text-on-grey-600--danger);
$text-on-grey-600--danger-subdued--UNSAFE: var(
  --text-on-grey-600--danger-subdued--UNSAFE
);

$text-on-grey-700: var(--text-on-grey-700);
$text-on-grey-700--subdued: var(--text-on-grey-700--subdued);
$text-on-grey-700--primary: var(--text-on-grey-700--primary);
$text-on-grey-700--primary-subdued--UNSAFE: var(
  --text-on-grey-700--primary-subdued--UNSAFE
);
$text-on-grey-700--secondary: var(--text-on-grey-700--secondary);
$text-on-grey-700--secondary-subdued--UNSAFE: var(
  --text-on-grey-700--secondary-subdued--UNSAFE
);
$text-on-grey-700--tertiary: var(--text-on-grey-700--tertiary);
$text-on-grey-700--tertiary-subdued--UNSAFE: var(
  --text-on-grey-700--tertiary-subdued--UNSAFE
);
$text-on-grey-700--success: var(--text-on-grey-700--success);
$text-on-grey-700--success-subdued--UNSAFE: var(
  --text-on-grey-700--success-subdued--UNSAFE
);
$text-on-grey-700--warning: var(--text-on-grey-700--warning);
$text-on-grey-700--warning-subdued--UNSAFE: var(
  --text-on-grey-700--warning-subdued--UNSAFE
);
$text-on-grey-700--danger: var(--text-on-grey-700--danger);
$text-on-grey-700--danger-subdued--UNSAFE: var(
  --text-on-grey-700--danger-subdued--UNSAFE
);

$text-on-grey-800: var(--text-on-grey-800);
$text-on-grey-800--subdued: var(--text-on-grey-800--subdued);
$text-on-grey-800--primary: var(--text-on-grey-800--primary);
$text-on-grey-800--primary-subdued--UNSAFE: var(
  --text-on-grey-800--primary-subdued--UNSAFE
);
$text-on-grey-800--secondary: var(--text-on-grey-800--secondary);
$text-on-grey-800--secondary-subdued--UNSAFE: var(
  --text-on-grey-800--secondary-subdued--UNSAFE
);
$text-on-grey-800--tertiary: var(--text-on-grey-800--tertiary);
$text-on-grey-800--tertiary-subdued--UNSAFE: var(
  --text-on-grey-800--tertiary-subdued--UNSAFE
);
$text-on-grey-800--success: var(--text-on-grey-800--success);
$text-on-grey-800--success-subdued--UNSAFE: var(
  --text-on-grey-800--success-subdued--UNSAFE
);
$text-on-grey-800--warning: var(--text-on-grey-800--warning);
$text-on-grey-800--warning-subdued--UNSAFE: var(
  --text-on-grey-800--warning-subdued--UNSAFE
);
$text-on-grey-800--danger: var(--text-on-grey-800--danger);
$text-on-grey-800--danger-subdued--UNSAFE: var(
  --text-on-grey-800--danger-subdued--UNSAFE
);

$text-on-grey-900: var(--text-on-grey-900);
$text-on-grey-900--subdued: var(--text-on-grey-900--subdued);
$text-on-grey-900--primary: var(--text-on-grey-900--primary);
$text-on-grey-900--primary-subdued--UNSAFE: var(
  --text-on-grey-900--primary-subdued--UNSAFE
);
$text-on-grey-900--secondary: var(--text-on-grey-900--secondary);
$text-on-grey-900--secondary-subdued--UNSAFE: var(
  --text-on-grey-900--secondary-subdued--UNSAFE
);
$text-on-grey-900--tertiary: var(--text-on-grey-900--tertiary);
$text-on-grey-900--tertiary-subdued--UNSAFE: var(
  --text-on-grey-900--tertiary-subdued--UNSAFE
);
$text-on-grey-900--success: var(--text-on-grey-900--success);
$text-on-grey-900--success-subdued--UNSAFE: var(
  --text-on-grey-900--success-subdued--UNSAFE
);
$text-on-grey-900--warning: var(--text-on-grey-900--warning);
$text-on-grey-900--warning-subdued--UNSAFE: var(
  --text-on-grey-900--warning-subdued--UNSAFE
);
$text-on-grey-900--danger: var(--text-on-grey-900--danger);
$text-on-grey-900--danger-subdued--UNSAFE: var(
  --text-on-grey-900--danger-subdued--UNSAFE
);

$color-primary-lighter: var(--color-primary-lighter);
$color-primary: var(--color-primary);
$color-primary-darker: var(--color-primary-darker);
$text-on-primary-lighter: var(--text-on-primary-lighter);
$text-on-primary-lighter--subdued--UNSAFE: var(
  --text-on-primary-lighter--subdued--UNSAFE
);
$text-on-primary-lighter--vivid: var(--text-on-primary-lighter--vivid);
$text-on-primary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-primary-lighter--vivid-subdued--UNSAFE
);
$text-on-primary: var(--text-on-primary);
$text-on-primary--subdued--UNSAFE: var(--text-on-primary--subdued--UNSAFE);
$text-on-primary--vivid: var(--text-on-primary--vivid);
$text-on-primary--vivid-subdued--UNSAFE: var(
  --text-on-primary--vivid-subdued--UNSAFE
);
$text-on-primary-darker: var(--text-on-primary-darker);
$text-on-primary-darker--subdued--UNSAFE: var(
  --text-on-primary-darker--subdued--UNSAFE
);
$text-on-primary-darker--vivid: var(--text-on-primary-darker--vivid);
$text-on-primary-darker--vivid-subdued--UNSAFE: var(
  --text-on-primary-darker--vivid-subdued--UNSAFE
);

$color-secondary-lighter: var(--color-secondary-lighter);
$color-secondary: var(--color-secondary);
$color-secondary-darker: var(--color-secondary-darker);
$text-on-secondary-lighter: var(--text-on-secondary-lighter);
$text-on-secondary-lighter--subdued--UNSAFE: var(
  --text-on-secondary-lighter--subdued--UNSAFE
);
$text-on-secondary-lighter--vivid: var(--text-on-secondary-lighter--vivid);
$text-on-secondary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-secondary-lighter--vivid-subdued--UNSAFE
);
$text-on-secondary: var(--text-on-secondary);
$text-on-secondary--subdued--UNSAFE: var(--text-on-secondary--subdued--UNSAFE);
$text-on-secondary--vivid: var(--text-on-secondary--vivid);
$text-on-secondary--vivid-subdued--UNSAFE: var(
  --text-on-secondary--vivid-subdued--UNSAFE
);
$text-on-secondary-darker: var(--text-on-secondary-darker);
$text-on-secondary-darker--subdued--UNSAFE: var(
  --text-on-secondary-darker--subdued--UNSAFE
);
$text-on-secondary-darker--vivid: var(--text-on-secondary-darker--vivid);
$text-on-secondary-darker--vivid-subdued--UNSAFE: var(
  --text-on-secondary-darker--vivid-subdued--UNSAFE
);

$color-tertiary-lighter: var(--color-tertiary-lighter);
$color-tertiary: var(--color-tertiary);
$color-tertiary-darker: var(--color-tertiary-darker);
$text-on-tertiary-lighter: var(--text-on-tertiary-lighter);
$text-on-tertiary-lighter--subdued--UNSAFE: var(
  --text-on-tertiary-lighter--subdued--UNSAFE
);
$text-on-tertiary-lighter--vivid: var(--text-on-tertiary-lighter--vivid);
$text-on-tertiary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-lighter--vivid-subdued--UNSAFE
);
$text-on-tertiary: var(--text-on-tertiary);
$text-on-tertiary--subdued--UNSAFE: var(--text-on-tertiary--subdued--UNSAFE);
$text-on-tertiary--vivid: var(--text-on-tertiary--vivid);
$text-on-tertiary--vivid-subdued--UNSAFE: var(
  --text-on-tertiary--vivid-subdued--UNSAFE
);
$text-on-tertiary-darker: var(--text-on-tertiary-darker);
$text-on-tertiary-darker--subdued--UNSAFE: var(
  --text-on-tertiary-darker--subdued--UNSAFE
);
$text-on-tertiary-darker--vivid: var(--text-on-tertiary-darker--vivid);
$text-on-tertiary-darker--vivid-subdued--UNSAFE: var(
  --text-on-tertiary-darker--vivid-subdued--UNSAFE
);

$color-success-lighter: var(--color-success-lighter);
$color-success: var(--color-success);
$color-success-darker: var(--color-success-darker);
$text-on-success-lighter: var(--text-on-success-lighter);
$text-on-success-lighter--subdued--UNSAFE: var(
  --text-on-success-lighter--subdued--UNSAFE
);
$text-on-success-lighter--vivid: var(--text-on-success-lighter--vivid);
$text-on-success-lighter--vivid-subdued--UNSAFE: var(
  --text-on-success-lighter--vivid-subdued--UNSAFE
);
$text-on-success: var(--text-on-success);
$text-on-success--subdued--UNSAFE: var(--text-on-success--subdued--UNSAFE);
$text-on-success--vivid: var(--text-on-success--vivid);
$text-on-success--vivid-subdued--UNSAFE: var(
  --text-on-success--vivid-subdued--UNSAFE
);
$text-on-success-darker: var(--text-on-success-darker);
$text-on-success-darker--subdued--UNSAFE: var(
  --text-on-success-darker--subdued--UNSAFE
);
$text-on-success-darker--vivid: var(--text-on-success-darker--vivid);
$text-on-success-darker--vivid-subdued--UNSAFE: var(
  --text-on-success-darker--vivid-subdued--UNSAFE
);

$color-warning-lighter: var(--color-warning-lighter);
$color-warning: var(--color-warning);
$color-warning-darker: var(--color-warning-darker);
$text-on-warning-lighter: var(--text-on-warning-lighter);
$text-on-warning-lighter--subdued--UNSAFE: var(
  --text-on-warning-lighter--subdued--UNSAFE
);
$text-on-warning-lighter--vivid: var(--text-on-warning-lighter--vivid);
$text-on-warning-lighter--vivid-subdued--UNSAFE: var(
  --text-on-warning-lighter--vivid-subdued--UNSAFE
);
$text-on-warning: var(--text-on-warning);
$text-on-warning--subdued--UNSAFE: var(--text-on-warning--subdued--UNSAFE);
$text-on-warning--vivid: var(--text-on-warning--vivid);
$text-on-warning--vivid-subdued--UNSAFE: var(
  --text-on-warning--vivid-subdued--UNSAFE
);
$text-on-warning-darker: var(--text-on-warning-darker);
$text-on-warning-darker--subdued--UNSAFE: var(
  --text-on-warning-darker--subdued--UNSAFE
);
$text-on-warning-darker--vivid: var(--text-on-warning-darker--vivid);
$text-on-warning-darker--vivid-subdued--UNSAFE: var(
  --text-on-warning-darker--vivid-subdued--UNSAFE
);

$color-danger-lighter: var(--color-danger-lighter);
$color-danger: var(--color-danger);
$color-danger-darker: var(--color-danger-darker);
$text-on-danger-lighter: var(--text-on-danger-lighter);
$text-on-danger-lighter--subdued--UNSAFE: var(
  --text-on-danger-lighter--subdued--UNSAFE
);
$text-on-danger-lighter--vivid: var(--text-on-danger-lighter--vivid);
$text-on-danger-lighter--vivid-subdued--UNSAFE: var(
  --text-on-danger-lighter--vivid-subdued--UNSAFE
);
$text-on-danger: var(--text-on-danger);
$text-on-danger--subdued--UNSAFE: var(--text-on-danger--subdued--UNSAFE);
$text-on-danger--vivid: var(--text-on-danger--vivid);
$text-on-danger--vivid-subdued--UNSAFE: var(
  --text-on-danger--vivid-subdued--UNSAFE
);
$text-on-danger-darker: var(--text-on-danger-darker);
$text-on-danger-darker--subdued--UNSAFE: var(
  --text-on-danger-darker--subdued--UNSAFE
);
$text-on-danger-darker--vivid: var(--text-on-danger-darker--vivid);
$text-on-danger-darker--vivid-subdued--UNSAFE: var(
  --text-on-danger-darker--vivid-subdued--UNSAFE
);

$pixels-1: var(--pixels-1);
$pixels-2: var(--pixels-2);
$pixels-3: var(--pixels-3);
$pixels-4: var(--pixels-4);
$pixels-5: var(--pixels-5);
$pixels-6: var(--pixels-6);
$pixels-7: var(--pixels-7);
$pixels-8: var(--pixels-8);

$text-size-xs: var(--text-size-xs);
$text-size-s: var(--text-size-s);
$text-size-m: var(--text-size-m);
$text-size-l: var(--text-size-l);
$text-size-xl: var(--text-size-xl);
$text-size-xxl: var(--text-size-xxl);
$text-size-xxxl: var(--text-size-xxxl);

$border-radius-xs: var(--border-radius-xs);
$border-radius-s: var(--border-radius-s);
$border-radius-m: var(--border-radius-m);
$border-radius-l: var(--border-radius-l);
$border-radius-xl: var(--border-radius-xl);

$text-weight-bold: var(--text-weight-bold);
$text-weight-normal: var(--text-weight-normal);
$text-weight-light: var(--text-weight-light);

$transition-150: var(--transition-150);
$transition-300: var(--transition-300);

$focus-ring: var(--focus-ring);
$focus-ring-inset: var(--focus-ring-inset);

$box-shadow-100: var(--box-shadow-100);
$box-shadow-100-dark: var(--box-shadow-100-dark);
$box-shadow-100-focus: var(--box-shadow-100-focus);
$box-shadow-200: var(--box-shadow-200);
$box-shadow-200-dark: var(--box-shadow-200-dark);
$box-shadow-200-focus: var(--box-shadow-200-focus);
$box-shadow-300: var(--box-shadow-300);
$box-shadow-300-dark: var(--box-shadow-300-dark);
$box-shadow-300-focus: var(--box-shadow-300-focus);
$box-shadow-400: var(--box-shadow-400);
$box-shadow-400-dark: var(--box-shadow-400-dark);
$box-shadow-400-focus: var(--box-shadow-400-focus);
$box-shadow-500: var(--box-shadow-500);
$box-shadow-500-dark: var(--box-shadow-500-dark);
$box-shadow-500-focus: var(--box-shadow-500-focus);

$border-width-thin: var(--border-width-thin);
$border-width-default: var(--border-width-default);
