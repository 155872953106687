@import 'tokens.scss';

.LensSidebar {
  // position: absolute;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  // box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 2;
  // background: #F8F8F8;
  background: #f8f8f8;
  border-radius: 18px;
}
.ProspectLensGridBar {
  max-height: calc(100vh - 100px);
  overflow: auto;
  position: absolute;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 999;
  margin: 1rem 2rem;
  border-radius: 12px;
  // min-width: 400px;
  // max-width: 400px;
  // according to new UI
  min-width: 381px;
  max-width: 381px;
  padding: 34px 30px;

  @media (max-width: 1050px) {
    // margin-left: 15px;
    // max-height: 450px;
  }
}
// .ProspectLensGridBar::-webkit-scrollbar {
//   width: 8px;
//   height: 30px;
// }
// .ProspectLensGridBar::-webkit-scrollbar-track {
//   // background: linear-gradient(90deg, #FCD917 23.4%, #FFAB03 75.9%);
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
//   // margin-top: 120px;
// }
// .ProspectLensGridBar::-webkit-scrollbar-thumb {
//   // background: red;
//   background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
//   border-radius: 10px;
//   // height: 30px;
//   min-height: 40px;
// }
.ProspectLensGridBarCenter {
  position: absolute;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 999;
  margin: 1rem 2rem;
  border-radius: 12px;
  min-width: 400px;
  max-width: 600px;
  height: 90%;
  margin-left: 35%;
}
.run {
  color: green;
}
.close {
  color: red;
}
