.confirm_main {
  position: relative;
  width: 762px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;

  &__heading {
    width: 66%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 30px !important;
    line-height: 41px !important;
    color: #000000 !important;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 63%;
  }
  &__yes_button_modal {
    z-index: 9999;
    padding: 25px 20px;
    font-family: 'Open Sans';
    width: 50% !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */

    text-align: center;

    color: #000000;
  }
  &__no_button_modal {
    z-index: 9999;
    padding: 25px 20px;
    font-family: 'Open Sans';
    font-style: normal;
    width: 50% !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    text-align: center;
    color: #000000;
  }
}
.confirmrightcomb {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.confirmleftcomb {
  position: absolute;
  top: 0px;
  left: 0px;
}
