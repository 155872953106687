.loginPrefix {
  // background-image: url('../../assets/Images/loginBg.png');
  // background-size: 100% 100%;
  // background-color: white;
}
.forget_all_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 2rem 6rem;
  background: #ffffff;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 2;
}
.mainLogin {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  /* height: 100%; */
  min-height: calc(100vh - 123px);
  height: 100%;
  width: 500px;
  padding: 2rem 0;
  z-index: 2;

  @media screen and (max-height: 800px) {
    padding: 5rem 0;
  }
  @media screen and (max-width: 500px) {
    max-width: 85vw;
  }

  @media (max-width: 1050px) {
    // width: 300px;
    .setPasswordInput {
      width: 100% !important;
    }
  }

  &__logo {
    margin-bottom: 1rem;
  }
  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
}
.loginContainer {
  background: white;
  // height: 100vh;
}
.email_has_been_sent {
  max-width: 280px;
  margin: 0 30px;
  display: flex;

  @media (max-width: 699px) {
    margin: 0;
  }
}

.crtAccOr {
  text-align: center;
  margin: 10px 0;
}

.resendMail {
  color: #ffab03;
}
.loginResendMail {
  display: flex;
  justify-content: center;
  // width: 140px;
  text-align: center;
  color: #ffab03;
}
.loginResendMail > p {
  margin-bottom: 3px;
}
.loginResendMail :hover {
  opacity: 0.8;
  cursor: pointer;
}
.onboardingBack {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 140px;
  text-align: center;
  color: #ffab03;
}
.onboardingBack > p {
  margin-bottom: 3px;
}
.onboardingBack :hover {
  opacity: 0.8;
  cursor: pointer;
}
.sendVerification {
  border-radius: 10px;
  padding: 26px 0;
}
.Create_account_discription {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 134% */

  text-align: center;

  /* Light 400 */

  color: #6d6d6d;
}
