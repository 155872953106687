@import 'tokens.scss';

// override default leaflet styles
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.Digital Farming__map .leaflet-container .leaflet-pane .leaflet-popup {
  // display: none;
  //   left: -103px !important;
  //   bottom: 55px !important;
  //   top: -87px !important;

  //   bottom: 87px !important;
  //   left: -71px;
  //   transform: translate3d(353px, 197px, 0px) !important;
}

.Digital Farming__map
  .leaflet-container
  .leaflet-pane
  .leaflet-popup-tip-container {
  display: block !important;
}

.MapCustomPopup {
  &__header {
    // background: $color-primary;
    // padding: 1rem;
    word-break: break-word;
    white-space: normal;
    padding: 0.8rem 0.8rem 0rem 0.8rem;
    white-space: pre-line;
    min-width: 140px;
    font-size: $text-size-m;
    // text-align: center;
  }
  &__buzzStatus {
    // background-color: #f68533;
    text-align: center;
    color: white;
    width: 124px;
    height: 24px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 2px;
  }
  &__HistrogramHeight {
    width: 150px;
    height: 45px;
    background: black;
    transition: height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: rotatex(180deg) translate3d(0, -200px, 0); /* the Y-Value shall be higher than the height on hover*/
    transform-origin: top;
  }
  &__HistrogramHeight:hover {
    height: 180px;
  }
}
