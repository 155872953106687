.loginHeader {
  /* height: 9vh; */
  width: 100%;
  padding-top: 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-block: 9px;
}
.loginHeader_logo {
  padding: 0px 10px;
  // margin-top: auto;
  //   margin-left: 10px;
  height: 32px;
}
.loginHeader_hr {
  margin: 0;
  background-color: #fcd917;
  border: 2px solid #fcd917;
}
