@import 'src/tokens';
@import 'src/mixins';

.HeaderItem {
  color: $text-on-primary;
  text-decoration: none;
  text-align: start;
  border: none;
  background-color: transparent;
  font-size: 15.9px;
  cursor: pointer;
  padding: 20px 1rem;
  font-family: inherit;

  @include focus-ring-inset;
  &:hover {
    background-color: $color-primary-lighter;
    color: $text-on-primary-lighter;
  }
  &__icon {
    margin-right: 0.5rem;
  }
}

.HeaderItem--is-active {
  // background-color: $color-primary-lighter;
  // margin-bottom: 4px black;
  border-bottom: 2px solid #ffab03 !important;
  // margin-bottom: 10px;
  font-weight: 700;
  padding-bottom: 4px;
}
