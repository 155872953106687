.empty_status_container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top:103px;

  &__nested_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &__headings_container {
    margin-top: 38px;
  }

  &__subHeading {
    margin-top: 5px;
    display: inline-block;
  }

  @media (max-width: 699px) {
    img {
      width: 50%;
    }

    &__headings_container {
      .Text {
        font-size: 18px !important;
      }
    }
  }
}
