.notification_container {
  height: 100%;

  .PageHeader__actions {
    @media (max-width: 699px) {
      display: none;
    }
  }

  .TableSelectionWrapper,
  .TableSelectionWrapper__table-wrapper {
    height: inherit;
  }

  .nested_container {
    background: white;
    position: relative;
    min-height: 80%;
    height: 100%;

    ul {
      list-style: none;
      padding: 0;
    }

    .header_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #c6cbcd;
      padding: 0 20px;

      li {
        padding: 8px 0;
        cursor: pointer;
        padding-bottom: 27px;
      }
      .header_icons {
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
          // position: fixed;
        }
      }
      .header_titles::-webkit-scrollbar {
        display: none !important; /* Safari and Chrome */
      }
      .header_titles::-webkit-scrollbar {
        display: none !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .header_titles {
        text-transform: capitalize;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          margin-left: 15px;
        }

        @media (max-width: 699px) {
          flex-wrap: nowrap;
          // overflow: hidden
          overflow-x: scroll;

          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */

          // ::-webkit-scrollbar {
          //   display: none !important; /* Safari and Chrome */
          // }
          // ::-webkit-scrollbar {
          //   display: none !important;
          //   -ms-overflow-style: none;
          //   scrollbar-width: none;
          // }
          // flex-wrap: wrap;
        }
      }

      .count {
        padding: 10px 15px;
        border-radius: 25%;
        margin-left: 0.5rem;
        background: #c6cbcd;
        font-weight: bold;
        font-size: 16px;
        color: white;
      }

      .mark_all_as_read {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        border-bottom: 1px solid black;
        padding-bottom: 0;
      }
    }

    .body_container {
      overflow: auto;

      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        height: 65px;
        border-bottom: 1px solid #c6cbcd;
        align-items: center;
        padding: 0 15px;
        min-width: 560px;
        background-color: white;
        cursor: pointer;
      }

      .notification_title {
        cursor: pointer;
        display: flex;
        align-items: center;

        .Text {
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media only screen and (min-width: 768px) {
            max-width: 450px !important;
          }

          @media only screen and (min-width: 992px) {
            max-width: 680px !important;
          }

          @media only screen and (min-width: 1200px) {
            max-width: 850px !important;
          }

          @media only screen and (min-width: 1600px) {
            max-width: 1200px !important;
          }

          @media only screen and (min-width: 1800px) {
            max-width: 1600px !important;
          }

          @media (max-width: 699px) {
            width: 380px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .pagination_container {
        margin: 0;
        padding-top: 20px;
      }
    }

    .black_bg_color {
      background-color: black !important;
    }

    .border_bottom {
      border-bottom: 3px solid black;
    }

    .unread {
      background: rgba(255, 171, 3, 0.1) !important;
    }

    .empty_status_container__headings_container {
      .Text {
        font-family: sans-serif, 'Helvetica Neue', 'Lucida Grande', Arial;
        font-size: 32px !important;
      }
    }

    // .empty_status_container__nested_container {
    //   img {
    //     width: 20%;
    //   }
    // }
  }

  .pagiate_container {
    height: 95px;
    margin-right: 1.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
