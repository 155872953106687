.contactDetail {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: black;
      letter-spacing: 0.5px;
    }
  }

  &__headIcon {
    display: flex;
    align-items: center;
    // gap: 1rem;
    // padding-left: 5px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px;
    margin-top: 1rem;
    background-color: white;
    padding: 3rem 2rem;
    padding-bottom: 0;
    grid-gap: 1.8rem;
    gap: 1.8rem;
  }
  &__contentBox {
    display: flex;
    align-items: center;
    // flex-grow: 1;
    width: 31.5%;
    // grid-gap: 1rem;
    height: 100px;
    display: flex;
  }

  &__button {
    padding: 0.5rem;
    background-color: rgba(252, 217, 23, 1);
    color: black;
    border: none;
  }
}
.edit_information_title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Light 100 */

  color: #2b1f0a;
}
