.ImageWithLink {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  gap: 1.4rem;
}

.digital_banner_title {
  margin-top: 1rem;
  margin-bottom:5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height, or 121% */

  /* Light 400 */

  color: #6d6d6d !important;
}
