@import 'src/tokens';
@import '../input-variables';

.RadioButton {
  display: flex;
  &__wrapper-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0.375rem;
    padding-right: 0.75rem;
    border-radius: $border-radius-s;
  }
  &__input {
    all: unset;
    appearance: none;
    border-radius: 100rem;
    display: block;
    background-color: $color-white;
    height: var(--pixels-8);
    width: var(--pixels-8);
    margin: var(--pixels-6);
    margin-right: 0.75rem;
    box-shadow: 0 0 0 var(--pixels-4) var(--color-white),
      0 0 0 var(--pixels-6) var(--text-on-white--primary);
    &:checked {
      background-color: var(--text-on-white--tertiary);
    }
  }
}

.RadioButton--s-size {
  font-size: $text-size-xs;
  font-weight: $input-font-weight-s;
  letter-spacing: 0.015rem;
  height: $input-height-s;
}

.RadioButton--s-size .RadioButton__input {
  width: 0.25rem;
  height: 0.25rem;
  margin-right: 0.5rem;
}

.RadioButton--s-size .RadioButton__wrapper-label {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}

.RadioButton--m-size {
  font-size: $text-size-m;
  font-weight: $input-font-weight-m;
  letter-spacing: 0.015rem;
  height: $input-height-m;
}

.RadioButton--l-size {
  font-size: $text-size-l;
  font-weight: $input-font-weight-l;
  letter-spacing: 0.015rem;
  height: $input-height-l;
}

.RadioButton--l-size .RadioButton__input {
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 1rem;
}

.RadioButton--l-size .RadioButton__wrapper-label {
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.RadioButton--xl-size {
  font-size: $text-size-xl;
  font-weight: $input-font-weight-xl;
  letter-spacing: 0.015rem;
  height: $input-height-xl;
}

.RadioButton--xl-size .RadioButton__input {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 1.25rem;
}

.RadioButton--xl-size .RadioButton__wrapper-label {
  padding-left: 0.625rem;
  padding-right: 1.25rem;
}

.RadioButton:hover .RadioButton {
  &__wrapper-label {
    background-color: $color-primary-050;
    box-shadow: $box-shadow-200;
  }
}

.RadioButton:focus-within .RadioButton,
.RadioButton:active .RadioButton {
  &__wrapper-label {
    box-shadow: $box-shadow-300-focus;
  }
}
