@import 'src/tokens';
@import '../../@eltoro-ui/components/input-variables.scss';
.password_setup_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 4rem 5rem;
  background: #ffffff;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.setPasswordError {
  color: red;
}
.newpassword_model {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 134% */

  text-align: center;

  /* Light 400 */

  color: #6d6d6d;
  margin-top: -10px;
}
.passwordReset {
  &__login {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  &__app {
    display: none;

    @media screen and (max-width: 500px) {
      display: block;
    }
  }
}
