.bottomAges {
  position: absolute;
  width: 25px;
  height: 23px;
  left: 139px;
  transform: rotate(45deg);
  border-radius: 0;
  background: #ffab03;
  top: 134px;
  z-index: -1;
}

.TableSelectionWrapper {
  &__selected-row {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #ffffff !important;
  }
  &__selected-row-delete {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #ffffff !important;
  }
}
