.pagination_for_realtor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 10px;
  margin: 0 auto;

  svg {
    color: #f68533;
    cursor: pointer;
  }

  ._text {
    font-size: 10px !important;
  }

  ._button {
    width: 0;
    height: 0;
    border: none;
    font-size: 10px;
    display: flex;
    justify-content: center;
  }
}
