@import 'src/tokens';
@import 'src/mixins';

.BuzzScoreFilters {
  // display: flex;
  // padding-top: 2rem;
  // position: relative;
  &__Header {
    // position: absolute;
    // top: 0;
    // left: 12px;
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 22px;
      margin-bottom: 12px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #000000;
    }
  }

  &__filters {
    // display: flex;
    justify-content: space-between;

    .Checkbox__wrapper-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding: 0;
      color: #000000;

      .Checkbox__input-wrapper {
        margin-right: 14px;
      }
    }
  }
  &__labels {
    // display: flex;
    justify-content: space-between;
    margin-top: -0.7rem;
  }
  &__labels > p {
    font-size: 16px;
  }
  &__filter {
    margin-top: 0.5rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__button {
    @include focus-ring;
    outline: none;
    height: 1.5rem;
    width: 4.5rem;
    border-radius: 10px;
    cursor: pointer;
    border-width: $pixels-4;
    border-style: solid;
    text-decoration: none;
    background-color: $color-white;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__check {
    opacity: 0;
  }
}

.BuzzScoreFilters__filter--is-active {
  .BuzzScoreFilters__check {
    opacity: 1;
  }
}
