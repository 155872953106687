.ProgressStepperBar {
  width: 100%;
  &__stepbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    border: none;
    width: 100%;
    background-color: white;
  }
  &__stepbutton_text {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    border: none;
    width: 100%;
    background-color: white;
  }
  &__steps {
    display: flex;
    align-items: center;

    @media (max-width: 699px) {
      justify-content: center;
    }
  }
  &__line {
    height: 0.3px;
    background-color: #c6cbcd;
    width: 55px;
    margin: 0px;
    z-index: 1;
  }
  &__step {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #eeeff0;
    color: #6d797c;
    border: 1px solid #6d797c;
    z-index: 2;
  }
}

.ProgressStepperBar__step--is-filled {
  border: 2px solid #33a0c4;
  background-color: transparent;
}

// .ProgressStepperBar__line--is-filled {
//   background-color: #fcd917;
// }

.progressStepper {
  border-radius: 50%;
  border: 2px solid;
  width: 25px;
  height: 25px;
  text-align: center;
}

.progressTag {
  position: absolute;
  margin-left: -10px;
  font-size: 15px;
  color: #6d797c;

  @media (max-width: 699px) {
    font-size: 12px;
    margin-left: -11px;
  }
}
