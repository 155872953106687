@import 'tokens.scss';

.contact-seen {
  width: 100%;
  left: 24px;
  top: 192px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background: white;
  padding: 0 4rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
  border-radius: 27px;
  height: 249.98px;
  @media screen and (max-width:1750px) {
    padding: 0 3rem;
  }
  @media screen and (max-width:1550px) {
    padding: 0 2rem;
  }
  @media screen and (max-width:1350) {
    padding: 0 1rem;
  }
  &__icon > img{
    height: 150px;
    @media screen and (max-width:1750px) {
height: 135px;    }
    @media screen and (max-width:1550px) {
height: 120px;    }
    @media screen and (max-width:1350) {
height: 105px;    }
  }
  &__columns_per_line {
    display: grid;
    // grid-template-columns: 1fr;
    // grid-gap: 1rem;
    // gap: 1rem;
    padding-top: 2.2rem;
    padding-left: 1rem;
  }

  &__button {
    width: 186px !important;
    height: 44px !important;
    top: 655px;
    font-size: 15px;
    font-family: 'Open Sans';

    color: #6d6d6d;
    background: #d1d1d1 !important;
    border-radius: 13px !important;
    border: none !important;
    @media screen and (max-width:1750px) {
      height: 40px !important;
    width: 170px;    }
          @media screen and (max-width:1550px) {
            height: 36 !important;
            width: 155px; 
                      }        
                          @media screen and (max-width:1350) {
                            height: 32px !important;
                            width: 140px;         }

    &:hover {
      color: white;
    }
  }
  &__text-content {
    margin-top: -0.6rem;
    margin-bottom: 0.5rem;
    text-align: left;
    @media screen and (min-width:1750px) {
      margin-top:1.5rem
       }

    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 70px !important;
      line-height: 18px;
      display: flex;
      color: #aab2b5 !important;
      @media screen and (max-width:1750px) {
        height: 60px !important; 
        }
            @media screen and (max-width:1550px) {
        height: 52px !important;    }
            @media screen and (max-width:1350) {
        height: 46px !important;    }

    }

    &__sub_title {
      font-size: 16px;
      line-height: 18px;
      font-family: 'Open Sans';
      color: #989898 !important;

      @media screen and (max-width: 699px) {
        font-size: 12px !important;
      }
    }
  }
}
