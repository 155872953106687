@import 'tokens.scss';
@import 'mixins.scss';

.ad_to_all_ListingLabel {
  border-radius: 1.25rem;
  background-color: transparent;
  border: none;
  padding: $pixels-2;
  margin: 0 $pixels-2;
  display: block;
  text-align: start;
  outline: none;

  @include focus-ring;
  cursor: pointer;
  &__icon-circle {
    $size: 1.25rem;
    height: $size;
    width: $size;
    color: $text-on-grey-100;
    border-radius: 100rem;
    display: grid;
    place-items: center;
    font-size: $text-size-xs;
    background-color: $color-grey-100;
  }
  &__buzz-marker {
    font-size: $text-size-l;
    margin-right: 0.25rem;
  }
  &__labels {
    width: 250px;
    display: flex;
    align-items: center;
  }
  &__label {
    color: $text-on-white;
    font-weight: $text-weight-bold;
    font-size: $text-size-xs;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }
  &__sub-label {
    color: $text-on-white--subdued;
    font-size: $text-size-xs;
    font-weight: $text-weight-normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
  }
  &:focus-visible {
    border-color: transparent;
  }
  &:hover {
    background-color: $color-grey-100;
    .ListingLabel {
      &__icon-circle {
        background-color: $color-grey-200;
      }
    }
  }
  &--selected {
    .ListingLabel {
      &__icon-circle {
        background-color: $text-on-tertiary-200--subdued;
        color: white;
        font-weight: bold;
      }
    }
    &:hover {
      .ListingLabel {
        &__icon-circle {
          background-color: $text-on-tertiary-200--subdued;
        }
      }
    }
  }
}
