.contacts_sync {
  @media (max-width: 699px) {
    br {
      display: none;
    }

    .OnboardingModal__welcome {
      padding: 1rem;
    }

    button,
    .Button__text {
      font-size: 14px !important;
    }
  }
}

.confirm_btn_sync {
  color: #000000;
  border: 2px solid #808080;
  // margin-top: 20px;
  font-weight: bold;
  // width: 284px;
  background: white;
  padding: 13px;
  margin-bottom: 0.5rem !important;
  font-size: large;
  height: 42px;
  padding: 5px 12px;
}

.confirm_btn_upload {
  font-size: 16px;
}
.center-btns {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: dot 1.3s infinite;
  animation-delay: 0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
