@import 'tokens.scss';

.AreaSearchLens {
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    margin: 1rem 2rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
  &__search_title {
    color: #000000 !important;
    font-weight: bold;
    font-size: 16px !important;
    line-height: 22px;
    margin-top: 5px;
    display: inline-block;
    margin-bottom: 6px;
  }

  .realtor_container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 401;
    border-radius: 14px;
    margin-top: 15px;
    margin-right: 25px;
    max-height: 580px;
    overflow: auto;

    @media (max-height: 800px) {
      max-height: 430px;
    }

    @media (max-width: 1050px) {
      right: -10px;
      margin-right: 0;
      margin-top: 0;
    }
  }

  .buzzScoreImg_container {
    position: absolute;
    right: 40px;
    z-index: 401;
    bottom: 0px;
  }
}

.AdvertiesToAllInMarket {
  .LensSidebarHeader__sub-heading {
    margin: 0 2rem -5px;
  }

  // .results_prospects {
  //   margin-left: 38%;
  // }
  @media (min-width: 1050px) {
    .buzzScoreImg_container {
      right: 65px;
      bottom: 30px;
    }
  }

  @media (max-width: 1050px) {
    .realtor_container {
      max-height: 340px;
      margin-top: 8px;
      right: 10px;
    }

    .buzzScoreImg_container {
      right: -19px;
      bottom: 141px;

      img {
        width: 305px;
      }
    }
  }
}
