.ContactInformation {
  &__title {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    > p {
      margin: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 124.68%;
      color: #515a5c;
    }
    > h4 {
      margin: 6px 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 124.68%;
      color: #000000;
    }
  }

  &__icon {
    color: #ffab03;
  }
}
