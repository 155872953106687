@import 'src/tokens';
@font-face {
  font-family: Helvetica;
  src: url('../../../assets/HelveticaNeue.ttf');
}
.PageNav {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  // border: 1px solid #aab2b5;
  border-radius: 7px;

  @media (min-width: 700px) and (max-width: 1050px) {
    display: flex;
    flex-direction: row;
  }

  &__nav-link {
    padding: 0.5rem 1rem;
    padding-right: 2rem;
    // border-bottom: 1px solid #aab2b5;

    @media (min-width: 700px) and (max-width: 1050px) {
      border-bottom: none;
    }

    &:link,
    &:visited {
      color: #000000;
      text-decoration: none;
      font-family: 'Open Sans';
      // font-weight: $text-weight-bold;
      font-weight: 400;
    }
    &:hover {
      background-color: $color-grey-100;
    }
    &--active {
      &:link,
      &:visited {
        background-color: #F6B433;
        color: $text-on-primary-500--vivid;
      }
    }

    @media (min-width: 700px) and (max-width: 1050px) {
      flex-grow: 1;
    }
  }
  &__nav-link:last-child {
    border-bottom: none;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    @media (min-width: 700px) and (max-width: 1050px) {
      border-radius: 0 7px 7px 0;
      border-left: 1px solid #aab2b5;
    }
  }
  &__nav-link:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    @media (min-width: 700px) and (max-width: 1050px) {
      border-radius: 7px 0 0 7px;
      border-right: 1px solid #aab2b5;
    }
  }
}
