@import 'src/tokens';

.PageNotFound {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $color-white;
  &__graphic {
    color: $color-secondary-300;
    position: relative;
  }
  &__question {
    position: absolute;
    padding-top: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 3rem;
  }
  &__button {
    margin-top: 1rem;
  }
}
