.MaxHeightContainer {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    position: relative;
    z-index: 1;
  }
  &__content {
    // overflow: auto;
    flex-grow: 1;
    position: relative;
    z-index: 0;
    border-radius: 20px;
  }
  &__content::-webkit-scrollbar {
    width: 15px;
    height: 60px;
  }
  &__content::-webkit-scrollbar-track {
    // background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    // margin-top: 120px;
  }
  &__content::-webkit-scrollbar-thumb {
    // background: red;
    background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
    border-radius: 10px;
    // height: 30px;
    min-height: 120px;
  }
  // &__content::-webkit-scrollbar-button {
  //   height: 40px;
  // }

  &--full-height {
    height: 100%;
  }
}
