.RealEstateInfoForm {
  display: flex;
  padding: 4rem 3rem;
  position: relative;

  @media (max-width: 1050px) {
    padding: 3rem 1rem 4rem;
    flex-direction: column;
  }

  @media (max-width: 699px) {
    padding: 2rem 0rem 4rem;
    max-width: 315px;
  }

  &__header {
    width: 298px;

    .heading {
      font-size: 20px !important;
      font-weight: bold;
      display: block;
      margin-bottom: 2rem;

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 3.5rem 1rem;
    border-top: 1px solid #c6cbcd;
    border-bottom: 1px solid #c6cbcd;

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;

      @media (max-width: 699px) {
        wi0th: 100%;
      }
    }
  }
  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }
  &__progress {
    margin: 1rem 0;
  }
  &__inputs {
    border-top: 1px solid #c6cbcd;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-left: 1px solid #c6cbcd;
    padding: 1rem;
    border-bottom: 1px solid #c6cbcd;

    @media (max-width: 1050px) {
      width: 100%;
      border-left: 0;
      border-top: 0;

      @media (max-width: 699px) {
        wi0th: 100%;
      }
    }
  }

  &__footer {
    position: absolute;
    right: 20px;
    left: 20px;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1050px) {
      bottom: 10px;
    }

    @media (max-width: 699px) {
      font-size: 12px;
    }

    .loginResendMail > p {
      padding-bottom: 5px;
      border-bottom: 2px solid #44c2e6;
    }
  }

  &__arrow_icon {
    color: #44c2e6;
    margin-right: 10px;
    margin-top: 18px;

    @media (max-width: 699px) {
      margin-top: 15px;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    gap: 1rem;

    .Text {
      @media (max-width: 699px) {
        font-size: 12px !important;
      }
    }
  }
}
