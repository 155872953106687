@import 'src/tokens';

.CampaignStatusBadge {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  min-width: 12ch;
  font-weight: $text-weight-bold;
  color: $color-grey-600;
  // border: 2px solid black;
  &__text-container {
    flex-grow: 1;
    text-align: center;
    font-size: $text-size-s;
  }
  &__indicator {
    background: $color-grey-050;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}
.CampaignBadgeSuccess {
  background: linear-gradient(94deg, #2ad90e 0%, #44b431 100%);
  color: #ffffff;
}
// .CampaignBadge {
//   background: #fff9ec;
//   border: 1px solid #ffab03;
//   width: 118px;
//   height: 40px;
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400 !important;
//   font-size: 16px !important;
//   line-height: 22px !important;

//   color: #ffab03 !important;
// }
.CampaignBadge_inReview {
  background: #fff9ec;
  display: flex;
  border-radius: 5px;
  padding: 0.9rem 9px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #ffab03;
  width: 118px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  margin: -19px 0;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  color: #ffab03 !important;
}
.CampaignBadge_draft {
  background: #fafafa;
  display: flex;
  border-radius: 5px;
  margin: -10px 0;
  padding: 0.9rem;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #aab2b5;
  width: 118px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  color: #6d797c !important;

}
.CampaignBadge_active {
  background: #f4fff2;
  border: 1px solid #2ad90e;
  display: flex;
  border-radius: 5px;
  padding: 0.9rem;
  align-items: center;
  justify-content: space-around;
  width: 118px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  color: #2ad90e !important;
  margin: 0 auto;
}
