.container {
  width: 100%;
}
.did-floating-labels-contents {
  position: relative;
}
.did-floating-labels {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 17px;
  color: #6d6d6d;
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 23px;
  width: auto;
  padding: 0;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.did-floating-inputs,
.did-floating-selects {
  padding: 0px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #6d6d6d;
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 54px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    ~ .did-floating-labels {
      top: -8px;
      font-size: 14px;
      margin-right: 18px;
      width: auto !important;
    }
  }
}
.did-floating-selects:hover {
  border: 1px solid #ffab03;
}

.did-floating-inputs-error {
  padding: 0px 0px 0px 36px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  width: 100%;
  border: 1px solid #bd0000 !important;
  border-radius: 3px;
  color: #bd0000;
  height: 45px;
  box-sizing: border-box;
}
select.did-floating-selects {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.did-floating-selects::-ms-expand {
  display: none;
}

.did-floating-inputs:not(:placeholder-shown) ~ .did-floating-labels {
  top: -8px;
  font-size: 13px;
  width: auto !important;
}
.did-floating-selects:not([value='']):valid ~ .did-floating-labels {
  top: -8px;
  font-size: 13px !important;
}
.did-floating-selects[value='']:focus ~ .did-floating-labels {
  top: 11px;
  font-size: 13px;
}
.did-floating-selects:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input {
  .input_icon_start,
  .input_icon_end {
    color: #9d3b3b;
  }
  .did-floating-inputs,
  .did-floating-selects {
    border: 1px solid #9d3b3b;
    color: #9d3b3b;
  }
  .did-floating-labels {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 121% */

    color: #bd0000;
  }
  .did-floating-selects:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
  }
}

.input-group {
  display: flex;
  .did-floating-inputs {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
}
.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.input_icon_start {
  position: absolute;
  top: 0.7rem;
  left: 14px;
  z-index: 999;
}
.input_icon_end {
  position: absolute;
  top: 0.7rem;
  right: 12px;
  z-index: 999;
}
.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}
.form-label-group input:not(:placeholder-shown) ~ label,
  .form-label-group input:-webkit-autofill ~ label /* <<<< Add these */ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

// input {
//   -webkit-background-clip: text !important;
// }
