@import 'tokens.scss';
@import 'mixins.scss';

.SummaryEditButton {
  border-radius: $border-radius-s;
  border: $border-width-default solid transparent;
  background: transparent;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;

  /* Light 100 */

  color: #2b1f0a;
  outline: none;
  cursor: pointer;

  @include focus-ring;
  // &:hover {
  //   color: $text-on-grey-300--tertiary;
  //   border-color: $text-on-grey-100--tertiary;
  // }
}
