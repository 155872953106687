.addModalFields {
  margin-bottom: 18px;
}
.mylisting_tableContent {
  padding:0 1rem;
  margin: 1rem;
  background: white;
  border-radius: 20px;
}
.MyListings_counts {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #6d6d6d;
}
.listView {
  display: flex;
  &__notFound {
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #bd0000;
    &__icon {
      margin-right: 10px;
    }
  }
  &__foundIcon {
    margin-right: 10px;
    // margin-bottom: -2px;
    margin-top: 4px;
  }
}
.MyContacts {
  &__pageHeader {
    padding: 0.7rem !important;
  }
  button {
    @media screen and (max-width: 699px) {
      max-width: 130px !important;
      font-size: 13px !important;
      padding-left: 6px;
    }
  }
}
.my-contacts-header-title {
  margin-bottom: 4px !important;
  // padding-top: 6px;
}
.My-Contacts__header {
  padding: 0.9rem !important;
}
.my-contacts-header-subtitle {
  color: #bd0000 !important;
}
.my-contacts-header-subtitle-op {
  padding-top: 3px;
  margin-left: 51px;
}

.sync-contacts-button {
  margin-left: 20px !important;
}
.contact_delete {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #ffffff !important;
}

.icon-header-contacts-sync {
  margin-top: 0px !important;
}

.contacts-btn {
  background-color: transparent !important;
}
.contacts-btn:hover {
  background-color: transparent !important;
}

.sync-table {
  height: calc(100vh - 139px) !important;
}

@media (max-width: 768px) {
  .sync-table {
    height: calc(100vh - 130px) !important;
  }
}
.syncontact_button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #2b1f0a;
  background: #d1d1d1;
  border: 1px solid #c7c9cb;
  border-radius: 10px;
  padding: 10px 20px;
}
