@import 'src/tokens';

@font-face {
  font-family: Helvetica;
  src: url('../../../assets/HelveticaNeue.ttf');
}
.FormItem {
  &__label {
    color: #000000;
    // font-weight: bold;
    margin-bottom: 0.25rem;
    font-family: Helvetica;
    // font-size: 14px;
  }
  &__error-message {
    color: $color-danger;
    margin: 0;
    margin-top: 0.25rem;
    span {
      margin-left: 0.25rem;
      font-size: $text-size-xs;
    }
  }
  &__msg-wrapper {
    height: 1.5rem;
  }
}
