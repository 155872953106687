@import 'src/tokens';

.BlockSelection {
  &__header,
  &__body {
    padding: 1rem;
  }
  &__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.BlockSelection__selection-button {
  border: none;
  outline: none;
  background: transparent;
  display: block;
  text-align: left;
  cursor: pointer;
  &__canvas {
    border: 1px solid grey;
    &:hover {
      box-shadow: $box-shadow-400;
    }
  }
  &--is-disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    filter: saturate(0);
  }
}
