.notificationContainer {
  &__container {
    height: auto !important;
    width: 100%;
    border-radius: 27px;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
    background-color: #fff;
  }
  &__unread{
    background: rgba(255, 171, 3, 0.1);
  }
  &__listBox {
    width: 100%;

    .empty_status_container {
      top: 0;

      .Text {
        font-size: 18px !important;
      }
      img {
        width: 150px;
      }
    }
  }
  &__list {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    grid-template-columns: 1fr 7fr auto;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    margin: 1rem 0;
    padding: 0.3rem 0.5rem;
    border-radius: 12px;
  }
  &__listTitle {
    display: flex;
    flex-direction: column;
  }
  &__listTitle {
    > h4 {
      letter-spacing: 1px;
      margin: 0.1rem 0;
      color: #2b1f0a;
      font-size: 16px;
      font-weight: 800;
    }
    > p {
      letter-spacing: 0.3px;
      margin: 0.1rem 0;
      font-weight: 300;
      font-size: 13px;
      color: #000000;
      // max-width: 120px;
    }
  }
}
