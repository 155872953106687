.realtor {
  width: 294px;
  height: 200.67px;
  border-radius: 14px;
  padding: 1rem 0.5rem;
  margin: 10px 0;
  background: white;

  &__header_section {
    padding-left: 7px;
    height: 25px;
  }

  &__title {
    display: block;
    font-size: 14px !important;
    line-height: 8px;
  }

  &__sub_title {
    font-size: 12px !important;
    color: #aab2b5 !important;
    // line-height: 0;
  }

  .TableWrapper {
    overflow: hidden !important;
    padding: 0 !important;
  }

  .TableHeader__cell {
    padding: 5px;
  }

  .TableHeader__label-container {
    font-size: 11px;
    letter-spacing: 0.5px;

    img {
      margin-left: 5px;
      width: 10px;
    }
  }

  .TableRow {
    border: 0 !important;
  }

  .TableCell {
    font-size: 11px !important;
    letter-spacing: 0.5px;
    padding: 0;

    &:nth-child(2) {
      b {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 135px;
      }
    }
  }

  .svg-inline--fa {
    margin-left: 5px !important;
  }

  .table_cell_content {
    // width: 16px;
    // height: 16px;
    // border-radius: 50%;
    // background: #44c2e6;
    // padding-top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffab03;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
