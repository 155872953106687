@import 'tokens.scss';

.UploadVideoModal {
  padding: 1.6rem;
  display: grid;
  gap: 1.1rem;
  position: relative;
  &__footer {
    display: flex;
    justify-content: space-around !important;
    padding: 0 40px;
    width: 100% !important;
    align-items: center;
  }
  &__cancel-button {
    margin-right: 1rem;
  }
}
.upload_your_video {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  margin: -50px 0;
}
.upload_video_disc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #515a5c;
}
