@import 'src/tokens';
@font-face {
  font-family: Helvetica;
  src: url('../../assets//HelveticaNeue.ttf');
}
.setPasswordError {
  position: relative;
  display: flex;
  margin-left: 0px;
}
.setPasswordErrorMsg {
  margin: 0;
  //   margin-left: 15px;
  font-family: Helvetica;
  margin-left: 0.25rem;
  font-size: $text-size-xs;
}
