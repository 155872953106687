@import 'tokens.scss';

.CampaignSetup {
  height: 100%;
  &__main_table_camp {
    border-collapse: none !important;
    width: 100% !important;
  }
  &__header {
    height: 84px;
    background: var(--color-white);
    border-radius: 22px !important;
  }
  &__sendAdvertise {
    padding: 4px 2rem !important;
    // width: 252px;
    // height: 54px;
  }
  &__boxButtonSizes {
    margin: 1rem auto;
    font-weight: bold;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2b1f0a !important;
    padding: 0;
    padding-inline: 12px;
    width: 254px !important;
    height: 54px;
    @media (max-width: 1560px) {
      width: 150px !important;
      font-size: 10px !important;
      height: 39px;
    }
  }
  &__boxButtonSize {
    margin: 1rem auto;
    font-weight: bold;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2b1f0a !important;
    padding: 0;
    padding-inline: 12px;
    width: 254px !important;
    height: 54px;
    @media (max-width: 1560px) {
      width: 150px !important;
      font-size: 10px !important;
      height: 40px;
    }
  }
  &__subheading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #6d6d6d !important;
  }
  &__titleSpace {
    @media (max-width: 699px) {
      padding-top: 2rem;
    }
  }
  &__title {
    @media (max-width: 699px) {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px !important;
    }
  }
  &__campaign_use_total_prospects {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #dc9300 !important;
    border: 1px solid #dc9300 !important;
    background: rgba(255, 171, 3, 0.07) !important;
    border-radius: 10px !important;
  }
  &__backbutton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 21.5205px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Error 400 */
    background: rgba(201, 34, 45, 0.08);
    border-radius: 10px;
    color: #bd0000 !important;
    border: 1px solid #bd0000;
    // :hover {
    //   background-color: green !important;
    // }
  }

  &__modalIcon {
    position: absolute;
    top: 10px;
    right: 30px;
  }
  &__next-button {
    margin-left: 2rem;

    @media (max-width: 699px) {
      margin-left: 0;
    }
  }

  .CampaignCreationFooter {
    @media (max-width: 699px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }
  &__targeted_audience {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Light 400 */

    color: #6d6d6d !important;
  }

  &__bottom_details {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 4rem;

    @media (max-width: 699px) {
      margin-left: 0;
    }
  }

  &__progress {
    margin-left: auto;
    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__modalSpace {
    // width: 400px !important;
    width: 407px;
    height: 300px;
    margin: 0 2rem 0.3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 699px) {
      width: 300px;
    }

    @media (min-width: 1920px) {
      margin: 0 auto;
    }
  }
  &__heading_container {
    text-align: center;
    margin-bottom: 9px;

    @media (max-width: 699px) {
      margin-bottom: 9px;
      margin-top: 0.5rem;
    }

    .Text {
      font-size: 35px !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 40px !important;
      line-height: 54px;
      text-align: center;
      @media (max-width: 699px) {
        font-size: 22px !important;
      }
    }
  }

  &__name {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0 0 1rem 0;
  }
  &__input-label {
    margin: 1rem 0 0.25rem 0;
  }
  &__name-input {
    max-width: 40rem;
  }
  &__audiences {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: var(--border-radius-xl);

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 33px !important;

      /* Light 100 */

      color: #2b1f0a;
      @media (max-width: 699px) {
        font-size: 17px;
      }
    }
  }
  &__audience-list {
    margin-top: 9px;
  }
  &__empty {
    padding: 0 0 1rem;
    background-color: $color-grey-100;
    display: grid;
    gap: 0.75rem;
    justify-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: 1;
    background-color: white;
    @media screen and (max-width: 699px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__box {
    width: 33.33%;
    // border: 1.5px solid #aab2b5;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    padding: 1.5rem 1rem;
    // box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    border: 1.5px solid #f0f0f0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width: 699px) {
      width: 100%;
      border: 3.5px solid #aab2b5;
      border-radius: 10px;
    }

    &__icon_container {
      margin: 1rem auto;

      @media (max-width: 699px) {
        margin: 1rem auto 0;

        .__icon {
          width: 45px;
        }
      }
    }
  }

  &__Choose_your_Creative {
    width: 100%;
    // min-width: 320px;
    height: 247px;
    margin-top: 10px;
  }

  &__center_prospect_container {
    @media (max-width: 699px) {
      display: none;
    }
  }

  &__boxText {
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 699px) {
      height: 55px;
    }
  }
  &__boxtext_size {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6d6d6d !important;
  }
  &__boxsizeButton {
    width: 252px;
    height: 54px;
  }
  &__boxTextButton {
    @media (max-width: 1560px) {
      font-size: 13px;
    }
    padding: 0 5px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Light 100 */

    color: #2b1f0a;

    @media (max-width: 1050px) {
      font-size: 12px;
    }
  }
  &__boxButton {
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    color: #1ba1c5;

    @media screen and (max-width: 600px) {
      // width: 180px !important;
      background: green !important;
    }
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: #1ba1c5;

      // color: $text-on-primary--vivid;
      box-shadow: none;
    }
  }
  &__tableHeader {
    border: 1px solid #c6cbcd;
  }
  &__tableRow {
    border: 1px solid #c6cbcd;
    border-right: 1px solid #c6cbcd;
  }
  &____tableCheckbox {
    background: skyblue;
  }
  // &__tablePagination {
  //   margin: 0 5px;
  //   background: #fff;
  //   border: 1px solid #c6cbcd;
  //   border-radius: 5px;
  // }
  &__NoAudience {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #f0f0f0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__NoAudience_titles {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000 !important;
  }

  // .PageHeader {
  //   @media (max-width: 699px) {
  //     gap: 2.2rem;
  //   }
  // }
}
.mb-8px {
  margin-top: 7px;
  margin-bottom: 8px;
}
.bg-content-color {
  background-color: #f8f8f8;
}
