@import 'src/tokens';
.MyProfilePage {
  height: 100%;
  display: flex;
  align-items: stretch;
  background-color: #f8f8f8;
  border-radius: 18px;

  @media (max-width: 1050px) {
    flex-direction: column;
  }

  &__nav {
    // margin-left: 25px;
    margin-left: 15px;
    width: 300px;
    max-width: 20rem;
    // border-right: $pixels-1 solid $color-grey-300;
    background-color: #f8f8f8;
    border-radius: 18px;

    @media (max-width: 1050px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding: 0 2rem;
    }

    @media (max-width: 699px) {
      padding: 0.5rem;
    }
  }
  &__main {
    flex-grow: 1;
    overflow-y: auto;
    // padding: 2rem;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 20px;
    margin-top: 5px;

    @media (max-width: 1050px) {
      padding-top: 0.5rem;
    }
    @media (max-width: 699px) {
      padding: 0.5rem;
    }
  }
  &__header {
    padding: 0.5rem 1rem;
    color: black;
    // margin-top: 0;
    // margin-bottom: 7px;
    margin-top: 7px;
    margin-bottom: 4px;
    font-size: 22px;

    @media (max-width: 1050px) {
      padding: 0 1rem;
    }

    @media (max-width: 699px) {
      padding: 0 0.5rem;
      font-size: 24px;
      margin: 0.7rem 0;
    }
  }
}
.chargebeePackageModalHeader {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  line-height: 53px;
}
.chargebeePackageModalHeader > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 35px !important;
  line-height: 54px;
  text-align: center;
  color: #2b1f0a !important;
  margin: 0;

  @media (max-width: 1050px) {
    font-size: 32px;
  }

  @media (max-width: 699px) {
    font-size: 20px;
    line-height: 0;
    margin: 0.5rem 0;
  }
}
.chargebeePackageModalText {
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #6d6d6d !important;

  .Text {
    @media (max-width: 699px) {
      font-size: 15px !important;
    }
  }
}
.chargebeePackageModalBoxes {
  display: flex;
  margin: 0.5rem 1rem;
  // margin: 2rem 2rem 1rem 2rem;

  @media (max-width: 699px) {
    flex-wrap: wrap;
  }

  .upgrade_button {
    width: 205px;
    height: 40px;
  }
}
.chargebeePackageModalBox1 {
  background: #fff;
  display: flex;
  // border: 1px solid #6d6d6d;
  border: 1px solid #aab2b5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // height: 330px;
  width: 270px;
  margin-right: 1rem;
  flex-direction: column;
  border-radius: 7px;
  align-items: center;
  padding: 1rem 1.5rem;
  &__discount {
    margin-top: 12px;
  }
  &__discountPercent {
    font-size: 25px;
    color: #bd0000;
  }
}
.chargebeePackageModalBox2 {
  background: #fff;
  display: flex;
  // border: 1px solid #6d6d6d;
  border: 1px solid #aab2b5;
  border-radius: 7px;
  // height: 330px;
  width: 270px;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.chargebeePackageModalBox1Perks {
  align-self: flex-start;
  margin-bottom: 1rem;
}
.chargebeePay {
  display: flex;

  // height: 330px;
  width: 400px;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;

  @media (max-width: 699px) {
    width: 100%;
    padding: 1rem 0;
    display: block;
    margin: 0;
  }
}
.chargebeePerksList > li {
  color: lightgray;
  text-align: center;
  margin: 8px 3px;
  list-style: none;
}
.ChargebeeBg {
  // background-image: url('../../assets/Images/honeycomb.jpg');
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  width: 1100px;

  @media (max-width: 699px) {
    max-width: 310px;
  }
}
.onBoardBgIcon::before {
  content: '';
  background: url('../../assets//Images/honeycombleft.png') no-repeat;
  position: absolute;
  left: -1px;
  top: 1px;
}
.onBoardBgIcon::after {
  content: '';
  background: url('../../assets//Images/honeycombright.png') no-repeat;
  position: absolute;
  // height: 100%;
  // width: 100%;
  // background-size: 330px;
  // top: 220px;
  left: 762px;
  top: 488px;
  // background-position: right bottom;
  // z-index: -1;
}
