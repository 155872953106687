.setPasswordInput {
    background: #ffffff;
    /* Light 100 */
  
    box-sizing: border-box;
    border: 1px solid #2B1F0A !important;
    box-sizing: border-box;
    outline: none;
    height: 50px;
    font: 'Helvetica Neue';
    // width: 400px;  
    font-size: 22px;
    // padding-left: 45px;
    // border-radius: 3px;
  }
  .hidden {
    display: none;
  }
  .setPasswordInputError {
    background: #fcf2f2;
    /* Light 100 */
  
    box-sizing: border-box;
    // border: 3px solid #ccc;
    box-sizing: border-box;
    outline: none;
    height: 50px;
    // width: 400px;
    font-size: 22px;
    // padding-left: 45px;
    border-radius: 3px;
    color: #bd0000;
    border: 2px solid #bd0000 !important;
  }
  .setPasswordInput:focus {
    border: 3px solid rgb(4, 110, 209);
  }
  .inputLeftIcon {
    position: absolute;
    color: #AAB2B5;;
    //   top: 500px;
  }
  .inputLeftIconError {
    position: absolute;
    color: #bd0000;
  }
  .inputRightIcon {
    position: absolute;
    right: 10px;
    top: 0;
    color: #bd0000;
  }
  