.notificationSetting {
  h3 {
    margin: 0;
  }
  &__container {
    // border: 1px solid lightgray;
    border-radius: 20px;
    background: white;
    padding: 0.3rem 1.5rem;
    margin-top: 0.8rem;
  }
  &__containerItem {
    display: flex;
    align-items: center;
    // border-bottom: 1px solid lightgray;
    margin-right: 1.5rem;
  }
  &__containerItemContent {
    margin-left: 2rem;
    opacity: 0.8 !important;

    @media (max-width: 699px) {
      font-size: 12px;
    }
  }
  &__containerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  &__containerItemOn {
    // color: gray;
  }
  &__containerItemOff {
    // color: gray;
  }
  &__containerItemIcon {
    margin: 0 7px;
    font-size: 32px;
    cursor: pointer;
  }
  &__button {
    color: #ffab03;
    border: none;
    font-weight: bold;
    background: none;
    cursor: pointer;
    margin-top: -1rem;
  }
  @media screen and (max-width: 1050px) {
    h3 {
      margin-top: 1rem;
    }
  }
  @media (max-width: 699px) {
    h3 {
      margin-top: 0;
    }
  }
}
