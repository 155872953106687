@import 'tokens.scss';

.ListingsList {
  // max-height: calc(100vh - 530px);
  &__controls {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin-top: -0.5rem;
  }
  &__Select {
    border-radius: 12px;
  }
  &__search {
    // margin-right: 1rem;
    margin-left: auto;
    flex-basis: 33%;
    background: transparent;
    margin-bottom: 1rem;
  }
  &__listings {
    display: grid;
    gap: $pixels-4;
    overflow: auto;
    max-height: 80px;
    @media (min-height: 715px) {
      max-height: calc(100vh - 675px);
    }
  }
  &__listingScroll::-webkit-scrollbar {
    width: 15px;
    height: 60px;
  }
  &__listingScroll::-webkit-scrollbar-track {
    // background: linear-gradient(90deg, #FCD917 23.4%, #FFAB03 75.9%);
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    // margin-top: 120px;
  }
  &__listingScroll::-webkit-scrollbar-thumb {
    // background: red;
    background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
    border-radius: 10px;
    // height: 30px;
    min-height: 40px;
  }

  &__listingsCenter {
    display: grid;
    gap: $pixels-4;
    overflow: auto;
    max-height: calc(100vh - 503px);
    @media (min-height: 820px) {
      max-height: calc(100vh - 540px);
    }
  }

  &__listingsDetail {
    display: flex;
    justify-content: space-between;
  }
  &__listingsDetailBtn {
    margin: 0 auto;
    border: none;
    color: #007b94;
    border-bottom: 1px solid #007b94;
  }
  &__listingsDetailBtn:hover {
    background-color: transparent;
    color: #007b94;
  }
}

.Digital_Farming {
  // max-height: calc(100vh - 530px);

  &__listings {
    display: grid;
    gap: $pixels-4;
    overflow: auto;
    max-height: 150px !important;
    @media (min-height: 715px) {
      max-height: calc(100vh - 675px);
    }
  }

  &__listingsCenter {
    max-height: calc(100vh - 530px);
    @media (min-height: 820px) {
      max-height: calc(100vh - 538px);
    }
  }
}

.fontSize_sort_by {
  font-weight: normal;
  font-size: 14px;
}
