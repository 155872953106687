.CampaignPerformance__loader_campain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.CampaignPerformance__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px;

  color: #000000 !important;

  @media screen and (max-width: 699px) {
    font-size: 12px !important;
    line-height: 15px;
  }
}
.CampaignPerformance__contentModal {
  font-family: 'Open Sans';
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 54px !important;
  text-align: center !important;
  color: #2b1f0a !important;
}

.CampaignPerformance__select_campaign {
  width: 165.67px;
  height: 44.97px;
  background: #fcd917;
  border-radius: 10px;
  border: none;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2b1f0a;
}
.CampaignPerformance__content {
  width: 709px;
  height: 90%;
  @media screen and (max-width: 1500px) {
    width: 600px;
  }
  @media screen and (max-width: 700px) {
    width: 500px;
    height: 300px!important;
  }
  @media screen and (max-width: 520px) {
    width: 420px;
  }
  @media screen and (max-width: 440px) {
    width: 310px;
  }
}
.CampaignPerformance__submitModal {
  width: 1083px;
  // height: 717px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  // margin: 17px;
  padding: 0px;
  @media screen and (max-width: 1500px) {
    width: 883px;
    height: 670px!important;
  }
  @media screen and (max-width: 1050px) {
    max-width: 650px;
  }
  @media screen and (max-width: 650px) {
    max-width: 420px;
    align-items: flex-start;
    overflow-x: auto;
  }
  @media screen and (max-width: 450px) {
    max-width: 320px;
  }
}

.CampaignPerformance_ListingModalBg::before {
  content: '';
  background: url('../../../../assets/Images/honeycomb_left.svg') no-repeat;
  position: absolute;
  // position: absolute;
  height: 100%;
  width: 100%;
  bottom: -80px;
  left: -120px;
  background-size: 295px;
  background-position: left bottom;
  z-index: -1;
}
.CampaignPerformance_ListingModalBg::after {
  content: '';
  background: url('../../../../assets//Images/honeycomb.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 295px;
  top: -210px;
  background-position: right top;
  z-index: -1;
  left: 0;
  @media screen and (max-width: 600px) {
    right: -50px;
  }
}
.spinner-performance-list {
  color: #fcd937;
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 411px;
  left: 0;
  bottom: 0px;
  right: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
