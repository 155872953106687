@import 'src/tokens';

.RealEstateInfo {
  margin-top: 30px;

  @media (max-width: 699px) {
    margin: 1rem 0;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__heading {
    // margin-bottom: 1rem;
    margin-left: 4px;
  }

  &__error_icon {
    color: $color-danger;
  }

  &__divider {
    border: 0.5px solid #c6cbcd;
    width: 100%;
  }

  .Section--dark {
    padding: 32px;
    border: 1px solid #aab2b5;
    border-radius: 7px;

    @media (max-width: 699px) {
      padding: 15px 10px;
    }
  }

  .input_label {
    .FormItem__label {
      @media screen and (max-width: 835px) {
        min-height: 32px;
      }

      @media (max-width: 699px) {
        min-height: 0.3rem;
      }
    }
  }

  .radio_label {
    .FormItem__label {
      @media screen and (max-width: 865px) {
        min-height: 60px;
      }

      @media (max-width: 699px) {
        min-height: 0.3rem;
        font-size: 16px;
      }
    }
  }

  &__field_container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    @media (max-width: 699px) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__field {
    margin: 0 10px;
    width: 40%;

    @media (max-width: 699px) {
      width: 100%;
      // margin: 0;
      margin: 0.5rem 0;
    }
  }

  &__last_clild {
    width: 20%;
  }

  &__btn_container {
    display: flex;
    justify-content: flex-end;
  }

  &__save_button {
    width: 118px;
    height: 40px;
    font-weight: bold;
  }
}
