.UploadOrBuild {
  display: flex;
  gap: 0.5rem;
  &__Upload_creative_heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    text-align: center;

    color: #6d6d6d !important;
  }
  &__Upload_creative_Mheading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: #2b1f0a !important;
  }
  &__action-button {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    width: 187px;
    height: 54px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Light 100 */

    color: #2b1f0a !important;
  }

  @media (max-width: 699px) {
    flex-direction: column;
  }
  &__creative_or {
    padding: 0 3rem !important;
    @media (max-width: 1540px) {
      padding: 0 0rem !important;
    }
  }
}
.Upload_your_own {
  border: 1.5px solid #f0f0f0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 17px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  height: 223px !important;
  margin-top: 1rem;
}
