.UploadAreaLayout {
  padding: 5rem;
  display: grid;
  justify-items: center;
  gap: 0.4rem;
  // margin-top: -57px !important;

  @media (max-width: 699px) {
    padding: 1rem;
    gap: 0.5rem;
    text-align: center;
  }

  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center;
    color: #f6b433 !important;
    @media (max-width: 699px) {
      font-size: 12px !important;
    }
  }
}

.btn-access-creative {
  background-color: transparent;
  width: 100%;
  border: 1px solid #318ea3;
  padding-block: 15px;
  &:hover {
    border: 1px solid #318ea3;

    // cursor: pointer;
  }
}

.btn-span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffab03;
}
