@mixin focus-ring-inset {
  &:focus {
    box-shadow: $focus-ring-inset;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring-inset;
  }
}

@mixin focus-ring {
  &:focus {
    box-shadow: $focus-ring;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring;
  }
}

// @mixin theme-scroll {
//   &::-webkit-scrollbar {
//     width: 8px;
//     height: 30px;
//   }
//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
//     border-radius: 10px;
//     min-height: 40px;
//   }
// }
