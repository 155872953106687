@import 'tokens.scss';

.CampaignCreationFooter {
  width: 98%;
  background-color: $color-white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
  margin-right: 13px;
  margin-bottom: 2rem;
  border-radius: 20px;
}
