@import 'src/tokens';
@import 'src/mixins';

.Campaigns {
  &__select-action {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;

    @include focus-ring;
  }
  &__delete_campaign_list {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #ffffff !important;
  }

  &__search {
    border: 2px solid gray;
    border-radius: 5px;
    background: white;
    padding: 5px;
  }
}
