@import 'src/tokens';
@import 'src/mixins';
$digit-color: #11d900;
$old-digit-color: #1ba1c5;

@mixin stacked {
  position: absolute;
  top: 0.125rem;
  left: 0;
  color: black!important;
  font-weight: 400!important;
  width: 100%;
  height: calc(100% - 0.25rem);
}

.CalendarInputDay {
  position: relative;
  border: none;
  background-color: transparent;
  font-size: $text-size-s;
  border-radius: $border-radius-s;
  padding: 0;
  &--is-hovered {
    z-index: 1;
  }

  @include focus-ring;
  &__aspect-ratio {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% - 0.5rem);
  }
  &__range-bg {
    
    @include stacked;
    background-color: $color-grey-100;
    background-color: #fcd917;

    .CalendarInputDay--is-subdued & {
      background-color:black;
      background-color: #fcd917;
    }
    &--start,
    &--end {
      background-color: $color-primary;
      background-color: #fcd917;

      .CalendarInputDay--is-subdued & {
        background-color: $color-grey-100;
        background-color: #fcd917;
      }
    }
    &--start {
      border-top-left-radius: 100rem;
      border-bottom-left-radius: 100rem;
    }
    &--end {
      border-top-right-radius: 100rem;
      border-bottom-right-radius: 100rem;
    }
  }
  &__hovered-range-bg {
    @include stacked;
    background-color: $color-grey-200;
    background-color: lightgray;
    &--start,
    &--end {
      background-color: $color-primary;
      background-color: lightgray;
    }
    &--start {
      border-top-left-radius: 100rem;
      border-bottom-left-radius: 100rem;
    }
    &--end {
      border-top-right-radius: 100rem;
      border-bottom-right-radius: 100rem;
    }
  }
  &__label {
    @include stacked;
    display: grid;
    place-items: center;
    color: $text-on-white--subdued;
    .CalendarInputDay--is-in-month & {
      color: $text-on-white;
      color: white;
      font-weight: $text-weight-bold;
    }
    .CalendarInputDay--is-disabled & {
      color: $text-on-white--subdued;
      font-weight: $text-weight-normal;
      cursor: not-allowed;
    }
  }
}
