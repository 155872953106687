@import 'tokens.scss';

.ExpandYourMarketing {
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    margin: 1rem 2rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    padding: 0 1rem;
    div[id^='mapid'] {
      height: 100%;
    }
  }
  &__rightTop {
    position: absolute;
    z-index: 999;
    top: 30px;
    right: 30px;
    width: 182px;
    // background-color: #fff;
    border-radius: 12px;
    // width: 300px;
    height: 45px;
    // align-items: center;
    text-align: center;
    padding-top: 5px;
  }
  &__rightTopButton {
    border: 2px solid #ffb708;
    height: 30px;
    margin: 0 10px;
    border-radius: 10px;
    font-size: 13px;
    color: black;
    font-weight: 600;
    letter-spacing: 0.8px;
    :hover {
      background: none;
    }

    @media screen and (max-width: 1050px) {
      margin: 0 2px;
    }
  }
}
.realtor_container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 401;
  border-radius: 14px;
  margin-top: 15px;
  margin-right: 25px;
  max-height: 580px;
  overflow: auto;

  @media (max-height: 800px) {
    max-height: 430px;
  }

  @media (max-width: 1050px) {
    right: -10px;
    margin-right: 0;
    margin-top: 0;
  }
}
